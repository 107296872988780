@import '../../helpers'

// Variant: `default`
//Gray background
.card--default
  background: #f8f8f8

// Variant: `highlighted`
// Color of value is blue
.card--highlighted
  background: #f0f6fc

.card--warning
  background: #fdf8e9

// Variant: `danger`
// Red with red title
.card--danger
  $card-color: get-dash-color(danger)
  border: 1px solid $card-color
  background: rgba($card-color, .05)

  .h4,
  h4,
  .h5,
  h5
    font-size: 18px
    line-height: 22px
    color: $card-color
    text-transform: uppercase
    font-weight: font-weight(semibold)
    margin-bottom: space(4)

  .card-content
    padding: space(6)

// Variant: `centered`
// Text/content is centered
.card--centered
  text-align: center

// Variant: `padded`
// Text/content is centered
.card--padded
  padding: space(6)

// Variant: `rounded`
// Text/content is centered
.card--rounded
  border-radius: 10px
  overflow: hidden
  border: 0

.card-body
  color: $body-color

a.card
  transition: box-shadow .15s ease-in-out
  &:hover,
  &:focus
    box-shadow: 0 2px 15px 0px rgba(0,0,0,.1)
    img
      opacity: 1
  & ,
  .card-body,
  .card-title,
  .card-description
    text-decoration: none !important

.card--download
  background: #346CAE08 0% 0% no-repeat padding-box
  border: 1px solid #346CAE
  border-radius: 4px
  &.disabled
    background: lighten(#f9f9f9, 1%)
    border-color: #CCCCCC
    h5,
    .card-body
      color: #777777
