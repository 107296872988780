@import '../helpers'

.c-topic-nav
  background: $brand-primary
  margin: 0
  padding: 0
  justify-content: flex-end

.c-topic-nav ul
  display: flex
  align-items: center
  justify-content: flex-end
  @include list-unstyled

.c-topic-nav__item
  border-radius: 0

.c-topic-nav__link
  color: $white
  display: block
  border-top: 4px solid transparent
  font-weight: 600
  letter-spacing: .03em
  transition: .2s ease-in-out
  text-decoration: none
  &.nav-link
    border-radius: 0
  &:hover
    background: rgba($white, .5)
    color: $black
  &.active
    border-color: rgba($white, .5)
    cursor: default
  &.active.hide-when-active
    visibility: hidden
    opacity: 0
  &.disabled
    color: rgba($white, .3)
    pointer-events: none
