@use "sass:math"

@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-card-tabs,
.c-card-tabs.nav-tabs
  flex: 0 0 auto
  display: flex
  flex-wrap: nowrap
  align-self: flex-start
  border-bottom: 1px solid dash-color(link-color-primary)
  width: calc(100% + #{space(4)} + #{space(4)})
  margin: (- space(3)) (- space(4)) space(4) (- space(4))
  background: $white

.c-card-tabs--mx-0
  margin-left: 0
  margin-right: 0
  width: 100%

.c-card-tabs--my-0
  margin-top: 0
  margin-bottom: 0

.c-card-tabs__tab,
.c-card-tabs.nav-tabs .c-card-tabs__tab.nav-link
  // flex: 1 1 auto
  @include font-open-sans
  text-align: center
  padding: space(1) space(4) space(1) space(4)
  font-size: 16px
  font-weight: 600
  line-height: math.div(20, 16)
  color: dash-color(link-color-primary)
  margin-bottom: -1px
  margin-right: space(2)
  background: dash-color(card-bg)
  border-bottom: 1px solid dash-color(link-color-primary)
  &,
  &:hover
    text-decoration: none
    border-radius: 0
  &:hover
    background: dash-color(link-color-primary)
    color: $white
  &.active
    border-radius: 0
    background: dash-color(link-color-primary)
    color: $white
    font-weight: 600
    pointer-events: none
    &:hover
      border: 0
      color: $white !important
