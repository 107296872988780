@import '../../helpers'

.c-dynamic-map
  position: relative
  display: flex
  flex-direction: column
  flex: 1 1 100%
  max-width: 100%

.c-dynamic-map__controls
  display: flex
  flex-wrap: wrap
  align-items: flex-end
  flex-grow: 0

.c-dynamic-map__control
  max-width: 100%
  margin-bottom: space(2)
  padding-left: space(4)
  padding-right: space(4)
  &:last-child
    margin-bottom: 0
  @include media-breakpoint-up(md)
    flex: 1
    margin-bottom: 0
  label
    display: flex
    flex-wrap: wrap
    font-weight: 600
    text-transform: none
    color: get-dash-color(h2)
    font-size: 16px
    line-height: 18px
    span
      padding-right: space(1)
    span:last-of-type
      font-weight: normal
      color: get-dash-color(h1)
      font-size: 14px

  select
    font-weight: normal
    color: get-dash-color(h1)
  .c-dash-picker__wrapper
    width: 100%

.c-dynamic-map__map
  flex-grow: 1
  margin-top: space(2)
  margin-bottom: space(2)
  min-height: 500px
  @include media-breakpoint-up(md)
    margin-bottom: 0
  .c-loading-animation
    z-index: stack-order(top) !important
