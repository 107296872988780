@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-dash-chart
  flex: 1 1 100%
  display: flex
  flex-direction: column
  justify-content: flex-start

.c-dash-chart__title,
.c-dash-chart__note
  @include media-breakpoint-up(md)
    padding-left: space(8)

.c-dash-chart__title
  flex: 0 1 auto

.c-dash-chart__wrapper
  flex: 1 1 auto
  min-height: 300px
  max-height: 80vh

.c-dash-chart__wrapper--no-min-height
  min-height: auto

.c-dash-chart__note
  max-width: 125ch
  font-size: rem($sm-font-size)
