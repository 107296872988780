@import '../helpers';

.#{$range-slider-class-name} {
  @include range-slider('#{$range-slider-class-name}__');
}

.#{$range-slider-class-name}__steps {
  cursor: pointer;
  transition: .2s background ease-in-out;
  &:hover {
    background: $gray-400;
  }
}

.#{$range-slider-class-name}__handle {
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    transform: scale(1.1);
    &::before {
      color: $white;
      border-color: $white;
    }
  }
  &::before {
    @include font-icon($weight: 800);
    content: '\f337';
    color: rgba($white, .35);
    font-size: rem(20px);
    line-height: 1;
  }
}
