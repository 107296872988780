@import '../helpers'

$toggle-height: $input-min-height
$toggle-width: $input-min-height * 2
$toggle-thumb-height: $toggle-height - 2px
$toggle-icon-padding: 16px

.react-toggle
  margin-bottom: 6px

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track
  background-color: $brand-secondary
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-thumb
  border-color: $brand-secondary

.react-toggle-track
  height: $toggle-height
  width: $toggle-width
  background-color: $brand-primary

.react-toggle-thumb
  height: $toggle-thumb-height
  width: $toggle-thumb-height
  border-color: $brand-primary

.react-toggle-track-x
  right: $toggle-icon-padding

.react-toggle-track-check
  left: $toggle-icon-padding

.react-toggle-track-x
  &,
  svg
    width: 14px
    height: 14px
.react-toggle-track-check
  &,
  svg
    width: 18px
    height: 18px

.react-toggle--checked .react-toggle-thumb
  left: 100%
  transform: translateX(-100%)
