@import '../helpers'

.c-metric-card-link
  padding: space(3)
  background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba($card-color, 1) 65%)
  display: block
  text-align: right
  margin-top: space(1, true)
  &:hover,
  &:focus
    text-decoration: none
    background: linear-gradient(135deg, rgba(255,255,255,1) 20%, rgba($brand-primary, 1) 65%)
    color: $white
