@use "sass:math"

@import '../helpers'

.c-topic-header
  // display: flex
  // align-items: baseline
  // flex-wrap: wrap
  // @include flex(1, 1, 100%)
  width: 100%

.c-topic-header--nowrap
  flex-wrap: nowrap

.c-topic-header__heading
  @include font-open-sans($size: rem(30px), $color: $white, $weight: font-weight(bold))
  background: $brand-primary
  padding: space(2) space(4) space(2) 0
  text-transform: none
  color: $white

.c-topic-header__text
  flex: 1 1 100%
  flex-wrap: wrap
  background: $brand-primary
  padding: space(4)
  border-top-left-radius: $border-radius
  border-top-right-radius: $border-radius

.c-topic-header__intro,
.c-topic-header__action-call
  color: $white
  transition: .2s max-height ease-in-out, .2s opacity ease-in-out
  @include constrained-container

.c-topic-header__intro
  margin-bottom: space(3)

.c-topic-header__intro p
  font-size: 16px
  line-height: math.div(22, 16)
  margin: 0

.c-topic-header__selection
  @include topic-full-bleed
  @include topic-header
  padding: space(2) space(4)
  margin-top: (-#{space(4)})
  margin-bottom: space(2)
  display: flex
  @include flex(1, 1, auto)
  flex-direction: column
  gap: space(2)
  align-items: flex-start

.c-topic-header__selection label
  font-weight: 600

.c-topic-header__context
  display: none
  @include media-breakpoint-up($nav-switch-breakpoint)
    @include flex(1, 1, auto)
    text-align: right
    margin-left: auto
    margin-top: 0
    margin-right: space(4)
    margin-bottom: space(2)
    white-space: nowrap
    text-transform: uppercase
    letter-spacing: .07em
    color: $white

.c-topic-header__action
  margin-left: auto
  margin-right: space(2)
  margin-bottom: 1px
  @include media-breakpoint-up($nav-switch-breakpoint)
    margin-top: auto
    margin-right: space(4)

.c-topic-header__action .c-dropdown__button
  @include media-breakpoint-down($nav-switch-breakpoint)
    border: 0
    padding-top: space(1)
    padding-bottom: space(1)
    padding-right: space(4)
    margin-right: space(2)
    margin-bottom: 0
    margin-top: 0

.c-topic-header__intro-wrapper
  display: flex
  flex-wrap: wrap

.c-topic-header__action-call
  // display: flex
  // flex-warp: wrap

.c-topic-header__action-call-image
  display: flex
  position: relative
  &,
  img
    border-radius: 4px
    max-width: 175px
  img
    border: 2px solid $card-color

.c-topic-header__action-call h4
  margin-left: space(4)

.c-topic-header__action-call h4,
.c-topic-header__action-call a
  color: rgba($card-color, 1) !important

.c-topic-header__action-call a
  font-weight: 600
  &:focus,
  &:hover
    color: $gray-900 !important

.c-topic-header__action-call-icon
  font-size: rem(25px)
  color: rgba($card-color, .75)
  position: absolute
  top: 5px
  left: 5px

.c-topic-header__action-call-link
  display: flex
  align-items: flex-end
  &:focus,
  &:hover
    text-decoration: none
    .btn
      background: $card-color
      color: $gray-900
    img
      opacity: .8
  &:active
    img
      opacity: .4

.c-topic-header__action-call-link .btn
  padding-left: space(4)
  padding-right: space(6)
  border-top-left-radius: 0
  border-bottom-left-radius: 0
  border-left: 0
  margin-bottom: space(2)
  font-weight: 600
