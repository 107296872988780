@import '../helpers'

.c-tooltip-chart
  @include tooltip
  max-width: 100vw
  display: flex
  background: $white

.c-tooltip-chart--horizontal
  @include media-breakpoint-up(md)
    display: flex
    max-width: none


.c-tooltip-chart__context-wrapper
  flex: 1
  display: flex
  flex-direction: column
  justify-content: flex-end

.c-tooltip-chart--horizontal .c-tooltip-chart__value-wrapper
  max-width: 250px
  border-right: 1px solid $gray-200
  border-bottom: 0
  &:last-child
    border: 0

.c-tooltip-chart__value-wrapper
  display: flex
  flex-direction: column
  padding: space(2)
  border-right: 1px solid $gray-400
  max-width: 150px
  &:last-of-type
    border: 0
  @include media-breakpoint-up(lg)
    max-width: 200px

.c-tooltip-chart__title,
.c-tooltip-chart__date,
.c-tooltip-chart__location
  font-size: rem(14px)

.c-tooltip-chart__addendum,
.c-tooltip-chart__unit
  font-size: rem(12px)

.c-tooltip-chart__title,
.c-tooltip-chart__date
  margin-bottom: rem(5px)

.c-tooltip-chart__date
  @include font-default()
  display: block

.c-tooltip-chart__value
  display: block
  margin-bottom: space(1)
  &:not(.c-metric-value)
    @include data-metric()
  @each $index, $color in $chart-colors
    &--#{$index}
      color: $color !important

.c-tooltip-chart__location
  display: block
  &:empty
    display: none
    margin: 0

// .c-tooltip-chart__context

.c-tooltip-chart__addendum
  @include addendum
  justify-content: flex-end

.c-tooltip-chart__warning
  color: $brand-danger
  display: flex
  align-items: baseline
  margin-top: space(2)
  @include bolden
  i
    margin-right: space(1)

.c-tooltip-chart__legend
  display: flex
  align-items: center
  margin-top: space(2)

.c-tooltip-chart__legend-swatch
  @include swatch(rem(20px))

.c-tooltip-chart__legend-label
  @include bolden

.c-tooltip-chart__value-container
  display: flex
  align-items: center

.c-tooltip-chart__value-point
  display: flex
  align-items: center
  margin: 0 0 0 auto
  &,
  &:before
    display: inline-block
    padding: 0
    height: auto
    max-width: none
    min-width: auto
    transform: none
    position: static
  &:before
    argin: 0
    height: 20px
    width: 20px
    transform: none

.c-tooltip-chart__unit
  margin-right: space(1)
  margin-left: space(1)
  &:empty
    display: none
    margin: 0
