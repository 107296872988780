@import '../helpers'

.c-metric-donut
  display: flex
  align-items: center
  flex-wrap: wrap
  width: 100%

.c-metric-donut--centered
  @include media-breakpoint-up(xl)
    justify-content: center

.c-metric-donut .c-metric__viz-wrapper
  width: auto

.c-metric-donut .c-metric__viz-wrapper--val-inside
  position: relative

.c-metric-donut .c-metric__viz-wrapper--val-inside
  position: relative
  .c-metric-value
    position: absolute
    top: 48%
    left: 50%
    transform: translate(-50%, -50%)
    margin: 0
