@import '../helpers'

.c-card
  background: $card-color
  margin-bottom: space(6)
  > p
    max-width: $max-paragraph-width

.c-card--light
  background: $white

.c-card__header,
.c-card__content
  padding: space(3)

.c-card__header
  // Needs to be shared with metric card header
  padding: space(3) space(4) space(2) space(4)
  border-bottom: 2px solid $border-color-secondary
  background-color: $card-color

.c-card__title
  margin-bottom: 0
  color: $gray-600

.c-card__content p
  max-width: $max-paragraph-width
  &:last-child
    margin-bottom: 0

.c-card__footer
  display: flex
  align-items: center
  border-top: 2px solid $border-color-secondary
  flex-wrap: wrap

.c-card__footer--padded
  padding: space(3)

.c-card__footer--bg
  background: $card-header-bg-color

.c-card__footer-link
  @include card-link

.c-card--full-bleed
  margin-right: space(4, true)
  margin-left: space(4, true)
