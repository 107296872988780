@import '../../helpers'
@import '../../tools/mixins/dashboard'

// .c-dash-picker

$select-color: lighten(get-dash-color(gray-1), 10%)

.c-dash-picker__label
  display: block
  text-transform: uppercase
  margin-bottom: space(2)

.c-dash-picker__wrapper
  position: relative
  display: inline-flex
  min-height: 40px
  min-width: 100%
  margin-bottom: space(2)
  border: 1px solid $select-color
  border-radius: $border-radius-default
  cursor: pointer
  @include media-breakpoint-up(md)
    min-width: 200px
    max-width: 100%
  &:hover
    border-color: $white
    &::after
      color: $white
  &::after
    @include font-icon(rem(24))
    content: '\f107'
    position: absolute
    right: space(2)
    top: 50%
    transform: translateY(-50%)
    color: $select-color
    pointer-events: none

.c-dash-picker__select
  min-width: 100%
  font-weight: font-weight(bold)
  color: get-dash-color(link)
  border: 0
  padding: space(2) space(6) space(2) space(2)
  cursor: pointer
  border-radius: $border-radius-default
  outline: none
  -webkit-appearance: none
  -moz-appearance: none
  -ms-appearance: none
  -o-appearance: none
    appearance: none
  &:hover
    background: get-dash-color(link)
    color: $white
  &::-ms-expand
    display: none
