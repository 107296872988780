@import '../helpers'

.c-metric-card-group
  padding-top: space(2)
  margin-bottom: space(4)
  @include flex(1, 1, 100%)

.c-metric-card-group__heading-link
  @include link-arrows
  @include flex(1, 1, 100%)
  color: $brand-primary
  text-transform: none
  display: flex
  align-items: center
  border-bottom: 2px solid $brand-secondary
  margin-bottom: 0
  text-decoration: none
  &:hover
    text-decoration: none
    &,
    &::after,
    .c-metric-card-group__heading
      color: $brand-secondary

.c-metric-card-group__heading
  @include topic-title
  color: $body-color

.c-metric-card-group__heading-link .c-metric-card-group__heading
  margin-bottom: 0
  padding-bottom: 0
  border: 0
  color: $brand-primary

.c-metric-card-group__description
  margin: space(3) 0 space(4) 0
