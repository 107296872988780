@import '../helpers'

.text-data
  @include data-metric()

.text-data--comparison
  @include data-metric(18px, $gray-500)

@each $index, $color in $chart-colors
  .text-data--#{$index}
    color: $color

.no-data
  font-style: italic !important
  @include flex(1, 1, auto)
  align-self: stretch
  display: flex
  align-items: center
  justify-content: center

.tiny-th
  font-weight: 600 !important
  font-size: 14px !important
  line-height: 15px
  font-weight: 600
  letter-spacing: .01em
  text-transform: uppercase

.tiny-th__no_uppercase
  font-weight: 600 !important
  font-size: 14px !important
  line-height: 15px
  font-weight: 600
  letter-spacing: .01em

.strong-td
  font-weight: 600 !important

.t-credit--small
  font-size: 14px
  color: $gray-600

.ul-footnote
  margin-bottom: 26px
  font-size: 80%
  line-height: 1.5

.font-weight-semibold
  font-weight: font-weight(semibold)
