@use "sass:math"

@import '../helpers'

.c-metric-card
  margin-bottom: space(4)
  @include flex(1, 1, 100%)

.c-metric-card--suppressed
  opacity: .75

.c-metric-card--3-up
  max-width: 100%
  @include flex(1, 1, 100%)
  @include media-breakpoint-up(lg)
    max-width: percentage(1*0.5)
    flex: 1 1 percentage(1*0.5)
    padding-right: space(3)
  @include media-breakpoint-up(xl)
    max-width: percentage(math.div(1, 3))
    flex: 1 1 percentage(math.div(1, 3))
    padding-right: space(4)

.c-metric-card--flex
  display: flex
  flex-direction: column

.c-metric-card--no-flex
  flex: 0

.c-metric-card__header
  background: $card-header-bg-color
  padding: space(3) space(4) space(2) space(4)
  border: 1px solid $border-color-secondary
  border-top-left-radius: $border-radius
  border-top-right-radius: $border-radius

.c-metric-card__header--outside
  background: $white
  padding: space(2)

.c-metric-card__header--with-context
  display: flex
  align-items: center

.c-metric-card__header h3
  margin-bottom: 0

.c-metric-card__header--informational
  background: $brand-info
  border-bottom: 09

.c-metric-card__title-wrapper
  display: flex
  align-items: center
  flex-wrap: wrap

.c-metric-card__title-link
  text-decoration: none
  display: flex
  align-items: center
  @include link-arrows($size: rem(16px))
  &:hover
    &,
    h4,
    span,
    &::after
      text-decoration: none
      color: $brand-primary

.c-metric-card__title
  margin-bottom: 0
  margin-right: space(2)

.c-metric-card__title--primary
  text-transform: uppercase

.c-metric-card__title--secondary
  font-weight: normal

.c-metric-card__tooltip
  margin-left: auto
  padding-left: space(1)
  padding-right: space(1)
  margin-right: (-#{space(1)})
.c-metric-card__tooltip svg
  fill: $brand-primary

.c-metric-card__header-link
  margin-left: auto
  &:hover
    text-decoration: none

.c-metric-card__header-link span
  font-weight: 600

.c-metric-card__addendum
  @include addendum

.c-metric-card__addendum--right
  margin-left: auto

.c-metric-card__content,
.c-metric-card__row
  background-color: $card-color
  padding: 0 space(2)
  display: flex
  flex-wrap: wrap
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: 0 space(4)

.c-metric-card__content
  @include flex(1, 1, auto)
  border: 1px solid $border-color-secondary
  border-bottom-left-radius: $border-radius
  border-bottom-right-radius: $border-radius

.c-metric-card__content--wrapper
  padding: space(2)
.c-metric-card__content--spaced
  justify-content: space-between

.c-metric-card__group
  margin-top: space(4)
  margin-bottom: space(4)
  &:first-of-type .c-metric-card__group-item--padded
    padding-left: 0
  &:last-of-type .c-metric-card__group-item--padded
    padding-right: 0
  .highcharts-container
    max-height: 100px


.c-metric-card__group--auto
  @include flex(0, 0, auto)
  max-width: 100%

.c-metric-card__group--w-100
  @include flex(1, 1, auto)
  max-width: 100%
  display: flex

.c-metric-card__group--sized
  @include flex(1, 1, 100%)
  max-width: 100%
  @include media-breakpoint-up(sm)
    @include flex(1, 1, 50%)
    max-width: 50%
  @include media-breakpoint-up(lg)
    flex: 1 1 20%
    max-width: 20%

.c-metric-card__group--6
  $width: percentage(math.div(1, 6))
  @include media-breakpoint-up(md)
    flex: 1 1 percentage(math.div(1, 3))
    max-width: percentage(math.div(1, 3))
  @include media-breakpoint-up(lg)
    margin-bottom: 0
    flex: 1 1 $width
    max-width: $width

.c-metric-card__group--2
  @include flex(1, 1, 50%)
  max-width: 50%

.c-metric-card__group--3
  flex: 1 1 percentage(math.div(1, 3))
  max-width: percentage(math.div(1, 3))

.c-metric-card__group--informational
  display: flex
  align-items: baseline
  @include media-breakpoint-up(xs)
    @include flex(1, 1, 50%)
    max-width: 50%
  @include media-breakpoint-up(sm)
    flex: 1 1 percentage(math.div(1, 3))
    max-width: percentage(math.div(1, 3))

.c-metric-card__group--sm-stacked
  flex-wrap: wrap
  > div:first-child
    @include flex(0, 0, auto)
  > div:last-child
    @include flex(1, 1, auto)
  @include media-breakpoint-up(md)
    flex-wrap: nowrap


.c-metric-card__group--informational .icon-svg
  margin-right: space(2)

.c-metric-card__group--informational label
  @include bolden
  margin: 0 0 0 space(1)

.c-metric-card__group--flexible
  @include media-breakpoint-up(xl)
    @include flex(1, 1, auto)
    max-width: none

.c-metric-card__group--disabled
  opacity: .25

.c-metric-card__group-title
  font-size: 16px
  line-height: 20px
  font-weight: 400
  margin-bottom: space(1)

.c-metric-card__group-title-context
  text-transform: none

// .c-metric-card__chart

.c-metric-card__footer
  display: flex
  align-items: center
  border: 1px solid $border-color-secondary
  border-bottom-left-radius: $border-radius
  border-bottom-right-radius: $border-radius
  background: $white

.c-metric-card__footer-content
  padding: space(2) space(4)

.c-metric-card__footer--gradient
  border-top: 2px solid $white
  background: linear-gradient(135deg, rgba($card-color, 1) 0%, rgba(255,255,255,1) 100%)

.c-metric-card__footer--light
  background: $white
  border: 1px solid $border-color-secondary

.c-metric-card__footer--dark
  background: $card-header-bg-color

.c-metric-card__footer-link
  @include card-link

.c-metric-card__group-item
  margin-bottom: space(4)
  &:last-child,
  &:only-child
    margin-bottom: 0

.c-metric-card__group-item--padded
  padding: 0 space(2)
