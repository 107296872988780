@import '../tools'

.c-list-inline
  @include list-unstyled
  display: flex
  flex-wrap: wrap

.c-list-inline__item
  min-width: 250px
  margin-bottom: space(1)
