.c-score
  display: inline-flex
  align-items: baseline
.c-score__numeric
  font-weight: 700
  font-size: 25px
  line-height: 30px
  margin-right: 10px
  color:  #1A263C
.c-score__numeric--sm
  font-size: 18px
  line-height: 24px
.c-score__description
  text-transform: uppercase
  font-size: 14px
  color:  #1A263C
