@import '../helpers'

$search-button-width: 50px

.c-search-input
  display: flex
  position: relative

.c-search-input__field
  @include flex(1, 1, auto)
  border: 0
  padding: space(3) rem($search-button-width + 10px) space(3) calc(#{space(4)} - #{rem(5px)})
  border: 2px solid transparent
  min-height: $search-button-width
  &::placeholder
    font-size: 13px
    color: $black
  &:focus
    background: rgba($brand-primary-l, .05)
    border-color: $brand-primary-l
    outline: none
    box-shadow: none

.c-search-input__submit
  position: absolute
  right: 0
  top: 0
  bottom: 0
  width: rem($search-button-width)
  font-size: rem(24px)
