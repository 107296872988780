@import '../helpers'

.c-list-card
  display: flex
  min-height: 125px
  padding: 10px
  margin-bottom: 10px
  background: white
  border: #CCC 1px solid
  border-radius: 4px
  overflow: hidden
  &.highlighted
    border-width: 2px
    border-color: get-dash-color(link)
  img
    flex: 0
    cursor: pointer
    transition: transform .2s ease-in-out
    &:hover
      transform: translatey(-2px) scale(1.01)
  > div
    display: flex
    flex-direction: column
    flex: 1 1 auto

.c-list-card__title
  font-size: 18px
  line-height: 20px

.c-list-card__url
  display: block
  max-width: 300px
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
