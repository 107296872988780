@import '../helpers'

.c-search-results__container
  position: absolute
  background-color: #fff
  z-index: stack-order(modal) - 1
  border-radius: 2px
  font-size: 11px
  box-shadow: 0 2px 6px rgba(0, 0, 0, .3)
  -moz-box-sizing: border-box
  -webkit-box-sizing: border-box
  box-sizing: border-box
  overflow: hidden
  width: 100%
  top: calc(100% + 3px)
  left: 0

  .c-list__link.disabled
    color: $brand-primary

.c-search-results__container > ul
  padding-bottom: 0

.c-search-results__item-error,
.c-search-results__item-error:hover
  .c-list__link.disabled
    color: $brand-danger
    font-weight: normal
