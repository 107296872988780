@import '../helpers'

.o-map
  background: $card-color
  border: 1px solid $border-color-primary
  border-radius: $border-radius
  padding-bottom: space(4)

.o-map__title
  margin: 0
  padding: space(4)
  border-bottom: 1px solid $border-color-secondary

.o-map--full
  max-width: none
  height: 100%
  width: 100%

.o-map__video-wrapper
  position: relative
  margin-bottom: rem(5px)

.o-map__google-container
  height: 100%
  width: 100%

.o-map__container--constrained
  min-height: 500px
  height: auto
