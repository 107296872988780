.c-table-matrix
  > :not(caption) > * > *
    border: 0

.c-table-matrix__axis-label
  min-height: 75px
  font-size: 15px
  line-height: 20px
  font-weight: 600
  small
    display: block
    font-size: 13px
    line-height: 14px
    font-weight: 300
