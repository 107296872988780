@import '../helpers'
// neighborhood and indicator pages metrics
// these styles can be made neighborhood-page-specific (.o-metrics__large) or indicator-page-specific (.o-mertics small)

// example haml structure
// .o-metrics__large: shared/_topic_intro_from_census
// .o-metrics__small: asthma/hospitalizations
// .o-metrics__small w/sub-title before groupings: lead/show

$metric-breakpoint: lg

.c-metric
  margin-bottom: space(5)
  margin-right: space(6)
  &:last-of-type
    margin-right: 0

.c-metric__title
  margin-bottom: space(2)
  text-transform: none

.c-metric__title__year
  @include font-light(14px, $body-color, $lh: line-height(16, 14))
  margin-bottom: space(1)

.c-metric__detail .c-metric__title
  @include font-extrabold(16px, $gray-600, $lh: line-height(24, 18))
  text-transform: uppercase
  margin-bottom: 0

.c-metric__title__explainer
  font-size: 70%
  letter-spacing: normal

.c-metric__title .fa
  font-size: rem(20px)

.c-metric__group
  // use even when there's only one stat. this is the flex item
  // gives us a way to vertically stack related stats, so that they will be read consecutively by screen readers and stay together visually on small screens, rather than using the bs columns which do the opposite.

.c-metric__group--quarter-width-basis
  flex: 25% 0 1

.c-metric__group--third-width-basis
  flex: 33% 0 1

.c-metric__group--half-width-basis
  flex: 100% 0 1
  @include media-breakpoint-up($metric-breakpoint)
    flex: 50% 0 1

.c-metric__detail
  // flex container for nested stats like on lead pages
  margin-top: space(1)
  flex-wrap: wrap

.c-metric__context
  @include flex(1, 1, 100%)
  margin-bottom: space(2)
  @include media-breakpoint-up(sm)
    min-width: 200px
    max-width: 250px
  @include media-breakpoint-up($metric-breakpoint)
    @include flex(1, 1, 50%)


.c-metric__stat-and-label
  display: flex
  justify-content: space-between
  margin-right: space(4)

// neighborhood pages metrics
.o-metrics__large .c-metric__numerator
  // numerator is the stat class even when there is no denominator
  @include font-heading(rem(30px), $color: $brand-secondary, $weight: font-weight(extrabold), $lh: line-height(33, 30))
  margin-left: auto
  display: inline-block

.o-metrics__large .c-metric__denominator
  display: inline-block
  color: $gray-600
  font-size: rem(24)

// indicator pages metrics
.o-metrics__small .c-metric__title .units,
.units
  text-transform: initial

.c-metric__detail:only-of-type
  .c-metric__context
    min-width: auto
    @include flex(1, 1, auto)
  .c-metric__numerator,
  .c-metric__label
    margin-right: space(4)
    align-items: flex-end
    @include media-breakpoint-up(md)
      white-space: nowrap
  .c-metric__stat-and-label
    align-items: flex-end
    @include media-breakpoint-up(md)
      align-items: baseline

// .o-metrics__small .c-metric__detail

.o-metrics__small .c-metric__detail--padded:first-of-type
  @media (min-width: 1099px)
    padding-left: space(3)

.o-metrics__small .c-metric__label
  display: inline-block
  @include font-light()
  font-style: italic

.o-metrics__small .c-metric__numerator
  display: inline-block
  @include bolden
