@import '../../helpers'

.c-map-chart
  display: flex
  @include flex(1, 1, auto)
  justify-content: center
  flex-wrap: wrap
  @include media-breakpoint-up(md)
    justify-content: flex-start
    flex-wrap: nowrap

.c-map-chart--full-bleed
  background: $white
  @include story-container-full-bleed

.c-map-chart__map-wrapper,
.c-map-chart__chart
  @include flex(1, 1, 75%)
  max-width: 100%
  display: flex

.c-map-chart__map-wrapper
  margin-bottom: space(4)
  justify-content: center
  align-items: center
  flex-direction: column
  @include media-breakpoint-up(md)
    @include flex(1, 1, 25%)
    max-width: 25%
    margin-bottom: 0

.c-map-chart__map-text
  margin-top: space(2)
  @include media-breakpoint-up(md)
    padding-left: space(8)

.c-map-chart__chart-wrapper
  @include media-breakpoint-up(md)
    @include flex(1, 1, 75%)
    max-width: 75%

.c-map-chart .c-story-chart
  padding-left: 0
  padding-right: 0
