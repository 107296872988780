@import '../../helpers'

.c-timeline
  display: none
  @include scrollbar($width: rem(2px))
  position: sticky
  top: 0
  padding: space(2)
  padding-right: space(4)
  background: rgba($white, .9)
  z-index: 1001
  overflow: auto
  max-width: 100%
  @include media-breakpoint-up(md)
    display: block

.c-timeline__track
  height: 5px
  width: 100%
  background: $brand-primary
  position: absolute
  top: 50%
  transform: translateY(-50%)
  left: 0
  right: 0
  z-index: -1

.c-timeline__dates
  display: flex
  @include story-content-padding
  padding-right: 80px
  position: relative
  z-index: 10

.c-timeline__date
  margin-right: space(6)
  white-space: nowrap
  position: relative
  text-decoration: none
  span
    position: relative
    display: block
    border: 1px solid $brand-primary
    background: $card-color
    z-index: 10
    transition: .05s transform ease-in-out
    @include font-montserrat($size: 14px, $weight: 600, $lh: 1, $color: $brand-secondary)
    padding: space(1) space(3)
    border-radius: 5px
    @include media-breakpoint-up(md)
      font-size: 20px
      padding: space(1) space(4)
  &:hover,
  &:focus,
  &.active
    span
      background: $brand-primary
      color: $card-color
      transform: scale(1.1)
      transform-origin: center center
    &,
    span
      text-decoration: none
  &.active
    pointer-events: none
  &::before,
  &::after
    content: ''
    position: absolute
    left: -(#{ 2.5rem * 0.5})
    top: 5px
    bottom: 5px
    width: 1px
    background: linear-gradient(to top, rgba($brand-primary,0) 0%, rgba($brand-primary,1) 20%, rgba($brand-primary,1) 80%, rgba($brand-primary,0) 100%)
    z-index: 0
  &::after
    left: 50%
    width: 2px
    top: -10px
    bottom: -10px

.c-timeline-content
  // @include story-content-padding
