@import '../helpers'

$small-screen-breakpoint: lg

.c-map-with-list
  display: flex
  height: 100vh
  @include media-breakpoint-up($small-screen-breakpoint)
    height: 75vh
    max-height: 800px

.c-map-with-list__wrapper,
.c-map-with-list__container
  display: flex
  flex: 1 1 100%

.c-map-with-list__container
  overflow: hidden

.c-map-with-list__wrapper
  flex-direction: column
  max-width: 100%

.c-map-with-list__mobile-nav
  flex: 1 0 auto
  border-bottom: 0
  margin-bottom: space(4)
  button
    // Override a specific bootstrap declaration
    // (.btn-group > .btn, .btn-group-vertical > .btn)
    flex: 1 1 50% !important
  .btn-group
    flex-direction: row
  .btn-group .btn:first-of-type
    border-top-left-radius: 50px
    border-bottom-left-radius: 50px
  .btn-group .btn:last-of-type
    border-top-right-radius: 50px
    border-bottom-right-radius: 50px

.c-map-with-list__list,
.c-map-with-list__map
  position: relative
  @include media-breakpoint-down(md)
    display: none
    &.active
      display: block

.c-map-with-list__list
  flex: 1 1 100%
  width: 100%
  @include scrollbar($thumb-color: get-dash-color(link), $declare-overflow: false, $radius: 5px)
  overflow: auto
  padding-right: space(1)
  @include media-breakpoint-up($small-screen-breakpoint)
    flex: 0 1 35%
    width: 35%
    min-width: 400px
    max-height: 100%
    margin-right: 5px

.c-map-with-list__map
  flex: 1 1 100%
  width: 100%
  @include media-breakpoint-up($small-screen-breakpoint)
    width: 65%
    flex:  1 1 auto
    background: $gray-200
