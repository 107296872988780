@import '../../helpers'

@mixin map-legend
  background: rgba($card-color, .9)
  border: 1px solid $brand-primary
  border-radius: 3px

.c-d3-map
  max-width: 100%
  height: 100vh
  display: flex
  align-items: center
  position: relative
  // background: $white
  @include media-breakpoint-up(md)
    flex-direction: row

.c-d3-map__map,
.c-d3-map__legend
  position: relative
  z-index: 10

.c-d3-map__map
  width: 100%
  height: 100%
  @include flex(1, 1, 100%)
  @include media-breakpoint-up(md)
    min-width: auto

.c-d3-map__map svg
  transition: 1s transform ease-in-out
  @include media-breakpoint-up(md)
    max-height: none

.c-d3-map path
  stroke: white
  &.active
    stroke-width: 0

.c-d3-map__legend
  @include map-legend
  padding: space(4) space(3)
  position: absolute
  top: 0
  left: 0
  transform: rotate(-90deg) translate(0%, 70%)
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  display: flex
  align-items: flex-end
  position: absolute
  @include media-breakpoint-up(md)
    min-width: 50px
    margin-top: -50px
    transform: none
    left: auto
    right: space(6)
    align-items: flex-end
    top: 50%

.c-d3-map__legend .c-chart-legend__value
  margin-left: auto
  padding-left: space(6)
  font-weight: 700

.c-d3-map__legend .c-chart-legend__item
  margin-right: 0

.c-d3-map__legend--top-right
  position: absolute
  bottom: auto
  top: space(2)
  right: 0
  left: 0
  padding: space(4)
  background: $card-color
  border: 1px solid $brand-primary
  border-radius: 3px
  transform: none
  margin-bottom: 0
  flex-direction: column
  align-items: flex-start
  @include media-breakpoint-up(md)
    max-width: 300px
    top: 50%
    bottom: auto
    left: auto
    right: 0
    transform: translate(0, -50%)

.c-d3-map__legend--top-right p,
.c-d3-map__legend--top-right h4
  // Fixes IE11 overflow bug
  max-width: 100%

.c-d3-map__legend--top-right .c-chart-legend__item
  margin-left: 0
  &:only-child
    margin-bottom: 0

// .c-d3-map__legend--counties,
// .c-d3-map__legend--cities

.c-d3-map__legend--counties
  display: block

.c-d3-map__axis-label,
.c-d3-map__axis text
  color: $gray-700
  fill: $gray-700
  @include font-open-sans

.c-d3-map__label-value
  fill: $white
  @include font-heading($size: 1.25px, $weight: 800)
  transform: translate(.01px, 0)
  @include media-breakpoint-up(md)
    transform: none
    font-size: 1.9px

.c-d3-map__counties .c-d3-map__label-value
  font-size: .8px
  transform: translate(.75px, -1px)
  @include media-breakpoint-up(md)
    transform: none
    font-size: 1.85px

.c-d3-map__cities .c-d3-map__label-value
  font-size: .1px
  @include media-breakpoint-up(md)
    font-size: .25px

.c-d3-map__axis text
  font-size: rem(14px)

.c-d3-map__axis-label
  font-size: rem(16px)

.c-d3-map__tooltip
  position: absolute
  z-index: 100
  opacity: 0
  pointer-events: none
  display: none

.c-d3-map__label-text
  font-weight: 400
  font-size: 50%
