@import '../helpers'

$footer-border-size: 8px
$switch-breakpoint: lg

.c-footer
  position: relative
  display: flex
  top: 100%
  width: 100%
  background: $white
  padding-bottom: $footer-border-size
  overflow: hidden
  min-height: 90px
  &.open
    overflow: visible
  a
    text-decoration: none

.c-footer__content
  border-top: 4px solid $brand-primary
  padding-left: space(3)
  padding-right: space(3)
  display: flex
  @include flex(1, 1, auto)
  flex-wrap: wrap
  align-items: center
  justify-content: center
  @include media-breakpoint-up($switch-breakpoint)
    padding-left: 20px
    padding-right: 20px
    justify-content: flex-start

.c-footer__sud
  display: flex
  min-height: 44px
  background: #FFD18A
  color: #AD312F

.c-footer__sud-alert-icon
  display: flex
  align-items: center
  justify-content: center
  background: #AD312F
  color: #FFD18A
  width: 50px

.c-footer__sud-alert-text
  font-weight: 600
  text-transform: uppercase
  padding: 10px 20px
  width: 100%

.c-footer__brand
  display: flex
  align-items: center
  margin-bottom: 0
  margin-top: 16px
  @include media-breakpoint-up($switch-breakpoint)
    margin-top: 0
    margin-bottom: 6px

.c-footer__link
  display: flex
  align-items: center
  &:hover
    opacity: .9
    text-decoration: none

.c-footer__link-text
  margin-left: 8px
  font-size: 16px
  line-height: 26px
  font-weight: 400
  color: $brand-secondary
  font-weight: 700
  line-height: 1.2

.c-footer__link span
  line-height: 1.2
  display: block
  margin-bottom: 0
  font-weight: bold

.c-footer__nav
  width: 100%
  margin: space(3) 0 space(4)
  display: flex
  align-items: center
  justify-content: center
  @include media-breakpoint-up($switch-breakpoint)
    width: auto
    margin: 0 80px 0 auto

.c-footer__nav ul
  @include list-unstyled
  display: flex
  align-items: center
  gap: 0
  flex-direction: column
  @include media-breakpoint-up($switch-breakpoint)
    flex-direction: row
    gap: 32px
    margin-top: 16px
    margin-bottom: 16px

.c-footer__nav li
  @include media-breakpoint-up($switch-breakpoint)
    margin-bottom: 8px

.c-footer__nav li > a
  font-weight: 500
  color: rgba(0, 0, 0, 0.6)
  padding-left: 0
  padding-right: 0
  &:hover
    color: rgba(0, 0, 0, 0.6)
    text-decoration: underline

.c-footer__nav .nav-item--seperator
  border-right: 1px solid rgba(0, 0, 0, 0.23)
  width: 1px
  align-self: stretch
  margin-left: 16px
  margin-right: 16px
  display: none
  @include media-breakpoint-up($switch-breakpoint)
    display: block

$toggle-size: 40px
.c-footer__sibling-toggle
  position: absolute
  right: 0
  top: - ($toggle-size - $footer-border-size)
  z-index: stack-order(mid)
  button
    height: $toggle-size
    width: $toggle-size
    cursor: pointer
    border: 0
    outline: 0
    display: flex
    align-items: center
    justify-content: center
    text-decoration: none
    border: 2px solid transparent
    &:hover,
    &:focus
      background: $white
      border: 2px solid $brand-primary-d
      text-decoration: none
      &,
      &::before
        color: $brand-primary-d

.c-footer--delaware
  position: absolute
  top: calc(100% - 15px)
  width: 100%
  color: #fff
  border-top: $footer-border-size solid $brand-primary-d
  background-color: #2d3032
  font-size: rem(15)
  line-height: 1.5
  transition: transform .25s ease-in-out
  transform: translateY(0)
  &.open
    position: fixed
    transform: translateY(calc(-100% + #{$footer-border-size}))

.c-footer--delaware p
  font-size: rem(15)

.c-footer--delaware h1
  font-family: 'Open Sans', Helvetica, Arial,sans-serif
  font-weight: 500
  color: #fff
  font-size: rem(40)
  padding-top: rem(20)

.c-footer--delaware hr
  -moz-border-bottom-colors: none
  -moz-border-left-colors: none
  -moz-border-right-colors: none
  -moz-border-top-colors: none
  border-image: none
  border: 1px solid #fff
  margin-bottom: rem(20)
  margin-top: rem(20)

.c-footer--delaware .footer_logo
  float: left
  margin-right: rem(5)
  border: 0
  width: rem(50)
  height: rem(50)

.c-footer--delaware
  a
    text-decoration: none
    color: #fff
    transition: .5s
  a:hover,
  a:focus
    color: $brand-primary-d
    text-decoration: underline

.col-footer-seal
  @include media-breakpoint-down(xs)
    display: none

.toggle-footer-btn
  background-color: $brand-primary-d
  display: block
  float: right
  font: normal normal 25px/40px Arial,Sans-Serif
  color: #ffffff !important
  text-decoration: none
  width: 40px
  height: 40px
  margin-top: -10px
  margin-right: 20px
  text-align: center
  margin-top: 20px
  margin-right: 20px
  text-align: center
  text-decoration: none
  color: #fff!important
  @media (min-width:373px) and (max-width:479px)
    margin-top: 40px
  @media (min-width:480px)
    margin-top: 42px
  @media (min-width:768px)
    margin-top: -10px
    -webkit-margin-before: -5px!important
  @media (max-width: 874px)
    margin-top: -10px !important
  @media (min-width:992px)
    margin-top: -37px
    -webkit-margin-before: -25px!important
  @media (min-width:1200px)
    margin-top: -13px
    -webkit-margin-before: -10px!important
  &:hover
    text-decoration: none
  &:before
    content: '+'
  &.open:before
    content: '-'

.c-footer--delaware .social svg
  width: rem(39)

.footer_logo,
.social_facebook,
.social_facebook img,
.social_facebook img.top:hover,
.social_flickr,
.social_flickr img,
.social_flickr img.top:hover,
.social_rss,
.social_rss img,
.social_twitter,
.social_twitter img,
.social_youtube,
.social_youtube img,
.social_youtube img.top:hover
  width: rem(50)
  height: rem(50)

.footer_logo
  float: left
  margin-right: rem(5)
  border: 0

.wave_text
  display: none

.decreaseFont,
.resetFont,
.increaseFont
  color: #333 !important
  -o-transition: .5s
  -ms-transition: .5s
  -moz-transition: .5s
  -webkit-transition: .5s
  transition: .5s

.c-footer--delaware .btn-default
  background-color: #fff

.c-footer--delaware .btn-default
  border: 1px solid #ccc

.c-footer--delaware .btn-lg
  padding: rem(10) rem(16)
  font-size: rem(18)
  line-height: 1.333
