@import '../helpers'

.c-form__optional
  font-weight: 600
  margin-bottom: space(1)

.c-landing__input.form-control
  @media screen and (max-width: 1236px)
    font-size: rem(12)

.form__actions
  margin: space(4) 0
  display: flex
  align-items: center

fieldset legend,
.form-control-label
  font-weight: 600

input[type='text'],
input[type='number'],
input[type='date']
  background: $card-color
  border: 1px solid $brand-primary
