@import '../helpers'

.l-story
  &.loading
    max-height: 100vh
    overflow: hidden
    .c-banner__action-call
      opacity: 0
  .c-loading-animation
    z-index: stack-order(cosmos)

.l-story__content
  position: relative
  display: flex
  border-top: 5px solid $brand-secondary

.l-story__aside
  display: none
  position: sticky
  z-index: 1000
  top: 0
  max-height: 100vh
  @include flex(0, 1, $story-wayfinder-width)
  min-width: $story-wayfinder-width
  &.active
    display: block
  @include media-breakpoint-up(md)
    display: block

.l-story__chapters
  @include flex(1, 1, calc(100% - #{$story-wayfinder-width}))
  max-width: auto
  display: flex
  flex-direction: column
  max-width: 100%
  @include media-breakpoint-up(md)
    max-width: calc(100% - #{$story-wayfinder-width})

.l-story__chapters img
  border: 0
