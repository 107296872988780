@import '../helpers'

.o-tabs
  border-bottom: 2px solid $gray-200

.o-tabs--full-bleed
  margin-right: space(4, true)
  margin-left: space(4, true)

.tab-content .o-topic-context__content
  background: $white
  padding: space(4)
