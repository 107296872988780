// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Color Variables
// ---------------------------------------------------------------------------
// Variables to extend/update palette provided by Bootstrap
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


$delaware-blue        : #147BB1;
$delaware-true-blue   : #245194; // Color specified by the GIC for State header and footer
$light-blue     : #bcdeeb;
$red            : #942C1B;
$delaware-gray  : #b2aaa4;

$brand-primary  : $delaware-true-blue;
$brand-primary-l: $light-blue;
$brand-primary-d: $delaware-true-blue;
$brand-primary-dd: #1f334f;
$brand-secondary: $red;

// States
$brand-info     : $light-blue;
$brand-info-l   : #E5F2F7;
$brand-danger   : #C24644;
$brand-warning  : #FFD18A;

$white          : #FFFFFF;
$gray-090       : #F9FAFB;
$gray-100       : #F0F1F2;
$gray-200       : #e9ecef;
$gray-300       : #dee2e6;
$gray-400       : #ced4da;
$gray-500       : #adb5bd;
$gray-600       : #6c757d;
$gray-700       : #495057;
$gray-800       : #343a40;
$gray-900       : #212529;
$black          : #000000;

$body-color     : $gray-700;

$primary        : $brand-primary;
$secondary      : $brand-secondary;
/* $success     : $green !default; */
$info           : $brand-info;
$danger         : $brand-danger;
$warning        : $brand-warning;
/* $light       : $gray-100 !default;
$dark           : $gray-800 !default; */

$link-color: #0060f0;

$card-color: $white; // old color: #FCFAF7;
$card-header-bg-color: $white;
$card-footer-link-color: $card-header-bg-color;
$card-sidenote-color: #5c5e61;

$border-color-primary: #E2E2E2;
$border-color-secondary: #E2E2E2;
$border-color-l: lighten($border-color-primary, 25%);

$brand-colors: (
  white: $white,
  black: $black,
  primary: $brand-primary,
  primary-l: $brand-primary-l,
  secondary: $brand-secondary,
  gray-l: $gray-200,
  gray-m: $gray-400,
  gray-d: $gray-600,
);

$level-colors: (
  low: #80BDCE,
  normal: #80BDCE,
  soft: #80BDCE,
  warning: $brand-warning,
  medium: $brand-warning,
  moderately-hard: $brand-warning,
  borderline: $brand-warning,
  danger: $brand-danger,
  high: $brand-danger,
  very-hard: $brand-danger
);

// Charts (pre-covid colors)
$chart-colors: (
  1:  $delaware-blue,      // Blue
  2:  #E3733B,             // Orange
  3:  #4C4282,             // Light Violet
  4:  #66A082,             // Light Green
  5:  #AC005E,             // Magenta
  6:  #80BDCE,             // Light Blue
  7:  #CE9A6B,             // Light Orange
  8:  #2E284F,             // Dark Violet
  9:  #425936,             // Green
  10: #404040,             // Gray
);

$chart-reference-level-colors: (
  1: #BF1B1B,
  2: #C24644,
  3: #FF925C,
  4: #FFD18A,
  5: #80BDCE,
  6: #30A4E3,
) !default;

$chart-axis-color: #B2AAA4;
$chart-axis-label-color: $black;

// Range Slider
$range-slider-clr-primary: $brand-primary;
$range-slider-clr-secondary: #E9E9E9;
$range-slider-clr-disabled: #CCCCCC;
$range-slider-clr-obscured: lighten($range-slider-clr-primary, 20%);
$range-slider-clr-text: $black;
$range-slider-clr-dot-bg: white;
$range-slider-clr-handle-bg: $brand-primary;
$range-slider-clr-handle-bg-active: $brand-secondary;
$range-slider-clr-handle-border: $white;
$range-slider-clr-tooltip-fg: white;
$range-slider-clr-tooltip-bg: #6C6C6C;

// Headings
$heading-secondary-color: $gray-800;

// Metrics
$metric-primary: $brand-primary;
$metric-dark: #1A263C;
$metric-comparison: #484848;
$metric-positive: #7ED321;
$metric-negative: #AD312F;
$metric-neutral:  $gray-300;

// Tabs
$tab-active-color: $gray-900;

// Alerts
$alert-type-colors: (
  warning: (
    primary: $brand-secondary,
    secondary: $brand-warning,
  ),
);

// Health Emergency or Covid Pandemic Dashboard (Version 1)
// TODO: no longer used? Can this be torn out?
$he-colors: (
  primary: #005390,
  primary-l: rgba(20,123,177,0.2),
  primary-d: #1A263C,
  link-color-nav: #4CC1D2,
  link-color-primary: #188B9B,
  canvas-bg: #E6E9EC,
  danger: #DE2249,
  danger-l: #FFA8A8,
  danger-ll: rgba(255,168,168,0.7),
  danger-muted: #D8787A,
  mhc: #2D5552,
  mhc-l: #139f82,
  mhc-ll: rgba(19,159,130,0.7),
  text-dark: #0E394F,
  text-light: $white,
  text-color-d:#444B54,
  text-color-dd: #333D53,
  text-color-l: #FAFBFF,
  border-color: #00A7FF,
  card-bg: #F8F9FA,
  card-border: #AEB4BC,
  border: #D2D4DB,
  metric-color-primary: $metric-dark,
  metric-color-secondary: $metric-dark,
  chart-stroke: #65789C,
  stoplight: (
    #49B9A3,
    #FED454,
    #B30024,
  ),
  info: lighten(#005390, 65%),
);

// Dashboard Colors (Version 2)
$dashboard-colors: (
  canvas: #F2F2F2,
  canvas-l: #FAFAFB,
  card-bg: $white,
  card-border-color: #D9D8D6,
  link: #1576D7, // updated from #1E90FF to be compliant with color contrast standards
  link-hover: #2669AA,
  breadcrumb-active: #6D6D6D,
  gray-1: #767676,
  h1: #1A263C,
  h2: #1576D7, // updated from #1E90FF to be compliant with color contrast standards
  h3: #495057,
  all-caps: #555555,
  metric-color: $metric-dark,
  chart: (
    0: #1E90FF,
    1: #A0DEE7,
    2: #B4AFFF,
    3: #F7C390,
    4: #E5B303,
  ),
  table: (
    0: #1E90FF,
    1: #E5B303,
  ),
  highlight: #FCCF15,
  danger: #D7373F,
  warning: #E5B303,
  info: #1E90FF,
);

$color-ramps: (
  red: #711A0C #942C1B #BA7569 #E6D2CF,
  orange: #C53F02 #FE8E25 #FED483 #FFFFCE,
  blue: #1E66AC #60A4D0 #B5D1E3 #EDF1FF,
  purple: #5F4799 #938FC1 #C4C2DE #F0EEF6,
  green: #20803C #69BC6B #B1E0AA #EAF7E6,
);

$he-colors-charts-comparison: (
  1: #D2D4DB,
  2: #FF4747,
);

$he-colors-charts-status: (
  1: #8B0505,
  2: #FABA68,
  3: #2C9DAE,
);

// Page navbar
$page-navbar-bg: map-get($he-colors, primary-d);
$page-navbar-border-color: #65789C;
$page-navbar-color: $white;
$page-navbar-active-color: map-get($he-colors, danger);
$page-navbar-active-text-color: map-get($he-colors, link-color-nav);
$page-navbar-active-bg: rgba(map-get($he-colors, card-bg), .1);
