@import '../helpers'

$breadcrumb-link-color: $gray-600
$breadcrumb-page-link-color: $brand-primary

.c-breadcrumbs
  display: flex
  align-items: center
  flex-wrap: wrap
  padding-top: space(2)
  padding-bottom: space(2)
  padding-left: 0
  padding-right: space(2)
  list-style-type: none
  margin: 0
  li
    display: flex
    align-items: center

.c-breadcrumb__back
  font-size: rem(14px)
  margin-top: 2px
  margin-right: space(1)
  color: $breadcrumb-link-color

.c-breadcrumb__item
  @include font-default(rem(15px), $breadcrumb-link-color, $lh: line-height(18, 15))
  @include flex(0, 0, auto)
  &,
  a
    text-decoration: none

.c-breadcrumb__item--content
  @include font-default(rem(15px), $breadcrumb-page-link-color, $lh: line-height(18, 15))
  display: flex
  align-items: baseline
  > span
    display: block
    color: get-dash-color(h1)
    font-style: italic
    margin-right: space(2)

.c-breadcrumb__item--current
  @include font-semibold(rem(15px))

.c-breadcrumb__item--warning
  font-weight: font-weight(bold)

.c-breadcrumbs__slash
  margin-right: space(2)
  margin-left: space(2)
  color: $gray-500

.c-breadcrumbs--light
  margin: 0
  padding: 0
  a
    color: $white
    font-weight: 600
    &:hover,
    &:focus
      color: $white

.c-breadcrumbs--dashboard,
.c-breadcrumbs--topic
  padding: 0
  margin-bottom: 0
  li,
  .c-breadcrumb__item
    &.c-breadcrumb__item--current,
    &:last-of-type
      a,
      span
        color: get-dash-color(breadcrumb-active)
        pointer-events: none
  a,
  span
    color: get-dash-color(h1)
    font-size: rem($sm-font-size)
    letter-spacing: .03em
    &.disabled
      font-size: rem($sm-font-size)
      text-transform: none
      pointer-events: none
      font-weight: font-weight(normal)
      letter-spacing: initial
    &.secondary
      text-transform: none
      color: get-dash-color(link) !important
      text-decoration: underline
      pointer-events: auto !important
      @include hover-focus
        color: get-dash-color(link) !important
    @include hover-focus
      color: get-dash-color(link-hover)
  .c-breadcrumbs__slash
    color: #BFBFBF

.c-breadcrumbs--topic
  a
    color: get-dash-color(link)
    font-weight: font-weight(semi-bold)
  li,
  .c-breadcrumb__item
    &:last-of-type
      a
        color: $black
        font-weight: normal
