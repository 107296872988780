@import '../helpers'

// A simple circle as used in the community characteristics page
.c-inline-circle
  display: inline-block
  height: 1rem
  width: 1rem
  border-radius: 50%
  background-color: #000
  flex: 0 0 1rem
