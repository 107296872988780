@use "sass:map"
@import '../helpers'
@import './note_config'

.c-note
  background-color: #fff
  color: rgba(0, 0, 0, 0.87)
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
  border-radius: 4px
  box-shadow: none
  font-size: 14px
  line-height: 20px
  font-weight: 400
  background-color: rgba($black, .02)
  display: flex
  padding: 6px 16px
  color: black
  margin-bottom: 16px
  border-width: 2px
  border-color: rgba($black, .4)
  border-style: solid

.c-note__icon
  margin-right: 12px
  padding: 7px 0
  display: flex
  font-size: 22px
  opacity: 0.9


.c-note__message
  padding: 8px 0

.c-note__title
  font-weight: 600
  margin-bottom: space(1)

.c-note__message-text
  margin-bottom: 0

.c-note__action
  display: flex
  align-items: flex-start
  padding: 4px 0 0 16px
  margin-left: auto
  margin-right: -8px

.c-note__action-url
  margin-right: space(2)

.c-note__dismiss
  display: inline-flex
  align-items: center
  justify-content: center
  position: relative
  box-sizing: border-box
  background-color: transparent
  outline: 0
  border: 0
  margin: 0
  border-radius: 0
  padding: 5px
  cursor: pointer
  user-select: none
  vertical-align: middle
  text-decoration: none
  color: inherit
  text-align: center
  flex: 0 0 auto
  font-size: 22px
  border-radius: 50%
  overflow: visible
  color: rgba(0, 0, 0, 0.54)
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
  text-transform: none
  font-weight: 400
  color: #9e9e9e
  margin-bottom: auto
  margin-left: auto
  &:hover
    color: $delaware-blue

@each $variant-name in map.keys($note-colors)
  .c-note--#{$variant-name}
    $variant: map-get($note-colors, $variant-name)
    $main-color: map-get($variant, border)
    border-color: $main-color
    background-color: map-get($variant, background)
    .c-note__icon
      color: $main-color
    .c-note__message
      color: map-get($variant, text-color)

.c-note--in-card
  border-right-color: $border-color-primary
  border-left-color: $border-color-primary
