// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Custom Bootstrap Variables
// !! v5.1.3
// ---------------------------------------------------------------------------
// Available variables:
// https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_variables.scss
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Colors
@import './colors';
@import '../tools/functions';
@import './spacing';

// Fixes for breaking changes in v4 => v5 upgrade
$utilities: (
  "margin-left": (
    responsive: true,
    property: margin-left,
    class: ml,
    values: map-merge($spacers, (auto: auto))
  ),
  "margin-right": (
    responsive: true,
    property: margin-right,
    class: mr,
    values: map-merge($spacers, (auto: auto))
  ),
  "padding-left": (
    responsive: true,
    property: padding-left,
    class: pl,
    values: map-merge($spacers, (auto: auto))
  ),
  "padding-right": (
    responsive: true,
    property: padding-right,
    class: pr,
    values: map-merge($spacers, (auto: auto))
  ) ,
);

$grid-gutter-width: 20px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  md2: 864px,
  lg: 1200px,
  xl: 1440px
);

$container-max-widths: (
  sm: 720px,
  md: 1040px,
  lg: 1380px,
  xl: 1920px
);

// General
$border-radius: 5px;
$border-color: $border-color-primary;

// Links
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$link-color: $link-color;
$link-hover-color: $brand-secondary;
$link-decoration: underline;
$link-hover-decoration: none;

// Tooltips
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$tooltip-max-width: 400px;
$tooltip-bg: white;
$tooltip-padding-y: .5rem;
$tooltip-padding-x: .75rem;
$tooltip-opacity: 1;
$tooltip-border-radius: 2px;
$tooltip-color: $body-color;

$dropdown-link-color: $link-color;

$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-alt-sans-serif:  "Montserrat", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$input-btn-padding-y: .75rem;
$input-btn-padding-x: 1.15rem;

// Tables
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$table-striped-bg: #f8f8f8;
$table-hover-bg: $gray-100;

// Cards
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$card-spacer-y: 25px;
$card-spacer-x: 20px;
$card-border-color: get-dash-color(card-border-color);

// Modal
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$modal-content-border-width: 0;
$zindex-modal-backdrop:      stack-order(modal);
$zindex-modal:               stack-order(modal)+1;
