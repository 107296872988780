@import '../helpers'

// NOTE:
// Many of the styles are inline so adding the important flag to overide

.gm-style .gm-style-iw-c
  @include tooltip
  padding: space(4) !important

.gm-style-iw-d
  background: $card-color
  overflow: auto !important

.gm-ui-hover-effect img
  display: none !important

.gm-ui-hover-effect
  top: space(3) !important
  right: space(1) !important
  &::after
    content: '\f057'
    @include font-icon
    font-size: 22px
    color: $gray-600

.gm-style .gm-style-iw-t::after
  border-left: 1px solid $brand-primary
  border-bottom: 1px solid $brand-primary
  background: $card-color

