@import '../helpers'

.c-select
  position: relative
  display: inline-flex
  border: 1px solid $gray-500
  border-radius: $border-radius-default
  cursor: pointer
  min-height: 40px
  text-overflow: ellipsis
  &:hover
    border-color: $white
    &::after
      color: $white
  &::after
    @include font-icon(rem(24))
    content: '\f107'
    position: absolute
    right: space(2)
    top: 50%
    transform: translateY(-50%)
    color: $brand-primary
    pointer-events: none

.c-select_selector,
.c-select select
  @include bolden
  color: $brand-primary
  border: 0
  padding: space(2) space(6) space(2) space(2)
  cursor: pointer
  border-radius: $border-radius-default
  outline: none
  -webkit-appearance: none
  -moz-appearance: none
  -ms-appearance: none
  -o-appearance: none
    appearance: none
  &:hover
    background: $brand-primary
    color: $white
  &::-ms-expand
    display: none

.c-select option
  color: $white

.c-select--constrained select
  max-width: 300px
  text-overflow: ellipsis

.c-select--grouped
  &,
  > select
    max-width: 100%
  > select
    text-overflow: ellipsis
