@import '../helpers'

.c-browser-warning
  background: $brand-warning
  padding: space(2)
  text-align: center
  @include font-heading($size: 16px, $color: $brand-secondary)
  width: 100%
  position: absolute
  top: 0
  z-index: 10000
