@import '../helpers.scss'
@import '../tools/mixins/dashboard'

$mobile-nav-bg:  #1A263C

.c-mobile-nav
  min-height: 100vh
  display: flex
  flex-direction: column
  hr
    border-color: $gray-600

.c-mobile-nav__header
  display: flex
  justify-content: space-between
  flex: 0 1 auto
  padding: space(2) space(2) space(1) space(4)
  background: rgba($gray-200, .2)
  margin-bottom: space(3)

.c-mobile-nav__content
  position: relative
  flex: 1
  overflow: auto
  padding-bottom: 5vh

.c-mobile-nav__switcher-trigger
  @include search-icon-before($color: $link-color)
  &:hover
    &::before
      color: $white

.c-mobile-nav__section-heading
  @include dash-card-heading($level: h4)
  position: sticky
  top: 0
  z-index: 50
  background: rgba($white, .9)

.c-mobile-nav__dismiss
  font-size: rem(30)
  padding: 0 space(2) 0 space(4)

.c-mobile-nav__nav .nav
  flex-direction: column

.c-mobile-nav__section-heading,
.c-mobile-nav__nav .nav-link
  padding: space(2) space(4)

.c-mobile-nav__nav .nav-link
  font-weight: font-weight(semibold)
  text-decoration: none
  &:active
    background: rgba($white, .4)
    color: lighten($mobile-nav-bg, 90%)
  &:hover
    background: rgba($white, .1)

.c-mobile-nav--dark
  background: $mobile-nav-bg
  .c-mobile-nav__section-heading,
  .c-mobile-nav__dismiss,
  .c-mobile-nav__nav .nav-link,
  .c-mobile-nav__switcher-trigger
    color: $white

  .c-mobile-nav__switcher-trigger
    @include search-icon-before($color: $white)

  .c-mobile-nav__section-heading
    background: $mobile-nav-bg

  .c-mobile-nav__header
    .c-dash-subnav__location,
    .c-dash-subnav__location-population,
    .c-dash-subnav__location-title
      color: $white

  hr
    border-color: $gray-300

  .c-mobile-nav__header
    background: rgba(get-dash-color(danger), .2)

  .c-mobile-nav__section-heading
    background: $mobile-nav-bg

  .c-mobile-nav__nav .nav-link
    &:active
      background: rgba($white, .4)
      color: lighten($mobile-nav-bg, 90%)
    &:hover
      background: rgba($white, .1)
