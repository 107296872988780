@import '../helpers'

$nav-switch-breakpoint: sm
$dash-subnav-bg: $white
$dash-subnav-color-active: $red
$dash-subnav-color-dropdown-active: #f2e5e3

.c-dash-subnav
  @include topic-container-padding($x: true, $y: false)

.c-dash-subnav__label
  font-size: 16px
  line-height: 24px
  font-weight: 400
  color: $black
  margin-bottom: 6px
  @include media-breakpoint-up(md)
    margin-bottom: 12px

.c-dash-subnav .nav
  align-items: center

.c-dash-subnav__location
  display: flex
  flex-direction: column

.c-dash-subnav__location-title
  margin-bottom: 0

.c-dash-subnav__location-population
  color: $dash-subnav-color-active
  margin-bottom: space(1)

.c-dash-subnav__location-label
  font-size: rem(10px)
  text-transform: uppercase
  margin-bottom: space(1)

.c-dash-subnav__location-population
  span:first-child
    font-weight: font-weight(semibold)

.c-dash-subnav .nav-link
  color: get-dash-color(h1)
  font-size: rem(14px)
  font-weight: font-weight(semibold)
  border-radius: 10px 10px 0px 0px
  padding: space(2) space(3)
  line-height: 1
  background-color: $white
  min-height: 45px
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid rgba($black, 0.1)
  border-bottom: 0
  margin-right: space(2)
  padding-left: space(4)
  padding-right: space(4)

  @include media-breakpoint-up(lg)
    padding-left: space(6)
    padding-right: space(6)
  &,
  &:hover
    text-decoration: none
  &:focus,
  &:hover
    background-color: #F5F5F5
  &.active
    border-color: $dash-subnav-color-active
    background-color: $dash-subnav-color-active
    color: white
  &[aria-expanded="true"]
    background-color: $dash-subnav-color-dropdown-active
    i
      transform: rotate(180deg)
      transform-origin: center


.c-dash-subnav__separator
  font-size: 1.5rem
  border-right: solid 1px $gray-400

.c-dash-subnav__show-on-stuck
  display: none
