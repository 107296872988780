@use "sass:math"

@import '../../helpers'

.c-story-copy
  line-height: 1.25

.c-story-copy--normal,
.c-story-copy--lead,
.c-story-copy--data
  font-weight: 700
  color: $gray-800
  // @include story-copy($color: $gray-800)

.c-story-copy--lead
  margin-top: space(2)
  margin-bottom: space(8)

.c-story-copy--data
  font-weight: 900

@each $name, $color in $chart-colors
  .c-story-copy--data-#{$name},
  .c-story-copy--normal-#{$name}
    color: $color

.c-story-copy--credit,
.c-story-copy--source
  font-style: italic
  font-size: 14px
  line-height: math.div(16, 14)
  margin-top: space(1)

.c-story-copy--constrained
  max-width: $story-max-width

.c-story-copy--normal-gray,
.c-story-copy--gray,
.c-story-copy--data-gray
  color: $gray-600

.c-story-copy--light,
.c-story-copy--data-light,
.c-story-copy--normal-light,
.c-story-copy--credit-light,
.c-story-copy--source-light
  color: $card-color

.c-story-copy--line-height-1
  line-height: 1
