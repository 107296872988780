@import '../../helpers'

.c-dashboard-masthead
  background: #F7FAFF
  padding-bottom: space(4)
  // Match next app or MUI shadow
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%)
  @include media-breakpoint-up(sm)
    padding-bottom: 0

.c-dashboard-masthead__wrapper
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  @include topic-container
  padding-top: space(3)
  padding-bottom: space(3)
  @include media-breakpoint-up(md)
    padding-top: space(4)
    padding-bottom: space(2)

.c-dashboard-masthead  h1
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 300
    font-size: 38px
    line-height: 43px
    color: $black
    margin-bottom: 0
    @include media-breakpoint-up(md)
      font-size: 38px
      line-height: 43px

.c-dashboard-masthead--secondary
    background: #faf4f3

.c-dashboard-masthead__download
  display: inline-flex
  align-items: center
  gap: 6px
  border: 1px solid $link-color
  text-decoration: none
  padding: 5px 15px
  border-radius: 4px
  font-size: 14px
  font-family: 'open sans'
  font-weight: 600
  line-height: 1.75
  span.material-icons
    font-size: 20px

.c-dashboard-masthead--white
    background: #FFFFFF
