@import '../../helpers'

.c-story-section-content
  @include flex(1, 1, auto)
  display: flex
  flex-direction: column
  position: relative

.c-story-section-content__title
  z-index: 100
  padding: space(6) 0 space(4) 0
  @include story-copy
  @include story-content-padding
  @include flex(0, 1, auto)
  font-family: "open sans"
  width: 100%
  max-width: none
  > span
    display: block
  > span:first-child
    color: $gray-700
    line-height: 1
    margin-bottom: space(2)
    font-weight: 400
  > span:empty
    margin: 0
    display: none
  p
    color: $body-color
    &:last-child
      margin-bottom: 0

.c-story-section-content__title
  font-size: 16px
  line-height: 1.1

.c-story-section-content__title .c-story-copy
  margin-bottom: space(1)

.c-story-section-content__title--pull-left
  @include story-container-full-bleed

.c-story-section-content__title--bg-white
  padding-top: space(4)
  padding-bottom: space(4)
  background: $white // linear-gradient(to bottom, rgba($white,1) 80%,rgba($white,0) 100%)
  @include media-breakpoint-up(md)
    padding-top: space(6)
    padding-bottom: space(6)

// .c-story-section-content__title--bg-white

.c-story-section-content__title--bg-white-top
  padding-top: 125px
  background: linear-gradient(to top, rgba($white,1) 80%,rgba($white,0) 100%)

.c-story-section-content__title .c-story-copy
  max-width: $story-max-width

.c-story-section-content__heading
  @include font-montserrat($size: 22px, $weight: 900, $lh: 1.05, $color: rgba($brand-secondary, .95))
  margin: 0 0 space(2) 0
  @include media-breakpoint-up(md)
    font-size: 28px
    line-height: 32px

.c-story-section-content__heading--light
  color: $card-color
  border-bottom-color: $card-color

.c-story-section-content__heading--bordered
  border-bottom: 2px solid $border-color
  padding-bottom: space(3)

.c-story-section-content__context
  max-width: $story-max-width
  margin-bottom: space(2)
  @include font-open-sans($size: 18px, $weight: 600, $lh: 1.25, $color: rgba($gray-700, .95))
  max-width: 80ch

.c-story-section-content__container-1
  background: none

.c-story-section-content__chart
  background: $white
