@use "sass:math"

@import '../../helpers'

$chapter-title-number-size: rem(50px)

.c-chapter-title
  display: flex
  align-items: center
  // position: sticky
  // top: 0
  // z-index: 20
  padding-top: space(2)
  padding-bottom: space(2)
  margin: 0
  background: rgba($brand-secondary, .95)
  z-index: 1000
  padding-left: space(4)
  max-height: 85px
  @include media-breakpoint-up(md)
    padding-left: space(8)
    padding-top: space(3)
    padding-bottom: space(3)

.c-chapter-title__number
  height: $chapter-title-number-size
  width: $chapter-title-number-size
  background: $card-color
  margin-right: space(2)
  @include flex(0, 0, auto)
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  border: 1px solid $brand-primary
  border-radius: 50%
  color: $brand-secondary
  @include font-montserrat($size: 22px, $weight: 900, $lh: math.div(24, 22), $color: $brand-secondary)
  @include media-breakpoint-up(md)
    font-size: 26px
    line-height: math.div(28, 26)

.c-chapter-title__text
  @include font-montserrat($size: 20px, $weight: 900, $lh: (24*0.05), $color: $card-color)
  text-transform: none
  @include media-breakpoint-up(md)
    font-size: 30px
    line-height: math.div(34, 30)

// Target first section intro (below chapter title)
// to remove top border
.c-chapter-title + .o-story-section .c-story-section-intro
  border-top: 0
