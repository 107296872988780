@import '../../helpers'

.tooltip
  z-index: stack-order(tooltip)

.tooltip-inner
  text-align: left
  border: 1px solid $gray-400
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15)
  padding: space(3) space(4)
  border-radius: 4px
  backgroud: $white

@each $dir, $dir-full-name in $directions
  .bs-tooltip-#{$dir-full-name} .arrow::before
    border-#{$dir-full-name}-color: $gray-400

.tooltip p
  margin-bottom: space(2)
  &:empty
    display: none
  &:last-child
    margin-bottom: 0
