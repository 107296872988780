.l-topics
  $text-color: #1A263C
  $text-color-secondary: #484848
  $font-family: Open Sans
  section > h2
    font-weight: 600
    font-size: 20px
    line-height: 27px
    color: $text-color
    text-transform: initial
    font-family: $font-family
  .card-body
    padding: 50px
    display: flex
    flex-direction: column
    .card-title,
    .card-subtitle,
    .card-text
      color: $text-color
    .card-title
      font-weight: 600
      font-size: 25px
      line-hieght: 34px
      margin-bottom: 12px
    .card-subtitle
      font-size: 14px
      line-hieght: 19px
      opacity: 0.75
      margin-bottom: 5px
    .card-text
      font-size: 16px
      line-hieght: 21px
      flex: 1 1 auto
    .card-text--secondary
      color: $text-color-secondary
    .card-title-minor
      margin-bottom: 4px
      font-size: 13px
      line-hieght: 22px
      font-weight: 600
      color: $text-color-secondary
    li
      margin-bottom: 8px
  .card
    .c-image img
      border-top-left-radius: 5px
      border-top-right-radius: 5px
      @media (min-width: 768px)
        border-top-right-radius: 0
        border-bottom-left-radius: 5px
  .card-body--featured
    padding: 25px 40px
    a
      font-weight: 600
  .img-fluid
    min-height: 100%
    object-fit: cover
