@use "sass:math"

@import '../helpers'

.o-topic-context__title
  @include topic-title
  color: $body-color
  align-items: baseline

.o-topic-context__title--full-bleed
  @include topic-title-full-bleed

.o-topic-context__sub-title
  @include h2($size: 'md')
  color: $body-color
  padding-top: space(3)
  padding-bottom: space(2)
  border-top: 1px solid $gray-300
  border-bottom: 1px solid $border-color-secondary
  text-transform: none
  background: $card-color
  margin-bottom: 0

.o-topic-context__title-wrapper
  margin-bottom: space(4)

.o-topic-context__title i
  font-size: rem(38px)
  line-height: 0
  margin-right: space(2)

.o-topic-context__title h2
  @include h2
  margin: 0
  color: $body-color
  font-weight: 600

.o-topic-context__title span
  font-size: rem(16px)
  line-height: math.div(18, 16)
  margin-left: space(2)
  color: $delaware-gray
  text-transform: none

.o-topic-context__title--full-bleed h2
  font-weight: 800

.o-topic-context__content
  // padding: space(4)
  position: relative
  p
    max-width: $max-paragraph-width

.o-topic-context__description
  @include constrained-container
  padding: 0 space(4)
  margin-bottom: space(5)
