@import '../helpers'

$confidence-details-color: $gray-700

.c-confidence-details
  display: flex
  @include list-unstyled
  margin: 0
  padding: 0
  justify-content: center
  flex-wrap: wrap

.c-confidence-details__item
  margin-right: space(2)
  padding-right: space(2)
  display: flex
  align-items: flex-end
  line-height: 1
  color: $confidence-details-color
  font-size: rem(12px)
  white-space: nowrap
  margin: space(1) 0
  &:last-child
    border: 0
    margin-right: 0
    padding-right: 0
  &::before
    @include font-icon
    font-size: rem(11px)
    padding-left: 2px
    padding-right: 2px
    content: ''
    margin-right: 5px
    color: $confidence-details-color

  &.lowerConfidence
    &::before
      content: '\f309'
      border-bottom: 2px solid $confidence-details-color
      padding-bottom: 1px

  &.upperConfidence
    &::before
      content: '\f30c'
      border-top: 2px solid $confidence-details-color
      padding-top: 1px

  &.weightedFrequency
    &::before
      content: '\f5cd'
      font-weight: 600

.c-confidence-details--legend
  padding: space(2) space(4)
  border-top: 2px solid $card-color
