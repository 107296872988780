@import '../../helpers'
@import '../../tools/mixins/dashboard'

$stripe-color: #0000000D

@mixin cell-context($display: block, $white-space: nowrap)
  display: $display
  font-size: $sm-font-size
  color: get-dash-color(gray-1)
  white-space: $white-space
  font-weight: 500

.c-dash-table
  flex: 1 1 100%
  margin-bottom: space(6)
  @include media-breakpoint-up(lg)
    margin-bottom: 0

.c-dash-table__table
  margin-bottom: 0
  thead tr th
    font-size: rem(14px)
    font-weight: font-weight(semibold)
    > span
      @include cell-context
  thead tr th,
  tbody tr td
    padding: rem(15px) rem(30px)
    color: $black
    border-left: 1px solid $border-color
    border-right: 1px solid $border-color
    text-align: center
    &:first-of-type
      text-align: left
  tbody tr td
    padding: rem(8px) rem(30px)
    vertical-align: middle
  tbody tr:last-of-type td
    border-bottom: 1px solid $border-color

.c-dash-table__table.c-dash-table__table--align-left
  thead tr th,
  tbody tr td
    text-align: left

.c-dash-table__table.table-striped
  tbody tr
    &:nth-of-type(even)
      background: $white
    &:nth-of-type(odd)
      background: $stripe-color

.c-dash-table__note p
  margin-top: space(3)
  margin-bottom: 0
  font-size: $sm-font-size
  line-height: $sm-line-height
  color: get-dash-color(gray-1)

.c-dash-table__more-link
  display: flex
  justify-content: center
  min-width: 100%
  font-weight: font-weight(bold)
  text-decoration: none
  span span:nth-of-type(2)
    display: none
  .fas
    transition: .15s transform
  &[aria-expanded="true"]
    .fas
      transform: rotate(180deg)
    span span:nth-of-type(1)
      display: none
    span span:nth-of-type(2)
      display: inline

// Variant "--totals"
// Highlights non-total rows and colors total numbers
.c-dash-table--totals .c-dash-table__table
  thead tr th,
  tbody tr td
    background: rgba($black, .02)
    &:first-of-type,
    &:last-of-type
      background: transparent
  tbody tr td
    &:last-of-type
      font-weight: font-weight(semibold)
  tbody tr:last-of-type td
    border-bottom: 1px solid $border-color
    padding: rem(15px) rem(30px)
    font-weight: font-weight(semibold)
    &:first-of-type,
    &:last-of-type
      font-weight: font-weight(bold)
      color: get-dash-color(link)

// Variant "--comparitive"
// Highlights (blue color) header cells of center columns
.c-dash-table--comparitive .c-dash-table__table
  thead tr th
    color: get-dash-color(link)
    &:first-of-type,
    &:last-of-type
      color: $black

// Variant "--with-percentages"
// .c-dash-table--with-percentages

// Variant "--chart-series-#{index}"
// Highlights title and last row with color from corresponding chart series
@each $color-index, $color in get-dash-color(table)
  .c-dash-table--chart-series-#{$color-index}
    .c-dash-table__title
      font-weight: font-weight(bold)
      color: $color
    &.c-dash-table--totals
      .c-dash-table__table
        tbody tr:last-of-type td
          &,
          &:last-of-type,
          &:first-of-type
            color: $color
            // TODO: We need to ensure the color contrast meets standards
            // Maybe instead of text color we highlight the cells?
            // background-color: $color
            // color: determine-color-for-best-contrast($color)
    .c-dash-table__more-link
      color: $color
      &:hover,
      &:focus
        color: darken($color, 5%)

// Variant "--striped"
// Even: white | Odd: gray
.c-dash-table--striped
  tbody tr
    &:nth-of-type(even)
      background: $white
    &:nth-of-type(odd)
      background: $stripe-color


// Variant "--striped-by-2"
// Striped in groups of 2
.c-dash-table--striped-by-2
  tbody tr
    background: $white
    &:nth-of-type(4n+1),
    &:nth-of-type(4n+2)
      background: $stripe-color

// Variant "--highlight-col-#{i}"
// .c-dash-table--highlight-col-1

// Variant "--chart-series-#{index}"
// Highlights col
@for $i from 1 through 5
  .c-dash-table--highlight-col-#{$i}
    thead tr th:nth-of-type(#{$i})
      background: rgba(get-dash-color(link), .1)
      font-weight: bold
    tbody tr td:nth-of-type(#{$i})
      background: rgba(get-dash-color(link), .075)
