@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-table-with-chart
  margin-bottom: space(6)

.c-table-with-chart__header
  margin-bottom: space(3)

// .c-table-with-chart__header-content

h2.c-table-with-chart__title
  margin-bottom: space(2)
  margin-right: space(4)
  flex: 1 1 100%
  @include media-breakpoint-up(md)
    flex: 1 1 75%
    max-width: 75%

.c-table-with-chart__nav
  @include list-unstyled
  display: flex
  margin-bottom: 0
  flex: 0 1 auto
  margin-bottom: space(2)
  li
    display: flex
    align-items: center
    margin-right: space(2)
    &::after
      content: ''
      height: 75%
      width: 2px
      background: lighten(get-dash-color(gray-1), 25%)
      border-radius: 2px
      margin-left: space(2)
    &:last-child
      margin: 0
      padding: 0
      &::after
        display: none

.c-table-with-chart__nav-link
  color: get-dash-color(gray-1)
  text-transform: uppercase
  font-size: 16px
  letter-spacing: .75px
  &.active
    color: get-dash-color(link)
    pointer-events: none
    text-decoration: none
    font-weight: 600

.c-table-with-chart__wrapper
  margin-bottom: space(6)

.c-table-with-chart__content
  display: none
  align-items: stretch
  &.active
    display: flex
  > div
    display: flex
