@import '../helpers'

.c-tab
  border: 0

@each $level-name, $color in $level-colors
  .c-tab--#{$level-name} a
    $lighten-factor: 15%
    @if $level-name == high or $level-name == danger
      $lighten-factor:  40%
    @else if $level-name == normal
      $lighten-factor:  30%
    $tab-color: lighten($color, $lighten-factor)
    background-color: $tab-color
    border: 2px solid $tab-color
    border-bottom: 0
    &.active
      border: 2px solid $color
      border-bottom: 0
      background-color: $tab-color

.c-tab__link,
.nav-tabs .nav-link
  margin-bottom: -2px
  border: 0
  border-bottom: 2px solid $gray-200
  text-decoration: none
  @include h3
  color: $gray-600
  &:focus,
  &:hover
    border-color: $brand-primary
    color: $brand-primary
  &.active
    border-color: $brand-secondary
    color: $tab-active-color
    font-weight: font-weight(bold)
  &.active:hover
    border-color: $tab-active-color
