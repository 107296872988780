@mixin dashboard-card {
  background: $white;
  border: solid 1px $card-border-color;
  border-radius: $card-border-radius;
}

@mixin dashboard-card-title($font-size: rem($h1-font-size-mobile)) {
  @include font-open-sans($size: $font-size, $color: dash-color(text-color-d), $weight: font-weight(semibold));
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin dash-card-heading($level: false) {
  @if $level == h1 {
    margin-bottom: space(2);
  }  @else if $level == h2 {
    font-size: rem($lg-font-size-mobile);
    color: get-dash-color(h2);
    text-transform: uppercase;
    margin-bottom: space(3);
    letter-spacing: .72px;
    @include media-breakpoint-up(md) {
      font-size: rem($lg-font-size);
    }
  }@else if $level == h3 {
    font-size: rem(20);
    font-weight: font-weight(normal);
    color: get-dash-color(h1);
    margin-bottom: space(2);
  }  @else if $level == h4 {
    font-size: rem(20);
    line-height: 28px;
    font-weight: font-weight(bold);
    color: get-dash-color(h1);
    margin-bottom: space(2);
    text-transform: none;
    @include media-breakpoint-up(md) {
      font-size: rem(22);
      line-height: 30px;
    }
  }@else if $level == h5 {
    font-size: rem(16);
    line-height: 22px;
    font-weight: font-weight(bold);
    color: get-dash-color(h1);
    margin-bottom: space(1);
    text-transform: none;
    @include media-breakpoint-up(md) {
      font-size: rem(18);
      line-height: 24px;
    }
  }@else {
    @include font-open-sans($size: rem($h1-font-size-mobile), $color: get-dash-color(h1), $weight: font-weight(semibold));
    @include media-breakpoint-up(md) {
      font-size: $h1-font-size;
    }
  }
}

@mixin dashboard_cta {
  $default-bg: $white;
  $default-border-color: darken(dash-color(card-bg), 5%);
  background: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: none;
  }
  span {
    @include flex(1, 1, auto);
    background: $default-bg;
    border: 1px solid $default-border-color;
    align-self: stretch;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: space(1) space(4);
    font-weight: 600;
    letter-spacing: .03em;
  }
  span:last-child {
    padding: 0 space(2);
    margin-left: 4px;
    font-size: 20px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:focus,
  &:hover {
    span {
      background: darken($default-bg, 5%);
      border-color: $default-border-color;
    }
  }

  &--danger {
    span {
      background: dash-color(danger);
      color: $white;
      border: 1px solid dash-color(danger-l);
    }
    &:focus,
    &:hover {
      span {
        background: lighten(dash-color(danger), 5%);
        border-color: dash-color(danger-ll);
      }
    }
  }

  &--info {
    span {
      background: dash-color(info);
      color: $black;
      border: 1px solid darken(dash-color(info), 5%);
    }
    &:focus,
    &:hover {
      span {
        background: lighten(dash-color(info), 5%);
        border-color: darken(dash-color(info), 10%);
      }
    }
  }
  &--mhc {
    span {
      background: dash-color(mhc);
      color: $white;
      border: 1px solid dash-color(mhc-l);
    }
    &:focus,
    &:hover {
      span {
        background: lighten(dash-color(mhc), 5%);
        border-color: dash-color(mhc-ll);
      }
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

@mixin dasboard-modal {
  .modal-title {
    @include h4;
  }

  .modal-title.modal-title__sm {
    font-size: rem(12px);
    font-weight: bold;
    @include media-breakpoint-up(md) {
      font-size: rem(14px);
    }
  }

  .modal-header {
    background: $gray-090;
    border-bottom: 0;
  }
  .modal-content {
    border-color: #d4d8dc;
    background: $gray-090;
    border-radius: 0;
    a {
      font-weight: 600;
      color: dash-color(link-color-primary);
      &:hover {
        color: darken(dash-color(link-color-primary), 5%);
      }
    }
  }
  .modal-body--restricted-height {
    @include scrollbar($width: 5px, $thumb-color: dash-color(link-color-nav));
  }
}

@mixin dashboard-tooltip {
  $border-color: dash-color(border);
  .tooltip-inner {
    box-shadow: 3px 5px 11px 0 rgba(77,85,103,0.12);
    background: dash-color(card-bg);
    border-color: #d4d8dc;
    font-size: 1rem;
    line-height: 1.3;
    padding: space(2);
    border-radius: 0;
    text-align: left;
    p {
      margin-bottom: 0;
    }
    h4 {
      font-size: rem(16px);
      line-height: 18px;
    }
  }
  @each $dir, $dir-full-name in $directions {
    .bs-tooltip-#{$dir-full-name} .arrow::before {
      border-#{$dir-full-name}-color: $border-color;
    }
  }
}

@mixin stoplight-coloring($base-selector: selector, $prop: background-color, $update-text-color: false) {
  $stoplight-colors: dash-color(stoplight);
  @for $i from 1 through length($stoplight-colors) {
    $color: nth($stoplight-colors, $i);
    .#{$base-selector}#{$i} {
      #{$prop}: $color;
      @if ($update-text-color == false) {
        color: determine-color-for-best-contrast($color);
      }
    }
  }
}
