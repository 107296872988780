@import '../../helpers'

$primary-slat-text-color: #1E66AC
$warning-slat-text-color: #FE8E25
$primary-variant: ( border: #1E66AC4D, bg: #1E66AC0D, bg-hover: null, text: $primary-slat-text-color )
$primary-bright-variant: ( border: #1E66AC4D, bg: #F8FCFF, bg-hover: $white, text: $primary-slat-text-color )
$warning-variant: ( border: #FE8E254D, bg: #FE8E250D, bg-hover: null, text: $warning-slat-text-color )
$warning-bright-variant: ( border: #FE8E254D, bg: #FFFAF5, bg-hover: $white, text: $warning-slat-text-color )
$slat-variants: ( primary: $primary-variant, primarylight: $primary-bright-variant, warning: $warning-variant, warninglight: $warning-bright-variant )

@mixin slat-border($color, $size: 1px)
  box-shadow: inset 0 0 0 $size $color

.c-dash-slat
  @include slat-border(get-dash-color(card-border-color))
  border-radius: $border-radius
  display: flex
  flex-direction: column
  flex: 1 1 auto
  transition: .25s box-shadow ease-in-out, .25s baackground ease-in-out
  background: $white
  height: 100%
  @each $variant-name, $variant in $slat-variants
    $bg: map-get($variant, bg)
    $border-color: map-get($variant, border)
    $border-color-hover: map-get($variant, bg-hover)
    @if $border-color-hover == null
      $border-color-hover: rgba($bg, .085)
    $text-color: map-get($variant, text)
    &--#{$variant-name}
      background: $bg
      @include slat-border($border-color)
      .c-dash-slat__title
        color: $text-color
      &.c-dash-slat--linked
        &:hover,
        &:focus
          @include slat-border(rgba($border-color, .25), $size: 2px)
          background: $border-color-hover
      .c-dash-slat__link-icon
        color: $text-color

.c-dash-slat--linked
  cursor: pointer
  text-decoration: none !important
  &:hover,
  &:focus
    &,
    .c-dash-slat__title,
    .c-dash-slat__value,
    .c-dash-slat__label
      text-decoration: none !important

.c-dash-slat__content
  display: flex
  flex: 1 1 auto
  .c-dash-slat__value-block
    flex-direction: column
    justify-content: space-between
    margin-top: auto
    &:not([class*="col"])
      flex: 1 1 100%

.c-dash-slat__value-block
  display: flex
  flex-direction: column
  justify-content: center
  &:not([class*="col"])
    width: 100%

.c-dash-slat__header
  display: flex
  align-items: center
  margin-bottom: space(4)

.c-dash-slat__title
  @include font-open-sans($size: rem(16), $color: get-dash-color(h1), $weight: font-weight(bold),  $lh: 22px)
  flex-grow: 0
  margin-right: space(2)
  align-items: center
  display: flex
  .c-tooltip-icon i
    font-size: rem(14)

.c-dash-slat__date
  background: $gray-200
  padding: space(1) space(2)
  border-radius: 50px

.c-dash-slat__value-wrapper,
.c-dash-slat__value-container
  display: flex
  flex-wrap: wrap
  flex-grow: 1

.c-dash-slat__value-wrapper
  flex-direction: column
  justify-content: center
  flex-grow: 1

.c-dash-slat__value-container
  align-items: flex-end
  margin-top: space(1)
  margin-bottom: space(1)

.c-dash-slat__value-content span
  &:not([class*=tooltip])
    @include font-open-sans($size: rem(25), $color: get-dash-color(h1), $weight: font-weight(normal),  $lh: 34px)

.c-dash-slat__value
  @include font-open-sans($size: rem(50), $color: get-dash-color(h1), $weight: font-weight(semibold),  $lh: 68px)
  margin-right: space(1)
  line-height: 1

.c-dash-slat__value--secondary
  @include font-open-sans($size: rem(32), $color: get-dash-color(h1), $weight: font-weight(bold),  $lh: 43px)

.c-dash-slat__label
  color: get-dash-color(h1)
  display: inline-flex

.c-dash-slat__v-center
  display: flex
  align-items: center
  flex-wrap: wrap
  flex-grow: 1


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Many values
// several values presented inline
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.c-dash-slat--many-values
  display: flex
  border-right: 0
  border-left: 0
  border-radius: 0
  box-shadow: none
  border-top: 1px solid $gray-300
  border-bottom: 1px solid $gray-300
  .c-dash-slat__value-block
    padding: space(4)
    &:last-of-type
      border: 0
    @include media-breakpoint-up(md)
      border-right: 1px solid get-dash-color(card-border-color)
      &:last-child
        border-right: 0
  .c-dash-slat__value
    margin-top: auto
    margin-right: space(1)
  .c-dash-slat__value
    margin-top: auto
    @include font-open-sans($size: rem(32), $color: get-dash-color(h1), $weight: font-weight(bold),  $lh: 43px)
    margin-right: space(1)

.c-dash-slat--no-border
  box-shadow: none

.c-dash-slat__link-icon
  display: flex
  align-items: center
  font-size: rem(25)
  margin-left: space(2)


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Topic Variation
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.c-dash-slat--topic__value
  color: $brand-primary
  font-size: 1.75rem
  font-weight: bold

.c-dash-slat--topic__value-icon
  height: 1.5rem
  width: 1.5rem

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Column-layout Variation
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.c-dash-slat--col
  flex-direction: column
