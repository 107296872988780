@import '../helpers'

.c-metric-value
  @include data-metric

.c-metric-value--primary
  color: $brand-primary
  margin-right: space(2)

.c-metric-value--comparison
  @include data-metric-comparison
  color: $metric-comparison
  @include flex(0, 0, auto)
  margin-top: 2px
  align-self: baseline

.c-metric-value--light
  color: $card-bg

$i: 0
@each $name, $color in $chart-colors
  .c-metric-value--#{$i}
    color: $color
  $i: $i + 1

.c-metric__unit
  @include font-semibold(rem(13px), $color: $gray-900)
  margin-left: space(1)

.c-metric__unit--lg
  @include font-default(rem(16px), $color: $gray-900)
  margin-left: rem(3px)

// .c-metric__unit--comparison

.c-metric__na
  @include font-default(rem(16px), $color: $gray-900)
