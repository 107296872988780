@import '../helpers'

// .c-stacked-bar
.c-stacked-bar__segments
  background: $gray-300

.c-stacked-bar__segments,
.c-stacked-bar__labels
  display: flex
  margin-bottom: space(2)

.c-stacked-bar__segment,
.c-stacked-bar__label
  flex: 1 1 33.33333333333333%

.c-stacked-bar__segment
  height: $stacked-bar-height
  position: relative
  &:first-of-type
    border-top-left-radius: $stacked-bar-border-radius
    border-bottom-left-radius: $stacked-bar-border-radius
  &:last-of-type
    border-top-right-radius: $stacked-bar-border-radius
    border-bottom-right-radius: $stacked-bar-border-radius
  &::before,
  &::after
    content: ''
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    height: $stacked-bar-selected-size
    width: $stacked-bar-selected-size
    border-radius: 50%
    background: transparent
  &::before
    border: 2px solid transparent
    z-index: 2
  &::after
    height: $stacked-bar-selected-size - $stacked-bar-selected-size-offset
    width: $stacked-bar-selected-size - $stacked-bar-selected-size-offset
    z-index: 3
  @each $name, $color in $level-colors
    &--#{$name}
      background: $color
      &.selected
        &::before
          border-color: $color
          background: $white
        &::after
          background: darken($color, 5%)

// .c-stacked-bar__labels

.c-stacked-bar__label
  @include font-default(13px, $body-color, $lh: line-height(18, 13))
  text-align: center
  padding-left: space(3)
  padding-right: space(3)

.c-stacked-bar__label-text
  margin-bottom: space(1)
  border-bottom: 1px solid $gray-200
  padding-bottom: space(1)
  &.selected
    @include bolden

.c-stacked-bar__label-text--description
  font-size: 12px
  color: $gray-800
