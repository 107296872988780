@import '../helpers'

.c-percent-bar
  height: $percent-bar-height
  background: $gray-200
  width: 100%
  display: flex
  border-radius: $border-radius-default
  overflow: hidden
  margin: 3px 5px
  &:first-of-type
    margin-left: 0
  &:last-of-type
    margin-right: 0

.c-percent-bar--comparison
  border: 1px solid $metric-comparison

.c-percent-bar__segment
  background: map-get($chart-colors, 1)
  @each $name, $color in $chart-colors
    &--#{$name}
      background: $color

.c-percent-bar__segment--primary
  background: $metric-primary

.c-percent-bar__segment--comparison
  background: $metric-comparison
