@import '../../helpers'

.c-chapter-section-intro
  @include flex(1, 1, auto)
  position: relative
  display: flex
  align-items: flex-start

.c-chapter-section-intro__copy
  @include flex(0, 1, 100%)
  padding: space(4)
  @include story-content-padding
  background: rgba($card-bg, .9)
  border-top: 1px solid $brand-primary
  border-bottom: 1px solid $brand-primary
  z-index: 10
  margin-top: 40%
  transform: translateY(-50%)

.c-chapter-section-intro__copy p
  max-width: $story-max-width
  @include font-montserrat($size: 22px, $weight: 600, $lh: 1.2, $color: $brand-primary)
  margin: 0

.c-chapter-section-intro__media
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 1

.c-chapter-section-intro__media img,
.c-chapter-section-intro__media video
  height: 100%
  width: 100%
  object-fit: cover
  font-family: 'object-fit: cover;'
