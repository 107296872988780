@use "sass:math"

@import '../helpers'

.c-topic-note
  padding: space(2) space(4)
  background: $card-color
  border-bottom: 2px solid $white

.c-topic-note--full-bleed
  @include topic-full-bleed

.c-topic-note--bordered
  margin-top: space(4)
  padding-top: space(4)
  border-top: 2px solid $border-color-secondary

.c-topic-note__wrapper
  max-width: $max-container-width
  display: flex
  align-items: baseline

.c-topic-note__wrapper
  &::before
    @include font-icon
    content: '\f249'
    margin-right: space(2)
    line-height: 1
    color: $body-color
    font-size: rem(18px)
.c-topic-note__wrapper--no-icon
  &::before
    content: ''
    display: none

.c-topic-note--warning .c-topic-note__wrapper
  &::before
    color: $brand-danger

// .c-topic-note__message
.c-topic-note__lead
  font-weight: 600
  font-size: rem(16px)
  line-height: math.div(18, 16)
  margin-bottom: space(1)
.c-topic-note__lead--all-caps
  text-transform: uppercase

.c-topic-note--warning .c-topic-note__lead
  color: $brand-danger

.c-topic-note__body
  @include font-semibold
  margin-bottom: 0

.c-topic-note--warning .c-topic-note__body
  color: $brand-danger
