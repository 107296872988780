@import '../helpers'

.c-more-below
  position: fixed
  background: $brand-primary
  left: $side-nav-width
  bottom: 5vh
  transform: translateX(calc(-100% - 10px))
  padding: space(2)
  color: $white
  border-radius: 3px
  transition: .5s opacity
  opacity: 1
  &::after
    left: 100%
    top: 50%
    border: solid transparent
    content: ' '
    height: 0
    width: 0
    position: absolute
    pointer-events: none
    border-left-color: $brand-primary
    border-width: 10px
    margin-top: -10px
  &.hidden
    opacity: 0
    pointer-events: none

.c-more-below__icon
  font-size: rem(16px)
  margin-left: space(2)
  animation: up-and-down
  animation-duration: 1s
  animation-iteration-count: infinite

@keyframes up-and-down
  0%
    transform: translateY(0)
  25%
    transform: translateY(2px)
  50%
    transform: translateY(0)
  75%
    transform: translateY(-2px)
  100%
    transform: translateY(0)
