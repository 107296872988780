@mixin topic-container-padding($x: true, $y: true)
  @if ($x == true)
    padding-left: space(3)
    padding-right: space(3)
  @if ($y == true)
    padding-top: space(2)
    padding-bottom: space(2)
  @include media-breakpoint-up(lg)
    @if ($x == true)
      padding-left: space(4)
      padding-right: space(4)
    @if ($y == true)
      padding-top: space(2)
      padding-bottom: space(2)

@mixin topic-container
  max-width: map-get($container-max-widths, lg)
  @include topic-container-padding
