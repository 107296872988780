// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Typography
// ---------------------------------------------------------------------------
// Mixins for each font-face their fonts
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

@import
  './variables',
  '../tools';

// copied from https://engageinteractive.co.uk/blog/top-10-scss-mixins
@mixin font-open-sans($size: $font-body-size, $color: false, $weight: false,  $lh: false) {
  font-family: $font-family-sans-serif;
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeelegibility;
}

@mixin font-montserrat($size: $font-body-size, $color: false, $weight: false,  $lh: false) {
  font-family: $font-family-alt-sans-serif;
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
  letter-spacing: .01em;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeelegibility;
}

@mixin font-default($size: false, $color: false, $lh: false, $heading: false) {
  @if ($heading) {
    @include font-montserrat($size: $size, $weight: font-weight(normal), $color: $color, $lh: $lh);
  } @else {
    @include font-open-sans($size: $size, $weight: font-weight(normal), $color: $color, $lh: $lh);
  }
}

@mixin font-light($size: false, $color: false, $lh: false) {
  @include font-open-sans($size: $size, $weight: font-weight(light), $color: $color, $lh: $lh);
}

@mixin font-semibold($size: false, $color: false, $lh: false, $heading: false) {
  @if ($heading) {
    @include font-montserrat($size: $size, $weight: font-weight(semibold), $color: $color, $lh: $lh);
  } @else {
    @include font-open-sans($size: $size, $weight: font-weight(semibold), $color: $color, $lh: $lh);
  }
}

@mixin font-bold($size: false, $color: false, $lh: false, $font: false, $heading: false) {
  @if ($heading) {
    @include font-montserrat($size: $size, $weight: font-weight(bold), $color: $color, $lh: $lh);
  } @else {
    @include font-open-sans($size: $size, $weight: font-weight(bold), $color: $color, $lh: $lh);
  }
}

@mixin font-extrabold($size: false, $color: false, $lh: false, $font: false, $heading: false) {
  @include font-open-sans($size: $size, $weight: font-weight(extrabold), $color: $color, $lh: $lh);
}

@mixin font-base($color: $body-color) {
  @include font-default($color: $color);
}

@mixin font-heading($size: false, $color: false, $lh: false, $weight: font-weight(bold)) {
  @include font-open-sans($size: $size, $weight: $weight, $color: $color, $lh: $lh);
}

@mixin h1 {
  @include font-heading(rem(45px), $color: $brand-secondary, $weight: font-weight(extrabold), $lh: line-height(58, 45))
}

@mixin h2($size:'md') {
  $sizes: (
    'sm': 20px,
    'md': 32px,
    'lg': 40px,
  );
  $size: map-get($sizes, $size);
  @include font-heading(rem($size), $color: $black, $weight: font-weight(bold), $lh: line-height(strip-unit($size)+4, strip-unit($size)));
  text-transform: uppercase;
}

@mixin h3 {
  @include font-heading(rem(24px), $gray-800, $weight: font-weight(semibold), $lh: line-height(22, 18));
}

@mixin h4 {
  @include font-bold(rem(20px), $gray-700, $lh: line-height(22, 18), $heading: true);
}

@mixin h5 {
  @include font-bold(rem(16px), $body-color, $lh: line-height(20, 16), $heading: false);
}

@mixin default-body-font($size: $font-body-size, $color: $body-color) {
  @if ($use-light-font) {
    @include font-light($size: $size, $color: $color)
  } @else {
    @include font-default($size: $size, $color: $color)
  }
}

@mixin data-metric($size: rem(28), $color: $body-color) {
  @include font-bold($size, $color: $color, $lh: line-height(28, 28));
  display: flex;
  align-items: baseline;
}

@mixin data-metric-comparison($size: rem(14), $color: $metric-comparison, $border: true, $bg: false) {
  @include font-bold($size, $color: $color, $lh: line-height(14, 13));
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: baseline;
  @if ($border) { border: 1px solid $color; }
  @if ($bg) { background: $bg; }
}

@mixin font-icon($size: rem(28), $weight: 800) {
  font-family: 'Font Awesome 6 Free';
  font-size: $size;
  font-weight: $weight;
}

@mixin addendum {
  display: inline-flex;
  align-items: center;
  margin-left: space(1);
  @content;
  > span {
    font-size: 13px;
    line-height: line-height(16, 13);
    display: flex;
    align-items: baseline;
    padding-right: space(2);
    margin-right: space(2);
    border-right: 1px solid $border-color;
    color: $gray-600;
    &:last-of-type {
      padding-right: 0;
      margin-right: 0;
      border-right: 0;
    }
  }
}

@mixin bolden {
  letter-spacing: .03em;
  font-weight: 700;
}
