@use "sass:math"

@import '../../helpers'

.c-story-section-intro
  border-top: 5px solid $brand-primary
  border-bottom: 5px solid $brand-primary
  padding: space(4)
  margin: 0 0 2px 0
  @include story-content-padding
  background: $card-color

.c-story-section-intro--subtle
  background: $border-color-secondary
  border-top-color: $white

.c-story-section-intro__text
  max-width: $story-max-width
  @include font-montserrat($size: 30px, $weight: 600, $lh: math.div(34, 30), $color: $gray-900)

.c-story-section-intro--subtle .c-story-section-intro__text
  color: $brand-secondary
