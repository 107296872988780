@import '../helpers'

.c-chart
  position: relative
  // Originally applied this to hide chart SVG elements when they are animated
  // in (they are visible because we removed the clip-masks that hide data points)
  // TODO: come up with a better solution to hide content but keep points
  // visible
  // overflow: hidden
  background-color: $card-color
  padding-bottom: space(4)
  min-height: rem(600px)

.c-chart--flex-height
  min-height: auto

.c-chart--compact-height
  min-height: auto
  padding-bottom: 0

.c-chart--loading
  min-height: rem(150px)

.c-chart__header
  margin-bottom: space(4)

.c-chart__title-wrapper
  display: flex
  align-items: center
  flex-wrap: wrap
  padding: 0 space(4)
  background: $white
  margin-bottom: spacce(2)

.c-chart__title
  margin-bottom: 0

.c-chart__title-content
  margin-top: space(2)
  padding: 0 space(4)

.c-chart__title-content p
  &:last-of-type
    margin-bottom: 0

.c-chart__title-addendum
  @include addendum
    margin-top: space(1)

.c-chart__title-addendum--right
  @include media-breakpoint-up(md)
    margin-left: auto

.c-chart__container,
.c-chart__levels
  opacity: 1
  @include transition($prop: transition)

.c-chart__container--loading
  opacity: .1

.c-chart__container svg
  padding-top: space(2)
  padding-bottom: space(2)
  overflow: visible !important // overide inline styles added by charting lib

.c-chart__max-value.bb-ygrid-line
  text
    @include font-semibold(rem(14px))
    fill: $brand-danger
  line
    stroke: $brand-danger
    stroke-width: 3px

.c-chart__levels--loading
  opacity: 0

// Dashboard

.c-chart--dashboard
  padding: 0
  background: none
  border: 1px solid dash-color(border-color)
  .c-chart__title-wrapper
    background: none
    border-bottom: 1px solid dash-color(primary-l)
