@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-dashboard-title
  @include font-semibold( rem(26px), $lh: 36px, $color: get-dash-color(h1) )

.c-dashboard-title i
  margin-right: space(2)

.c-dashboard-title h2
  margin-bottom: 0
  text-transform: none

.c-dashboard-title--h2
  @include font-semibold( rem(30px), $lh: 36px, $color: get-dash-color(h1) )
  margin-bottom: space(6)
  text-transform: none

.c-dashboard-title--line
  display: flex
  align-items: center
  &::after
    content: ''
    height: 1px
    flex: 1 1 auto
    margin-left: space(4)
    background: get-dash-color(h1)

.c-dashboard-title span
  @include font-default( rem(18px), $lh: 22px, $color: $gray-700 )
  margin-left: space(3)

.c-dashboard-report-dates
  margin-left: auto
  margin: 0px
  font-size: 12px
  line-height: 16px
  font-weight: 400
  font-family: Open Sans, sans-serif
  color: rgb(97, 97, 97)