@mixin story-copy($color: $white) {
  max-width: $story-max-width;
  @include font-montserrat($size: 16px, $weight: 500, $lh: 1.3, $color: $color);
  &:last-child {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(md) {
    font-size: 22px;
    line-height: 1.2;
  }
  @include media-breakpoint-up(md) {
    font-size: 28px;
    line-height: 1.2;
  }
}

@mixin story-content-padding($direction: left) {
  padding-left: space(4);
  padding-right: space(4);
  @include media-breakpoint-up(lg) {
    padding-#{$direction}: $story-content-padding;
  }
}

@mixin story-container-full-bleed {
  padding-right: space(4);
  padding-left: space(4);
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-left: $story-content-padding-negative;
    padding-left: $story-content-padding;
    margin-right: -(#{space(4)});
  }
}
