@import '../helpers'

.c-topic-mobile-utility
  display: flex
  flex-direction: column
  width: calc(100% + #{space(4)})
  background: $white
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none

.c-topic-mobile-utility__title
  font-size: rem(20px)
  @include line-height(24, 20)
  padding: space(1) space(2) 0 space(2)

.c-topic-mobile-utility__nav
  display: flex
  align-items: center
  background: $brand-primary
  border-top: 1px solid $white
  border-bottom: 1px solid $white

.c-topic-mobile-utility__action
  color: $white
  &:hover
    color: $white
.c-topic-mobile-utility__action i
  font-size: rem(18px)

.c-topic-mobile-utility__action a
  color: $white
  display: flex
  align-items: baseline
  text-decoration: none
  &:hover
    color: $white
  i
    margin-left: space(1)
  &.open
    i
      transform: rotate(180deg)

.c-topic-mobile-utility__action--right
  margin-left: auto
  padding-right: space(2)
