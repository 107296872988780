@import '../helpers'

.c-app-alert
  width: 100%

.c-app-alert--info
  background: $gray-300
  color: $black

.c-app-alert--warning
  background: $brand-warning
  color: $black

.c-app-alert__content
  margin: 0 auto
  display: flex
  justify-content: space-between
  align-items: center

.c-app-alert__title
  @include font-bold(rem(16px), $black, $lh: line-height(22, 16))
  margin-bottom: 0

.c-app-alert span,
.c-app-alert a
  @include font-semibold(rem(14px), $black, $lh: line-height(20, 14))

.c-app-alert a
  text-decoration: underline
  &:hover
    text-decoration: none

$breakpoints: xs sm md md2 lg xl
.c-app-alert__breakpoints
  display: none
  @include media-breakpoint-up(sm)
    display: flex
  .breakpoint
    height: 25px
    width: 35px
    border: 1px solid $gray-500
    margin-right: 3px
    border-radius: 20px
    text-transform: uppercase
    font-size: rem(12)
    line-height: 1
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    transition: .25s ease-in-out
    @each $b in $breakpoints
      @include media-breakpoint-only($b)
        &.#{$b}
          background: $primary !important
          color: determine-color-for-best-contrast($primary) !important
          font-weight: bold !important
          border-color: $primary !important
