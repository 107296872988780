@use "sass:math"

@import '../helpers'
@import '../tools/mixins/dashboard'

$summary-metric-card-min-height: 175px

.c-summary-metric
  @include dashboard-card
  margin-bottom: space(4)
  padding: space(2) space(3)
  display: flex
  flex-direction: column
  justify-content: center

.c-summary-metric__label
  font-size: rem(14px)
  line-height: math.div(18, 14)
  font-weight: 600
  margin-bottom: space(1)

.c-summary-metric__label--lg
  font-size: rem(16px)
  line-height: math.div(20, 16)
  margin-bottom: space(1)

.c-summary-metric__label--secondary
  font-style: italic

.c-summary-metric__value-label
  @include font-light(rem(16px))
  text-transform: uppercase

.c-summary-metric__label-value-group
  margin: 0
  display: block

.c-summary-metric__group-item--secondary .c-summary-metric__value
  color: #4D5567
  margin: 0 space(2) space(1) 0

.c-summary-metric__label-value-group .c-summary-metric__value--secondary-strong
  min-height: auto

.c-summary-metric__rate
  color: #6E7986
  font-weight: 600

.c-summary-metric__values
  display: flex
  align-items: flex-end
  flex-wrap: wrap
  margin-bottom: space(1)

.c-summary-metric__value
  @include font-semibold(35px, $lh: 1, $color: dash-color(metric-color-primary))

.c-summary-metric__value--secondary
  @include font-light(24px, $lh: 1, $color: dash-color(metric-color-secondary))

.c-summary-metric__value--xs
  @include font-default(16px, $lh: 1, $color: dash-color(metric-color-primary))

.c-summary-metric__value--sm
  @include font-semibold(24px, $lh: 1, $color: dash-color(metric-color-primary))

.c-summary-metric__value--lg
  font-size: rem(48px)

.c-summary-metric__label-value-group .c-summary-metric__value
  margin-top: auto

.c-summary-metric__no-value
  display: flex
  align-items: center
  font-style: italic
  padding: space(2) 0
.c-summary-metric__no-value ~ .c-summary-metric__value
  color: transparent

.c-summary-metric__value,
.c-summary-metric__no-value
  margin: space(1) 0
  @include flex(0, 1, auto)

.c-summary-metric__group-wrapper
  display: flex
  flex-direction: row
  @include media-breakpoint-down(lg)
    @include flex(0, 1, 50%)
  @include media-breakpoint-up(lg)
    flex-direction: column
  @include media-breakpoint-down(sm)
    flex-direction: column
  // align-items: center

.c-summary-metric__group
  @include flex(1, 1, auto)
  display: flex
  flex-wrap: wrap
  justify-content: space-between

.c-summary-metric__group--column
  flex-direction: column
  justify-content: flex-start

.c-summary-metric__group-header
  padding: space(1) 0
  border-bottom: 1px solid dash-color(primary-l)
  @include flex(1, 1, 100%)
  margin-bottom: space(2)

.c-summary-metric__group-title
  @include dashboard-card-title(16px)
  margin-bottom: 0

.c-summary-metric__group-title,
.c-summary-metric__group-header p
  margin: 0
  color: $gray-600

.c-summary-metric__location
  font-size: rem(14px)
  font-weight: 600

.c-summary-metric__group-item
  min-width: 175px

.c-summary-metric__footer
  margin-top: space(2)
  border-top: 1px solid dash-color(primary-l)
  padding-top: space(2)
  p
    margin: 0
    color: $gray-600

.c-summary-metric__value-percentage-pair
  display: flex
  align-items: baseline
