@import '../helpers'

$value-range-height: 15px
$value-range-vertical-spacing: space(2)
$value-range-colors: (blue: #116393)
$value-range-active-color: #a0a0a0
$value-range-base-rail-color: #e2e2e2

.c-value-range
  // padding-left: space(2)
  // padding-right: space(2)
  $value-range-color: #116393
  &--blue
    .c-value-range__indicator
      background: lighten($value-range-color, 80%)
      border: 1px solid rgba($value-range-color, .9)
    .c-value-range__rail-active
      background: $value-range-color
    .c-value-range__indicator
      font-size: 18px

.c-value-range__values,
.c-value-range__labels
  display: flex
  justify-content: space-between
  margin-bottom: $value-range-vertical-spacing

.c-value-range__labels--centered
  justify-content: center

.c-value-range__label-min,
.c-value-range__label-max,
.c-value-range__label-mid
  font-size: 12px

.c-value-range__value-min,
.c-value-range__value-max
  font-size: 14px

.c-value-range__value-min,
.c-value-range__value-max
  font-weight: 600

.c-value-range__label-mid
  text-align: center
  padding: 0 space(3)

.c-value-range__indicator-wrapper
  height: $value-range-height
  position: relative
  margin-bottom: $value-range-vertical-spacing
  display: flex

.c-value-range__indicator
  display: inline-block
  position: absolute
  z-index: 4
  background: lighten($value-range-active-color, 80%)
  border: 1px solid $value-range-active-color
  padding: 3px space(2)
  transform: translate(-50%, -50%)
  font-weight: 600
  border-radius: $border-radius
  top: 50%

.c-value-range__rails
  position: relative
  display: flex
  flex: 1
  overflow: hidden
.c-value-range__rails::before,
.c-value-range__rail-active
  content: ''
  display: block
  z-index: 2
  background: $value-range-base-rail-color
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

.c-value-range__rail-active
  z-index: 3
  background: $value-range-active-color
