@import '../../helpers'

.c-story-action-call-btn
  min-height: 100px
  background: $brand-primary
  padding: space(4)
  margin-left: (-#{space(4)})
  margin-bottom: space(6)
  display: flex
  max-width: calc(#{$story-max-width} + #{space(4)})
  @include font-montserrat($size: 25px, $weight: 700, $lh: 1.1, $color: rgba($card-color, .95))
  text-decoration: none
  &:hover,
  &:focus
    text-decoration: none
    color: rgba($card-color, 1)
    background: $brand-secondary
    > span:last-child
      font-size: 40px

.c-story-action-call-btn > span:first-child
  margin-top: auto
  span:first-child
    display: block
    font-size: 30px
  span:last-child
    font-weight: 500

.c-story-action-call-btn > span:last-child
  margin-left: auto
  font-size: 30px
  transition: .05s font-size ease-in-out
