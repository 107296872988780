@import '../helpers'

$topic-vertical-spacing: space(6)

@mixin topic-content-padding
  padding-left: space(2)
  padding-right: space(2)
  @include media-breakpoint-up(md)
    padding-left: space(4)
    padding-right: space(4)

.o-topic
  padding-bottom: $topic-vertical-spacing
  background: get-dash-color(canvas-l)

.o-topic__header
  width: 100%
  margin-bottom: space(4)
  border-radius: $border-radius

.o-topic__content--padded
  @include topic-container

.o-topic__lead-content
  margin-top: - space(4)
  &:empty
    display: none
    margin: 0

.o-topic__lead-content p
  &:last-child
    margin-bottom: 0

.o-topic > .o-topic__content,
.o-topic__header
  @include topic-content-padding

.o-topic__section
  margin-bottom: space(4)

.o-topic__section--secondary
  padding: space(2)
  background: $card-color

.o-topic__section ~ .o-topic__note
  margin-top: (-#{space(4)})
  border-top: 3px solid $white

.o-topic__header
  display: flex
  align-items: flex-end
  flex-wrap: wrap

.o-topic__intro > h2
  font-size: rem(28px)
  line-height: line-height(32,28)

.o-topic__intro
  margin: 0 space(4) space(4) space(4)
  max-width: $max-paragraph-width
  p, ul, ol
    @include default-body-font
  li
    margin-bottom: space(1)

.o-topic__intro,
.c-topic-header
  max-height: 1000px
  transition: .15s max-height, .15s opacity, .15s margin
  opacity: 1
  &.hidden
    max-height: 0
    overflow: hidden
    opacity: 0
    margin-bottom: 0


.o-topic__cards
  margin-top: space(2)

.o-topic__cards--grouped
  margin-top: 0

.o-topic__stats
  max-width: $screen-lg
  margin-bottom: space(3)
  @include media-breakpoint-up(sm)
    margin-bottom: $topic-vertical-spacing

.o-topic__context
  margin-bottom: $topic-vertical-spacing

.o-topic__summary
  p, ul, ol
    @include default-body-font

.o-topic__nav-toggle
  font-size: 22px
  position: absolute
  right: space(2)
  top: 0
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none

.o-topic-detail-container
  padding: space(2)
  .o-topic__section
    background: none
  .c-dashboard-title
    display: none
