@import '../helpers'

.c-nav-wrapper
  display: flex
  flex-direction: column
  max-height: 100%
  .c-aside-nav
    flex: 1 1 auto
    overflow: auto
    @include media-breakpoint-up(md)
      overflow: visible
  .c-mini-map
    flex: 1 1 auto
