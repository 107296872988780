@import '../helpers'

.c-tabcontent
  padding: space(4)
  border: 2px solid
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px
  border-top-right-radius: 4px
  @each $name, $color in $level-colors
    $lighten-factor: 18%
    @if $name == high or $name == danger
      $lighten-factor:  45%
    @else if $name == normal
      $lighten-factor:  32%
    &--#{$name}
      background-color: lighten($color, $lighten-factor)
      border-color: $color
