@import '../../helpers'

.modal-title
  padding-top: rem(4px)
  @include h4
  @include media-breakpoint-up(md)
    @include h3

.modal-header
  padding-bottom: space(2)

.modal-body
  padding-top: space(2)
  p:last-child
    margin-bottom: 0

.modal-footer
  border-bottom-left-radius: $modal-content-border-radius
  border-bottom-right-radius: $modal-content-border-radius

.modal-footer button
  padding-left: space(6)
  padding-right: space(6)

.modal-header button[data-bs-dismiss]
  color: get-dash-color(link)
  opacity: 1
  @include hover-focus
    color: get-dash-color(link-hover)

.modal-body--restricted-height
  max-height: 80vh
  @include scrollbar
  overflow: auto
  padding: 0
