@import '../helpers'

.c-media
  display: flex
  flex-direction: column
  @include media-breakpoint-up(sm)
    flex-direction: row

.c-media__figure
  @include flex(1, 1, auto)
  margin-right: space(6)
  margin-bottom: space(4)
  @include media-breakpoint-up(sm)
    @include flex(0, 0, auto)
    margin-bottom: 0

.c-media__context
  @include flex(1, 1, auto)
  @include default-body-font

.c-media__title
  @include font-bold(rem(18px), $black, $lh: line-height(24, 18))

.c-media__description
  margin-bottom: space(4)
  max-width: $max-paragraph-width
  img
    max-width: 100%

.c-media__sub-title
  @include font-bold(rem(14px), $gray-800, $lh: line-height(20, 14))
  margin-bottom: space(3)

.c-media__list
  @include list-unstyled
  padding-left: space(2)

.c-media__list li
  margin-bottom: space(6)

.c-media__list h4
  @include font-bold(rem(14px), $gray-700, $lh: line-height(20, 14))
  margin-bottom: space(1)
