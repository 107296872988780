@use "sass:math";

@mixin input-label($size: 'sm') {
  font-size: rem(13px);
  line-height: math.div(15, 13);
}

@mixin dropdown {
  position: relative;
  display: block;
  width: 100%;
  min-width: auto;
  max-width: none;
  padding: space(2) space(6) space(2) space(2);
  border: 1px solid $brand-primary;
  border-radius: $border-radius-default;
  cursor: pointer;
  color: $brand-primary;
  cursor: pointer;
  text-align: left;
  background-color: $white;
  min-width: 150px;
  white-space: nowrap;
  @include bolden;
  &:focus,
  &:hover {
    background: $brand-primary;
    color: $white;
    outline: 0;
    box-shadow: none;
    &::after {
      color: $white;
    }
  }
  &::after {
    @include font-icon(rem(24));
    content: '\f107'; // angle-down
    position: absolute;
    right: space(2);
    top: 50%;
    transform: translateY(-50%);
    color: $brand-primary;
    pointer-events: none;
  }
}
