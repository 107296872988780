@use "sass:math"

@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-dashboard-card
  padding: 0
  display: flex
  @include flex(1, 1, 100%)
  margin-bottom: space(4)
  flex-direction: column
  width: 100%

.c-dashboard-card h1,
.c-dashboard-card .h1,
.c-dashboard-card h2,
.c-dashboard-card .h2,
.c-dashboard-card h3,
.c-dashboard-card .h3,
.c-dashboard-card h4,
.c-dashboard-card .h4
  @include font-open-sans($size: rem($h1-font-size-mobile), $color: get-dash-color(h1), $weight: font-weight(semibold))
  @include media-breakpoint-up(md)
    font-size: $h1-font-size

.c-dashboard-card h2,
.c-dashboard-card .h2,
.c-dashboard-card h3,
.c-dashboard-card .h3
  font-size: rem($lg-font-size-mobile)
  color: get-dash-color(h2)
  text-transform: uppercase
  margin-bottom: space(3)
  letter-spacing: .72px
  @include media-breakpoint-up(md)
    font-size: rem($lg-font-size)

.c-dashboard-card h1,
.c-dashboard-card .h1
  margin-bottom: space(2)

.c-dashboard-card h3,
.c-dashboard-card .h3
  font-weight: font-weight(normal)
  color: get-dash-color(h1)
  margin-bottom: space(2)

.c-dashboard-card h4,
.c-dashboard-card .h4
  font-size: 1.125rem
  font-weight: font-weight(normal)
  color: get-dash-color(h3)
  margin-bottom: space(2)
  text-transform: none
  @include media-breakpoint-up(md)
    font-size: 1.125rem

.c-dashboard-card__body
  padding: space(3)
  @include media-breakpoint-up(md)
    padding: space(4)

.c-dashboard-card__body p
  font-size: rem($md-font-size-mobile)
  line-height: $md-line-height-mobile
  color: $black
  @include media-breakpoint-up(md)
    font-size: rem($md-font-size)
    line-height: $md-line-height

.c-dashboard-card--4-up
  max-width: 100%
  @include flex(1, 1, 100%)
  @include media-breakpoint-up(md)
    max-width: percentage(1*0.5)
    flex: 1 1 percentage(1*0.5)
    padding-right: space(4)
  @include media-breakpoint-up(lg)
    min-width: 450px
    max-width: percentage(math.div(1, 3))
    flex: 1 1 percentage(math.div(1, 3))
    padding-right: space(4)

.c-dashboard-card--flex
  display: flex
  flex-direction: column

.c-dashboard-card__header
  min-height: 65px
  padding: space(3) space(4) space(2) space(4)
  border-bottom: 1px solid $border-color-secondary
  background-color: $card-header-bg-color
  display: flex
  flex-direction: column
.c-dashboard-card__header-flex-height
  min-height: auto

.c-dashboard-card__intro
  background: $white
  margin-top: - space(8)
  margin-left: - space(4)
  margin-right: - space(4)
  margin-bottom: space(1)
  padding: space(4) space(4) space(3) space(4)
  @include media-breakpoint-up(lg)
    background: none
    margin-top: 0
    margin-left: 0
    margin-right: 0
    padding-left: 0
    padding-top: 0

.c-dashboard-card__intro,
.c-dashboard-card__intro p
  font-weight: normal
  margin-top: - space(1)
  margin-bottom: space(3)
  font-size: 16px

.c-dashboard-card__header--outside
  background: $white
  padding: space(2) 0

.c-dashboard-card__header--with-context
  display: flex
  align-items: center

.c-dashboard-card__header h3
  margin-bottom: 0

.c-dashboard-card__header--informational
  background: $brand-info
  border-bottom: 09

.c-dashboard-card__title-link
  text-decoration: none
  display: flex
  align-items: center
  @include link-arrows($size: rem(16px))
  &:hover
    &,
    h4,
    span,
    &::after
      text-decoration: none
      color: $brand-primary

.c-dashboard-card__title
  margin-bottom: 0
  margin-right: space(2)

.c-dashboard-card__title--primary
  text-transform: uppercase

.c-dashboard-card__title--secondary
  font-weight: normal

.c-dashboard-card__location
  font-weight: bold
  text-transform: uppercase
  font-size: rem(16px)
  margin-bottom: space(4)

.c-dashboard-card__tooltip
  margin-left: auto
  padding-left: space(1)
  padding-right: space(1)
  margin-right: (-#{space(1)})
.c-dashboard-card__tooltip svg
  fill: $brand-primary

.c-dashboard-card__header-link
  margin-left: auto
  &:hover
    text-decoration: none

.c-dashboard-card__header-link span
  font-weight: 600

.c-dashboard-card__addendum
  @include addendum

.c-dashboard-card__addendum--right
  margin-left: auto

.c-dashboard-card__content
  justify-content: space-around
  flex-wrap: wrap
  width: 100%
  &:last-child
    @include flex(1, 1, 100%)
    max-width: 100%
  > div
    width: 100%
    @include flex(1,1, auto)

.c-dashboard-card__content,
.c-dashboard-card__row
  display: flex
  flex-wrap: wrap
  @include flex(1, 1, auto)

.c-dashboard-card__divider
  background-color: #EFE9E4
  height: 1px
  padding: 0 space(2)
  display: flex
  flex-wrap: wrap
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: 0 space(4)

.c-dashboard-card__content
  @include flex(1, 1, auto)

.c-dashboard-card__content--wrapper
  padding-top: space(2)
  padding-bottom: space(2)
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding-top: space(3)
    padding-bottom: space(3)
.c-dashboard-card__content--spaced
  justify-content: space-between

.c-dashboard-card__group
  margin-top: space(4)
  margin-bottom: space(4)
  min-width: 100px
  &:first-of-type .c-dashboard-card__group-item--padded
    padding-left: 0
  &:last-of-type .c-dashboard-card__group-item--padded
    padding-right: 0


.c-dashboard-card__group--auto
  @include flex(0, 0, auto)
  max-width: 100%

.c-dashboard-card__group--w-100
  @include flex(1, 1, auto)
  max-width: 100%
  display: flex

.c-dashboard-card__group--sized
  @include flex(1, 1, 100%)
  max-width: 100%
  @include media-breakpoint-up(sm)
    @include flex(1, 1, 50%)
    max-width: 50%
  @include media-breakpoint-up(lg)
    flex: 1 1 20%
    max-width: 20%

.c-dashboard-card__group--6
  $width: percentage(math.div(1, 6))
  @include media-breakpoint-up(md)
    flex: 1 1 percentage(math.div(1, 3))
    max-width: percentage(math.div(1, 3))
  @include media-breakpoint-up(lg)
    margin-bottom: 0
    flex: 1 1 $width
    max-width: $width

.c-dashboard-card__group--1
  @include flex(1, 1, 50%)
  max-width: 50%

.c-dashboard-card__group--2
  flex: 1 1 90px
  max-width: 40%
  margin-left: space(4)
  margin-right: space(4)
  display: flex
  flex-direction: column

.c-dashboard-card__group--3
  flex: 1 1 percentage(math.div(1, 3))
  max-width: percentage(math.div(1, 3))
  display: flex
  align-items: flex-start
  justify-content: center

.c-dashboard-card__group-compact
  display: flex
  align-items: flex-end

@each $index, $color in $chart-colors
  .c-dashboard-card__group-of-2-color--#{$index - 4}
    color: $color

@each $index, $color in $chart-colors
  .c-dashboard-card__group-of-3-color--#{$index - 1}
    color: $color

.c-dashboard-card__group--informational
  display: flex
  align-items: baseline
  @include media-breakpoint-up(xs)
    @include flex(1, 1, 50%)
    max-width: 50%
  @include media-breakpoint-up(sm)
    flex: 1 1 percentage(math.div(1, 3))
    max-width: percentage(math.div(1, 3))

.c-dashboard-card__group--sm-stacked
  flex-wrap: wrap
  > div:first-child
    @include flex(0, 0, auto)
  > div:last-child
    @include flex(1, 1, auto)
  @include media-breakpoint-up(md)
    flex-wrap: nowrap


.c-dashboard-card__group--informational .icon-svg
  margin-right: space(2)

.c-dashboard-card__group--informational label
  @include bolden
  margin: 0 0 0 space(1)

.c-dashboard-card__group--flexible
  @include media-breakpoint-up(xl)
    @include flex(1, 1, auto)
    max-width: none

.c-dashboard-card__group--disabled
  opacity: .25

.c-dashboard-card__group-title
  display: flex
  width: 100%
  justify-content: center
  padding-top: 10px
  @include font-light(15px, $gray-800, $lh: line-height(18, 15))
  margin-bottom: space(1)

.c-dashboard-card__group-title-context
  text-transform: none

.c-dashboard-card__footer
  margin-top: space(2)
  padding-top: space(2)
  border-top: 1px solid $border-color-secondary
  p,
  span
    margin-bottom: space(1)


.c-dashboard-card__footer-content
  padding: space(2) space(4)

.c-dashboard-card__footer--gradient
  border-top: 2px solid $white
  background: linear-gradient(135deg, rgba($card-color, 1) 0%, rgba(255,255,255,1) 100%)

.c-dashboard-card__footer--light
  background: $white
  border: 1px solid $border-color-secondary

.c-dashboard-card__footer--dark
  background: $card-header-bg-color

.c-dashboard-card__footer-link
  @include card-link

.c-dashboard-card__footer-info
  margin-top: space(1)
  margin-bottom: space(1)
  @include media-breakpoint-up(md)
    margin: 0
    padding-left: space(4)

.c-dashboard-card__group-item
  margin-bottom: space(4)
  &:last-child,
  &:only-child
    margin-bottom: 0

.c-dashboard-card__group-item--padded
  padding: 0 space(2)

.c-dashboard-card__value
  @include font-extrabold(38px, $lh: 1)

.c-dashboard-card__label
  font-size: 14px
  line-height: 18px

.c-dashboard-card__chart
  &,
  .c-chart-trend
    width: 100%
  .bb-axis .tick line,
  .bb-axis path
    stroke: dash-color(chart-stroke) !important
  .bb-grid line
    stroke: #C3C5CB
    stroke-width: 1.5px
  .bb-axis .tick text
    color: dash-color(text-color-d)
    font-size: 13px
    font-weight: 600

.c-dashboard-card--simple
  @include dashboard-card
  @include flex(0, 1, auto)

  padding: space(4)
  min-height: 200px
  margin-bottom: 0
  .c-dashboard-card__content
    @include flex(1, 1, auto)
    justify-content: flex-start
  .c-dashboard-card__header,
  .c-dashboard-card__content,
  .c-dashboard-card__footer
    background: none
    padding: 0
    border: 0
    justify-content: flex-end
    font-size: rem(13px)
    color: dash-color(text-color-d)
  .c-dashboard-card__header
    border: 0
    justify-content: flex-start
  .c-dashboard-card__footer
    padding-top: space(4)
    min-height: 25px
    flex: 1 1 100%
  .c-dashboard-card__title
    color: dash-color(text-color-d)
    margin-top: auto
  .c-dashboard-card__title-wrapper
    margin-left: - space(4)
    margin-right: - space(4)
    padding: space(2) space(4) 0 space(4)
    background: $white
    display: flex
    flex-direction: column
  .c-dashboard-card__title-wrapper > span
    font-size: rem(13px)
    text-transform: uppercase
  .c-dashboard-card__group
    text-align: center
  .c-dashboard-card__group-item
    padding: 0 space(2)
    @include flex(1, 1, auto)

.c-dashboard-card--stretch
  @include flex(1, 1, auto)

.c-dashboard-card--no-border
  border: 0

.c-dashboard-card__row--sized
  min-height: 200px
  display: flex
  align-items: center
  justify-content: center

.c-dashboard-card--map
  .c-dashboard-card__content
    padding: 0

.c-dashboard-card__content-wrapper
  display: flex
  flex-direction: column
  @include flex(1, 1, auto)
  padding-top: space(4)

.c-dashboard-card__content-container
  display: flex
  flex-direction: column

.c-dashboard-card__content-container--with-narrative
  flex-direction: column
  @include media-breakpoint-up(lg)
    flex-direction: row
    justify-content: space-evenly
    align-items: flex-start
    flex-wrap: wrap
    .c-card-tabs
      margin-left: 0
      margin-right: 0
      width: auto
    .c-dashboard-card__chart-container
      max-width: 55%

.c-dashboard-card__chart
  background: none
  min-height: auto
  margin: 0
  padding: 0
  padding-right: space(4)
  .c-chart__container
    padding: 0
  .bb-line
    stroke-width: 4px

.c-dashboard-card__link-block
  min-width: 175px
  $hover-color: dash-color(danger)
  flex: 1 1 auto
  display: flex
  flex-direction: column
  &,
  .c-summary-metric__value,
  .c-summary-metric__label
    text-decoration: none
  .c-summary-metric__no-value
    color: $body-color
  &:last-child
    border: 0
  &,
  .c-summary-metric__value
    transition: .125s ease-in-out
  .c-summary-metric__label
    color: dash-color(text-color-d)
  &:focus,
  &:hover
    text-decoration: none !important
    .c-summary-metric__value
      transform: translateY(-3px)
    .c-summary-metric__value
      color: $hover-color
    .c-summary-metric__no-value
      color: $body-color

.c-dashboard-card__updated
  font-size: rem(16px)
  color: $gray-600

.c-dashboard-card__big-dates
  color: dash-color(danger)
  font-size: rem(24px)
  font-weight: bold
  white-space: nowrap
  margin-right: space(2)

.c-dashboard-card__big-dates-disclaimer
  color: dash-color(text-color-d)
  font-style: italic

.c-dashboard-card__footer--info
  background: dash-color(info)

.c-dashboard-card__chart-chart-group-sibling
  display: none
  &.visible
    display: block
