@import '../helpers'

a.skip-main
    left: -999px
    position: absolute
    top: auto
    overflow: hidden
    z-index: -9999

a.skip-main:focus, a.skip-main:active
    color: #333
    background-color: #fff
    border-color: #ccc
    display: inline-block
    padding: 6px 12px
    margin-bottom: 0
    font-size: 14px
    @include bolden
    text-decoration: none
    line-height: 1.42857143
    text-align: center
    white-space: nowrap
    vertical-align: middle
    -ms-touch-action: manipulation
    touch-action: manipulation
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    background-image: none
    border: 1px solid transparent
    border-radius: 4px
    left: auto
    top: -7px
    overflow: auto
    margin: 10px 30%
    z-index: 9999
