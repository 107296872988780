@import '../../helpers'

.o-story-graphic
  display: flex
  // overflow: hidden
  margin-top: auto

.o-story-graphic img
  height: 40vh
  width: 100%
  @include flex(1, 1, auto)
  margin-bottom: -35px
  object-fit: contain
  font-family: 'object-fit: contain;'
