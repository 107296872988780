@import '../../helpers'

.o-dashboard-key-metrics
  .c-dashboard-card__link-block-col
    flex-direction: column
  .c-summary-metric__group-item
    margin: space(2) 0 0

.o-dashboard-key-metrics .c-summary-metric__group-item .c-summary-metric__label
  color: $gray-600
  font-size: rem(13px)
  font-style: italic
