@import '../helpers'

.c-video
  position: relative
  min-width: 100%
  background: $card-color
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 2px
  video
    @include flex(0, 0, 100%)
    max-width: 100%

.c-video__play
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  z-index: 5
  &:after
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba($black, .2)
  &:hover
    .c-video__play-icon
      color: rgba(lighten($brand-primary, 10%), .9)
    &:after
      background: rgba($black, .1)

.c-video__play-icon
  font-size: 150px
  color: rgba($brand-primary, .9)
  text-align: center
  z-index: 10

.c-video__load-error-message
  min-height: 400px
  display: flex
  align-items: center
  justify-content: center
  @include flex(1, 1, auto)

.c-video--dummy
  background: $card-color
  display: flex
  flex-direction: column
  @include flex(1, 1, auto)
  height: 100%
  align-items: stretch
  .c-video__wrapper
    @include flex(1, 1, auto)
    display: flex
    margin: space(4) space(4) 0 space(4)
    background: white
  .c-animation-controls
    pointer-events: none
    opacity: .4
    padding: space(2) space(4)
    @include flex(0, 0, auto)
  .o-map__title
    &::after
      content: ''
      height: 80%
      background: darken($card-color, 10%)
      width: 20%

.c-video-controls__rate-title
  margin-right: space(2)

.c-video-controls__rate
  &.active
    background: $brand-primary
    color: $white
