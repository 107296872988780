@import '../helpers'

.c-map
  .marker-cluster-small,
  .marker-cluster-medium,
  .marker-cluster-large,
  .marker-cluster-high
    background: rgba(get-dash-color(link), .4)
    div
      background: rgba(get-dash-color(link), .8)
      color: white
      font-weight: 600
      font-size: 16px

.c-map__title
  padding: space(4)

.c-map__container--constrained
  min-height: 500px
  height: auto
