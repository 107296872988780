@import '../helpers'

.c-status-icon
  &::before
    @include font-icon(rem(24))
    content: '\f00c'

.c-status-icon--true
  &::before
    content: '\f00c'
    color: $metric-positive

.c-status-icon--false
  &::before
    content: '\f00d'
    color: $gray-600
