@import '../helpers'

// .c-resources
.c-resources__item
	margin-bottom: space(6)
	&:last-of-type
		margin-bottom: 0

.c-resources__item a
	font-size: rem(20)
	line-height: 32px
	font-weight: font-weight(semibold)

.c-resources__item p
	font-size: rem(18)
	line-height: 32px
	margin-bottom: space(2)
	max-width: 100ch
	&:only-child
		margin-bottom: 0
