@import '../../helpers'

.c-story-chapter-intro
  height: 100vh
  position: relative
  z-index: 1000
  background: $brand-secondary
  overflow: hidden

.c-story-chapter-intro__text
  display: flex
  flex-direction: column
  position: absolute
  top: 20%
  left: 0
  right: 0
  transform: translateY(-50%)
  padding: space(4) space(8)
  background: rgba($brand-secondary, .5)
  z-index: 100
  @include media-breakpoint-up(md)
    top: 40%
    flex-direction: row
    text-align: left
    align-items: center

.c-story-chapter-intro__number,
.c-story-chapter-intro__title
  @include font-montserrat($size: 24px, $weight: 900, $lh: 1.1, $color: rgba($card-color, .9))
  @include media-breakpoint-up(md)
    font-size: 32px
    line-height: 42px
  @include media-breakpoint-up(lg)
    font-size: 48px
    line-height: 58px
    max-width: 30ch

.c-story-chapter-intro__number
  $story-chapter-title-number-size: 50px
  @include flex(0, 0, auto)
  height: $story-chapter-title-number-size
  width: $story-chapter-title-number-size
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  border: rgba($card-color, .9) 3px solid
  margin-right: space(4)
  margin-bottom: space(4)
  font-size: 20px
  @include media-breakpoint-up(md)
    height: $story-chapter-title-number-size * 1.5
    width: $story-chapter-title-number-size * 1.5
    font-size: 30px
  @include media-breakpoint-up(lg)
    margin-bottom: 0
    height: $story-chapter-title-number-size * 2
    width: $story-chapter-title-number-size * 2
    font-size: 40px

.c-story-chapter-intro__img
  height: 100%
  width: 100%
  object-fit: cover
  font-family: 'object-fit: cover;'
