@import '../helpers'

$primary: (main: $brand-primary-d, hover: $brand-primary)
$secondary: (main: $brand-secondary, hover: $brand-primary-d)
$tertiary: (main: $brand-primary-l, hover: darken($brand-primary-l, 10%))
$gray-light: (main: #e6e6e6, hover: darken(#e6e6e6, 10%))
$gray-dark: (main: #3f3d56, hover: lighten(#3f3d56, 10%))
$illustration-colors: (primary: $primary, secondary: $secondary, tertiary: $tertiary, gray-light: $gray-light, gray-dark: $gray-dark)

.c-illustration
  display: flex
  align-items: stretch
  justify-content: center
  flex: 1 1 auto
  min-width: 100%

.c-illustration--hover
  &:hover,
  &:focus
    @each $key, $colors in $illustration-colors
      .c-illustration__segment--#{$key}
        $color: map-get($colors, hover)
        fill: $color
        &[stroke-width]
          fill: none
          stroke: $color

@each $key, $colors in $illustration-colors
  .c-illustration__segment--#{$key}
    $color: map-get($colors, main)
    fill: $color
    &[stroke-width]
      fill: none
      stroke: $color
