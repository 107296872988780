#page-wrapper
  width: 750px
  margin-left: auto
  margin-right: auto

// ---------------------------------------------------
//  DEMO STYLE
// ---------------------------------------------------

.navbar-btn
  box-shadow: none
  outline: none !important
  border: none

// ---------------------------------------------------
//   SIDEBAR STYLE
// -----------------------------------------------------

.wrapper
  display: flex
  width: 100%

#sidebar
  width: 250px
  position: fixed
  top: 0
  left: 0
  height: 100vh
  z-index: 999
  border-right: 1px solid #B2AAA4
  transition: all 0.3s

#sidebar.active
  margin-left: -250px

#sidebar .sidebar-header
  padding: 20px

#sidebar ul.components
  padding: 20px 0

#sidebar ul p
  padding: 10px

#sidebar ul li a
  border-bottom: 1px solid #eee

#sidebar ul li a
  padding: 10px
  font-size: 1.1em
  display: block

#sidebar ul li a:hover
  background: #eee

#sidebar ul li.active>a,
a[aria-expanded="true"]
  font-weight: bold

a[data-bs-toggle="collapse"]
  position: relative

.dropdown-toggle::after
  display: block
  position: absolute
  top: 50%
  right: 20px
  transform: translateY(-50%)

// ul ul a
//   font-size: 0.9em !important
//   padding-left: 30px !important
//
// ul ul ul a
//   font-size: 0.9em !important
//   padding-left: 60px !important

ul.CTAs
  padding: 20px

ul.CTAs a
  text-align: center
  font-size: 0.9em !important
  display: block
  border-radius: 5px
  margin-bottom: 5px

a.download
  background: #fff
  color: #7386D5


a.article,
a.article:hover
  background: #6d7fcc !important
  color: #fff !important

// ---------------------------------------------------
//   CONTENT STYLE
// ---------------------------------------------------

#content
  width: calc(100% - 250px)
  padding: 40px
  // min-height: 100vh
  transition: all 0.3s
  // position: absolute
  // top: 0
  // right: 0

#content.active
  width: 100%

// ---------------------------------------------------
//   MEDIAQUERIES
// ---------------------------------------------------

@media (max-width: 768px)
  #sidebar
    margin-left: -250px

  #sidebar.active
    margin-left: 0

  #content
    width: 100%

  #content.active
    width: calc(100% - 250px)

  #sidebarCollapse span
    display: none
