@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-stoplight-header
  margin: - space(4)
  margin-bottom: space(2)
  padding: space(4)

$stoplight-colors: dash-color(stoplight)
@for $i from 1 through length($stoplight-colors)
  $color: nth($stoplight-colors, $i)
  .c-stoplight-header--#{$i}
    .c-stoplight-header__status-numeric
      color: determine-color-for-best-contrast($color)

.c-stoplight-header__title
  text-transform: uppercase
  color: #333D53
  font-size: 26px
  font-weight: 600
  letter-spacing: 0
  line-height: 26px
  margin-bottom: 0

.c-stoplight-header__note
  color: #333D53
  font-size: 13px
  font-style: italic
  letter-spacing: 0
  line-height: 14px

.c-stoplight-header__status-numeric
  font-size: 100px

.c-stoplight-header__status-text
  color: #333333
  font-size: 20px
  font-weight: 600
  letter-spacing: 0.4px
  line-height: 24px
  padding-left: 70px
  padding-top: space(2)
  margin-bottom: 0
