@import '../helpers'

.c-legend
  display: flex
  flex-direction: column
  margin-bottom: space(1)
  @include flex(1, 1, auto)
  max-width: 100%

.c-legend--with-ticks
  position: relative
  &::before,
  &::after
    content: ''
    position: absolute
    width: 1px
    background: rgba(black, .5)
    left: -1px
    top: 0
    bottom: 0
  &::after
    left: auto
    right: -1px

.c-legend--flex-0
  @include flex(0, 0, auto)

.c-legend--flex-0 .c-legend__labels
  padding-right: 5px

.c-legend__no-data
  height: rem(15px)
  background: $no-data-color
  max-width: 35%

.c-legend__gradient
  height: rem(15px)
  background: linear-gradient(to right, #ffffe9 0%,#e1965c 50%, #6b1e2a 100%)
  position: relative

.c-legend__items,
.c-legend__labels,
.c-legend__item
  display: flex
  align-items: center
  flex-wrap: wrap

.c-legend__labels
  justify-content: space-between
  margin-bottom: rem(2px)
  position: relative

.c-legend__label-value
  font-weight: font-weight(bold)

.c-legend__label-tick
  position: absolute
  top: 0
  display: flex
  height: 200%
  &:first-child
    display: none
  &::before
    content: ''
    display: block
    width: 1px
    height: 100%
    background: rgba(black, .5)
    flex: 0 0 auto
  span
    position: absolute
    bottom: 0
    left: 5px
    line-height: 1

.c-legend__item
  margin-right: space(6)
  margin-bottom: space(1)
  &:last-of-type
    margin-right: 0

.c-legend__icon
  margin-right: space(2)
  max-height: 48px

.c-legend__label
  @include font-semibold(rem(16px))
  &--top,
  &--btm
    padding-left: 3px
    padding-right: 3px
