@import '../helpers';

.daterangepicker  {
  @include media-breakpoint-down(sm) {
    right: 20px !important;
    left: 20px !important;
    width: calc(100% - 40px) !important;
  }
}
.daterangepicker .ranges {
  max-height: 50vh;
  overflow: auto;
  font-size: 1rem;
  @include media-breakpoint-up(sm) {
    min-width: 300px;
  }
  li {
    font-size: 16px;
  }
}

.daterangepicker .ranges ul {
  // Using the important flag here because it's not straightforward
  // why there's an explicit width above the sm breakpoint
  width: auto !important;
}
