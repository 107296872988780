@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-dashboard-pair
  display: flex
  align-items: center

.c-dashboard-pair--columnar
  flex-direction: column
  align-items: flex-start

.c-dashboard-pair--baseline
  align-items: baseline

.c-dashboard-pair span
  &:last-child
    margin-right: 0

.c-dashboard-pair__direction
  margin-right: space(2)
  display: flex
  align-items: center
  justify-content: center
  @include flex(0, 0, auto)
  margin-bottom: 0
  .icon-svg,
  svg
    height: 21px
    width: 21px

.c-dashboard-pair__direction--key
  fill: $metric-neutral
  margin-left: space(3)
  margin-right: space(1)

.c-dashboard-pair__direction--small
  margin-right: space(1)
  .icon-svg
    &,
    svg
      height: 15px
      width: 15px

.c-dashboard-pair__direction--positive svg
  fill: $metric-positive

.c-dashboard-pair__direction--negative svg
  fill: $metric-negative

.c-dashboard-pair__direction--neutral svg
  fill: $metric-neutral
