@import '../../helpers'


// In BS 5 headings default to no border top
// and dark border bottom
.table th
  border-top: 1px solid $border-color
  border-bottom-color: $border-color
.table > :not(:first-child)
  border-top-color: $border-color

.table-hover tbody tr
  &:hover
    td
      color: $black
    .action
      &::before
        visibility: visible
  .action
    text-align: right
    padding-right: space(2)
    &::before
      @include font-icon
      content: '\f061'
      visibility: hidden
      font-size: 22px
      line-height: 1
  .edit
    &::before
      content: '\f044'
      font-weight: normal


.table-hover.table-clickable tbody tr td
  cursor: pointer
  color: $link-color
.table-responsive
  @include scrollbar($width: 5px, $radius: 3px)
  margin-bottom: space(4)
  position: relative
  .table
    margin-bottom: 0

th.c-table__stoplight-heading
  font-size: 22px
  line-height: 26px
  text-align: center
  border: 0
  text-align: center
  color: $black
  span
    font-size: 14px
    font-style: italic
    display: block


td.c-table__stoplight-dashed
  border-top: 1px dashed #D8D8D8
  border-bottom: 0

td.c-table__stoplight-cell
  border-top: 1px solid $white
  border-bottom: 0
  text-align: center
  vertical-align: middle
  font-weight: bold
  color: $black
  border-left: 1px solid $white
  border-right: 1px solid $white

.table td.c-table__stoplight-cell,
.table td.c-table__stoplight-cell-label,
.table td.c-table__stoplight-cell-date
  padding-top: rem(3px)
  padding-bottom: rem(3px)

$stoplight-colors: dash-color(stoplight)
@for $i from 1 through length($stoplight-colors)
  $color: nth($stoplight-colors, $i)
  td.c-table__stoplight-cell--#{$i}
    background-color: $color
    color: determine-color-for-best-contrast($color)

td.c-table__stoplight-cell-label,
td.c-table__stoplight-cell-date
  font-size: 16px
  letter-spacing: 0
  line-height: 30px
  border-top: 1px dashed #D8D8D8
  color: $black


.c-table__stoplight-cell--md
  font-size: 16px
  font-weight: bold
  line-height: 20px


.c-table__stoplight-cell--primary
  font-size: 18px
  font-weight: bold

$stoplight-green: nth(map-get($he-colors, stoplight),1)
$stoplight-yellow: nth(map-get($he-colors, stoplight),2)
$stoplight-red: nth(map-get($he-colors, stoplight),3)
$stoplight-green-yellow: mix($stoplight-green, $stoplight-yellow, 50%)
$stoplight-red-yellow: mix($stoplight-yellow, $stoplight-red, 50%)

$detail-stoplight-colors: ( 1: $stoplight-green, 2: $stoplight-green-yellow, 3: $stoplight-yellow, 4: $stoplight-red-yellow, 5:$stoplight-red)

.c-table__stoplight-detail-heading,
.c-table__stoplight-detail-cell
  border-right: 1px solid $white

@each $index, $color in $detail-stoplight-colors
  $_color: desaturate($color, 5%)
  .c-table__stoplight-detail-heading--#{$index},
  .c-table__stoplight-detail-cell--#{$index}
    background: $_color
    color: determine-color-for-best-contrast($_color) !important
