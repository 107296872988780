@import '../../helpers'

.c-story-graphic
  @include flex(1, 1, auto)
  display: flex
  flex-wrap: wrap
  align-items: center
  margin-top: 50px
  @include media-breakpoint-up(lg)
    margin-top: 100px
    align-items: flex-start

.c-story-graphic__text
  @include flex(1, 1, 100%)
  margin-bottom: space(6)

.c-story-graphic__image
  @include flex(0, 1, 100%)
  margin-left: auto
  align-items: flex-end
  @include media-breakpoint-up(md)
    @include flex(0, 1, 50%)
