$stepper-size: 30px
.c-stepper
  position: relative
  &::before
    content: ''
    position: absolute
    left: 14px
    top: 15px
    height: calc(100% - 15px)
    width: 1px
    background-color: #CCC
    z-index: 1
.c-stepper__step
  display: flex
  flex-direction: column
  margin-bottom: 20px
  color: #666666
  font-size: 18px
  position: relative
  padding-left: $stepper-size + 15px
  &::before
    position: absolute
    left: 0
    top: 0
    content: attr(step_number)
    z-index: 3
    display: block
    height: $stepper-size
    width: $stepper-size
    border-radius: 50%
    background: #eaeaea
    margin-right: 10px
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid #CCC
    color: black
  &:last-of-type
    &::after
      content: ''
      display: block
      position: absolute
      width: $stepper-size
      top: 0
      bottom: 0
      left: 0
      background: white
      z-index: 2
  &.complete
    color: green
    &::before
      color: white
      background: green
      border-color: green
  &.active
    color: black
    font-weight: bold
    &::before
      border-color: orange
      background: orange
      animation-name: active
      animation-duration: 1.5s
      animation-iteration-count: infinite
  .c-stepper__step-description
    font-size: 15px
    line-height: 22px
    font-weight: normal
    margin-bottom: 0
    &:empty
      display: none

@keyframes active
  0%
    background-color: orange
  50%
    background-color: white
  100%
    background-color: orange
