@import '../helpers'

.c-info-warning
  font-size: rem(13px)
  line-height: line-height(15,13)
  background: $card-color
  border-top: 2px solid $brand-info
  border-bottom: 1px solid $border-color-l
  color: $gray-600
  padding: 0 space(4)
  margin-top: -2px
  display: flex
  align-items: stretch

.c-info-warning--full-bleed
  @include topic-full-bleed

.c-info-warning__icon
  padding: space(1) space(1) space(1) space(1)
  background: $brand-info
  font-size: rem(16px)
  margin-right: space(2)
  display: flex

.c-info-warning strong
  font-weight: 600

.c-info-warning__message
  padding: space(1) space(1) space(1) 0
