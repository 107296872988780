@import '../helpers'

.c-metric__context-title
  @include font-default(13px, $lh: line-height(14, 12))
  margin-bottom: space(1)
  text-transform: uppercase
  width: 100%

.c-metric__value
  @include data-metric($color: $black)
  margin-right: space(2)

.c-metric__value--comparison
  color: $gray-700
  white-space: nowrap

.c-metric__value--comparison span
  &:first-of-type
    @include data-metric(18px, $gray-700)
  &:last-of-type
    font-weight: 100

.c-metric__value--unit
  font-weight: 100
  font-size: rem(18px)
  color: $gray-700

.c-metric__viz-wrapper
  width: 100%
  display: flex
  align-items: center
