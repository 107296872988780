@import '../helpers'

.o-utility
  display: flex
  flex-wrap: wrap
  align-items: center
  width: 100%
  min-height: $heading-min-height
  justify-content: space-between
  padding-left: space(4)
  padding-right: space(4)

.o-utility .c-breadcrumbs,
.o-utility__actions
  flex: 0 1 auto

.o-utility__actions
  flex: 0 1 auto
  margin-top: space(2)
  margin-bottom: space(2)
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: block

.o-utility__actions .btn
  font-size: rem(14px)
  font-weight: 700
  padding-left: space(4)
  padding-right: space(4)
  margin-top: space(1)
  margin-bottom: space(1)

.o-utility__actions > a
  margin-right: space(3)
  &:last-of-type
    margin-right: 0
  &:hover
    text-decoration: none
