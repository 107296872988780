@import '../../helpers'
@import '../../tools/mixins/dashboard'

@mixin dash-card-default-text
  font-size: rem($md-font-size-mobile)
  line-height: $md-line-height-mobile
  color: $black
  @include media-breakpoint-up(md)
    font-size: rem($md-font-size)
    line-height: $md-line-height

.c-dash-card
  padding: 0
  flex: 1 0 100%
  margin-bottom: space(4)

.c-dash-card h1,
.c-dash-card .h1,
.c-dash-card h2,
.c-dash-card .h2,
.c-dash-card h3,
.c-dash-card .h3,
.c-dash-card h4,
.c-dash-card .h4
  @include dash-card-heading

.c-dash-card h1,
.c-dash-card .h1
  @include dash-card-heading($level: h1)

.c-dash-card h2,
.c-dash-card .h2,
.c-dash-card h3,
.c-dash-card .h3
  @include dash-card-heading($level: h2)

.c-dash-card h3,
.c-dash-card .h3
  @include dash-card-heading($level: h3)

.c-dash-card h4,
.c-dash-card .h4
  @include dash-card-heading($level: h4)

.c-dash-card h5,
.c-dash-card .h5
  @include dash-card-heading($level: h5)

.c-dash-card__title--external
  font-size: rem(25)
  line-height: 38px
  margin-bottom: space(4)

.c-dash-card__title--bold
  font-weight: font-weight(semibold)

.c-dash-card__body
  padding: space(3)
  display: flex
  flex-direction: column
  @include media-breakpoint-up(md)
    padding: space(4)

.c-dash-card__body--fill
  &,
  .c-dash-card__content
    flex: 1 1 100%
    display: flex

.c-dash-card__body p
  @include dash-card-default-text

.c-dash-card__content
  position: relative

.c-dash-card__content p
  strong
    font-weight: font-weight(semibold)

.c-dash-card__link
  @include dash-card-default-text
  color: get-dash-color(link)
  text-decoration: underline
  @include hover-focus
    color: get-dash-color(link-hover)

.c-dash-card__header
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  @include media-breakpoint-up(md)
    margin-bottom: space(6)

.c-dash-card__header--with-action
  .c-dash-card__header-action
    flex-direction: column
  @include media-breakpoint-up(md)
    .c-dash-card__header-text
      flex: 0 1 auto
    .c-dash-card__header-action
      flex: 0 1 auto
      max-width: 50%
      display: flex
      align-items: flex-start

.c-dash-card__header-text
  display: flex
  flex-direction: column
  margin-bottom: space(4)
  @include media-breakpoint-up(md)
    margin-bottom: 0

.c-dash-card__header-action
  flex: 1 1 100%
  margin-bottom: space(4)
  @include media-breakpoint-up(md)
    margin-bottom: 0

.c-dash-card__action-tabs .btn-group,
.c-dash-card__header-action .btn-group
  max-width: 100%
  display: flex
  flex-direction: column
  margin-bottom: space(4)
  > .btn
    flex: 0 1 auto
  .btn:not(:first-of-type),
  .btn:not(:last-of-type)
    border-radius: 0
  .btn:first-of-type
    border-top-left-radius: $border-radius
    border-top-right-radius: $border-radius
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
  .btn:last-of-type
    border-top-left-radius: 0
    border-top-right-radius: 0
    border-bottom-left-radius: $border-radius
    border-bottom-right-radius: $border-radius
  @include media-breakpoint-up(md)
    flex-direction: row
    .btn:first-of-type
      border-top-left-radius: 50px
      border-bottom-left-radius: 50px
    .btn:last-of-type
      border-top-right-radius: 50px
      border-bottom-right-radius: 50px

.c-dash-card__action-tabs .c-dash-btn,
.c-dash-card__header-action .c-dash-btn
  padding-top: space(1)
  padding-bottom: space(1)
  &.active
    pointer-events: none
    color: $white
    font-weight: font-weight(semibold)

.c-dash-card__header-action .btn-group--full-width
  .btn
    flex: 1 1 auto

.c-dash-card__notes
  display: flex
  align-items: baseline
  flex-wrap: wrap
  p
    font-style: italic

.c-dash-card__note
  max-width: 75ch

.c-dash-card__updated span,
.c-dash-card__note p
  display: block
  color: get-dash-color(gray-1)
  white-space: nowrap
  font-size: rem($sm-font-size)
  line-height: 18px

.c-dash-card__note p
  white-space: normal

.c-dash-card__updated
  display: flex
  padding-right: space(4)
  white-space: nowrap
  margin-bottom: space(1)
  span:first-child
    margin-right: space(1)
    font-weight: font-weight(semibold)


.c-dash-card ul,
.c-dash-card__list
  li
    @include dash-card-default-text
    &::marker,
    &::before
      color: get-dash-color(link)
  li:not([class*=inline])
    line-height: $sm-line-height + 3

.c-dash-card ul.ul__unstyled
  li
    line-height: 28px
    &::marker
      color: $body-color


.c-dash-card__footnote
  > a
    font-size: rem($sm-font-size)
    color:  get-dash-color(gray-1)
    text-decoration: underline
    @include hover-focus
      color: $black

.c-dash-card__rule
  margin-top: space(6)
  margin-bottom: space(6)

.c-dash-card__data-warning
  font-style: italic
  color: $gray-700
