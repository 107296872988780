@import '../helpers'
$nav-switch-breakpoint: sm
$nav-switch-breakpoint-up: sm
$page-nav-breakpoint: sm
$page-nav-border-width: 1px

// Logo
.mhc-logo__title
  transform: translate(0px, 6px)
  @include media-breakpoint-up($nav-switch-breakpoint-up)
    transform: translate(0, 0)
.mhc-logo__sub-title
  display: none
  @include media-breakpoint-up($nav-switch-breakpoint-up)
    display: block
    fill: black

.c-nav-bar.navbar
  margin: 0 auto
  align-items: stretch
  padding-top: 0
  padding-bottom: 0
  background: $white

.c-nav-bar__container
  flex: 1 1 100%
  display: flex
  justify-content: space-between
  align-items: stretch

.navbar-toggler
  white-space: nowrap
  margin-left: auto
  display: flex
  align-items: center
  flex-wrap: nowrap
  i
    font-size: rem(28)
  span
    display: none
  @include media-breakpoint-up(md)
    i
      margin-right: 3px
      font-size: rem(18)
    span
      font-size: rem(16)
      display: inline
  @include media-breakpoint-up(md2)
    display: none

.c-nav-bar__nav-wrapper
  display: flex

.c-nav-bar__heading
  display: flex
  align-items: center
  flex: 1 1 auto
  min-height: 50px
  @include media-breakpoint-up(md)
    flex: 0 1 auto

.c-nav-bar__nav
  @include list-unstyled
  margin: 0
  display: none
  justify-content: flex-end
  @include media-breakpoint-up(md2)
    display: flex
    justify-content: center
    align-items: stretch
    flex: 1 1 100%
    max-width: 100%

.c-nav-bar__dropdown-toggle.dropdown-toggle
  border-bottom: $page-nav-border-width solid transparent
  &:after
    display: none
  &[aria-expanded="true"]
    .c-nav-bar__dropdown-toggle
      transform: rotate(180deg)

.c-nav-bar__toggle-icon
  font-size: 16px
  transition: transform .15s ease-in-out

.c-nav-bar__nav-item
  transition: .15s opacity ease-in-out
  opacity: 1
  font-weight: 600
  &.hidden
    opacity: .3
  @include media-breakpoint-only(md2)
    position: relative
    &.hidden
      display: none
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: flex
    align-items: stretch

.c-nav-bar__nav-item .nav-link
  border-bottom: 4px solid transparent
  @include media-breakpoint-up($nav-switch-breakpoint-up)
    display: flex
    align-items: center
  color: $gray-700
  font-size: rem(14px)
  font-weight: font-weight(semibold)
  border-radius: 0
  padding: space(2)
  &:hover
    outline: 0
  &.active
    color: $red
    font-weight: font-weight(semibold)
    @include media-breakpoint-up($nav-switch-breakpoint-up)
      border-bottom-color: $red
    &:focus
      color: lighten($red, 10%)
      border-bottom-color: lighten($red, 10%)
      font-weight: 600
  &.inactive
    font-weight: normal
  &.inactive:hover,
  &.inactive:focus
    color: get-dash-color(h1)
    @include media-breakpoint-up($nav-switch-breakpoint-up)
      border-bottom-color: $gray-300
  @include media-breakpoint-up(lg)
    padding: space(2) space(6)

.c-nav-bar__nav-item--seperator
  border-right: 1px solid #DBDBDE
  margin-left: space(2)
  margin-right: space(2)

.c-nav-bar__btn-actions
  display: flex
  flex: 1 1 50%
  justify-content: flex-end
  align-items: center
  margin: 0
  .btn
    margin-left: space(2)

.c-nav-bar__nav-container
  display: flex
  align-items: stretch
  flex: 1 1 100%
  .nav-item.c-nav-bar__nav-item
    font-weight: font-weight(semibold)
    justify-content: center
    flex: 1 1 100%
    font-size: rem(16)
    line-height: 18px
    @include media-breakpoint-up($nav-switch-breakpoint-up)
      align-items: stretch
    &.title
      font-size: rem(18)
      line-height: 20px
      color: #166BB6
      align-items: center
      padding-right: space(4)
      white-space: nowrap
  .nav-link
    flex: 1 1 100%
    border-bottom: $page-nav-border-width solid transparent
    display: block
    padding-top: space(1)
    padding-bottom: space(1)
    @include media-breakpoint-up($nav-switch-breakpoint-up)
      display: flex
      text-align: center
      align-items: center
      justify-content: center
    &:hover,
    &:focus
      background: rgba(get-dash-color(link), .06)
      color: get-dash-color(link)
      text-decoration: underline
    &.active
      @include media-breakpoint-up($nav-switch-breakpoint-up)
        border-color: $brand-primary-d

.c-nav-bar__sm-only
  @include media-breakpoint-up($nav-switch-breakpoint-up)
    display: none

// In page modifier
// variant that is within the body of a dashboard page
// nav has negative margins to make it appear full bleed
// active nav items also have a blue underline treatment
.c-nav-bar__nav--in-page
  justify-content: flex-start
  border-bottom: $page-nav-border-width solid #e5e5e5
  padding: 0 space(4)
  background: white
  margin-top: - space(2)
  margin-bottom: space(4)

  .c-nav-bar__nav-item.nav-item
    margin-bottom: - $page-nav-border-width
    .nav-link
      text-decoration: none
      font-size: 16px
      line-height: 1
      padding: space(3) space(4)
      color: $brand-primary-d
      &:hover,
      &:focus
        border-bottom-color: lighten($brand-primary-d, 20%)
      &.active
        font-weight: 700
        border-bottom-color: $brand-primary-d
        pointer-events: none
        &:focus
          border-bottom-color: lighten($red, 10%)
          font-weight: 600
