@import '../helpers'

.c-info-window
  @include default-body-font

.c-info-window h1
  @include data-metric
  margin-bottom: 0
  padding-right: space(5)

.c-info-window h2
  @include h4
  color: $gray-600
  margin-bottom: 0
  margin-top: space(1)
  padding-right: space(5)

.c-info-window__item
  display: flex
  align-items: baseline
  margin-bottom: rem(3px)

.c-info-window__icon
  margin-right: space(2)

.c-info-window__list
  @include list-unstyled
  margin-top: space(3)
  margin-bottom: 0
  &:empty
    margin: 0
