@use "sass:math"
@import '../helpers'

$thermomter-min-width: 300px
$thermomter-height: 25px
$thermomter-indicator-height: $thermomter-height - 2px
$thermomter-border-color: #d8d8d8
$thermomter-indicator-color: get-dash-color(link)

.c-thermometer-viz
  display: flex
  flex-direction: column
  min-width: $thermomter-min-width

.c-thermometer-viz__wrapper
  position: relative
  min-height: $thermomter-height
  display: flex
  flex: 1 1 100%
  background: $white
  border-radius: 50px
  border: 1px solid $thermomter-border-color
  overflow: hidden

.c-thermometer-viz__indicator
  position: absolute
  height: $thermomter-indicator-height
  width: $thermomter-indicator-height
  border: 1px solid $white
  top: 50%
  transform: translate(-50%, -50%)
  z-index: 10
  border-radius: 50%
  background: $thermomter-indicator-color
  display: flex
  justify-content: center
  align-items: center
  &::after
    content: ''
    width: 1px
    height: 100%
    background: rgba($white, .4)

@each $varient-name, $colors in $color-ramps
  .c-thermometer-viz__indicator-wrapper--#{"" + $varient-name}
    @for $i from 1 through length($colors)
      $color: nth($colors, $i)
      .c-thermometer-viz__indicator--#{$i}
        .c-thermometer-viz__segment
          background: $color

.c-thermometer-viz__values
  display: flex
  justify-content: space-between
  flex: 1 1 100%

.c-thermometer-viz__min,
.c-thermometer-viz__max
  font-weight: bold

.c-thermometer-viz__segments
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  z-index: 5
  position: relative
  flex: 1 1 100%
  border-radius: 50px
  &.--no-color .c-thermometer-viz__tick
    background: $thermomter-border-color

.c-thermometer-viz__tick
  position: absolute
  width: 2px
  flex: 1 1 2px
  transform: translateX(-1px)
  background: $white
  height: 100%
  left: 0
  z-index: 3

.c-thermometer-viz__segment
  position: relative
  flex: 1 1 auto
  min-height: rem(25)
  border-left: 1px solid $black
  border-right: 1px solid $black
  &:first-of-type
    border-left: 0
    border-top-left-radius: 50px
    border-bottom-left-radius: 50px
  &:last-of-type
    border-right: 0
    border-top-right-radius: 50px
    border-bottom-right-radius: 50px
  $lookup: (1:4, 2:3, 3:2, 4:1)
  @each $inc in 1 2 3 4
    $percentage: math.div($inc, 100) * 25
    &:nth-of-type(#{map-get($lookup, $inc)})
      background: rgba(get-dash-color(highlight), $percentage)

.c-thermometer-viz__segments--reversed
  .c-thermometer-viz__segment
    @each $inc in 1 2 3 4
      $percentage: math.div($inc, 100) * 25
      &:nth-of-type($inc)
        background: rgba(get-dash-color(highlight), $percentage)

.c-thermometer-viz__segment-label
  position: absolute
  top: -100%
  left: 0

.c-thermometer-viz_progress
  position: absolute
  height: 100%
  left: 0
  background: lighten($thermomter-indicator-color, 50%)
  z-index: 1

.c-thermometer-viz_labels
  position: absolute
  width: 100%
  height: 100%
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 5px
  z-index: 2
.c-thermometer-viz_label
  font-weight: normal
  color: $gray-600
  font-size: 15px
