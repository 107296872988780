@import '../helpers'

.c-search-nav
  border-bottom: 2px solid $border-color-secondary
  @include media-breakpoint-up(lg)
    display: none

.c-search-nav__actions
  @include list-unstyled
  display: flex
  justify-content: space-between
  margin-bottom: 0

.c-search-nav__actions li
  @include flex(1, 1, auto)

.c-search-nav__actions li
  display: flex
  &:last-of-type
    justify-content: flex-end

.c-search-nav__action
  &.active
    font-weight: bold
    pointer-events: none
