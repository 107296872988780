@import '../../helpers'
@import '../../tools/mixins/dashboard'

$dashboard-alert-colors: dash-color(stoplight)
$dashboard-alert-names: success warning danger
$dashboard-alert-default-bg: dash-color(info)

.c-dashboard-alert
  @include dashboard-card
  display: flex
  padding: space(4)
  background: $dashboard-alert-default-bg
  flex-direction: column
  @include media-breakpoint-up(lg)
    flex-direction: row
    align-items: center

a.c-dashboard-alert
  &:focus,
  &:hover
    text-decoration: none
    background: lighten($dashboard-alert-default-bg, 1%)
    .c-dashboard-alert__action
      color: darken(dash-color(link-color-primary), 10%)
      background: rgba($white, 1)
@for $i from 1 through length($dashboard-alert-colors)
  $color: nth($dashboard-alert-colors, $i)
  .c-dashboard-alert--#{$i},
  .c-dashboard-alert--#{nth($dashboard-alert-names, $i)}
    background-color: $color
    .c-dashboard-alert__header,
    .c-dashboard-alert__content,
    .c-dashboard-alert__footnote > span
      color: determine-color-for-best-contrast($color) !important
    @if ($i == 3)
      .c-dashboard-alert__action
        $action-bg: determine-color-for-best-contrast($color)
        background: rgba($action-bg, .75)
        color: determine-color-for-best-contrast($action-bg) !important
  a.c-dashboard-alert--#{$i},
  a.c-dashboard-alert--#{nth($dashboard-alert-names, $i)}
    &:focus,
    &:hover
      background: lighten($color, 5%)

.c-dashboard-alert__header
  color: $black
  @include dashboard-card-title
.c-dashboard-alert__content
  color: $black
  font-size: rem(16px)
  &,
  p
    margin: 0
  @include media-breakpoint-up(lg)
    margin-right: space(2)

.c-dashboard-alert__action
  font-weight: bold
  background: rgba($white, .75)
  border-radius: 4px
  padding: space(2) space(4)
  margin-top: space(2)
  width: 100%
  white-space: nowrap
  @include media-breakpoint-up(lg)
    width: auto
    margin-left: auto

.c-dashboard-alert__footnote > span
  display: block
  font-style: italic
  font-size: rem(14px)
  color: rgba($black, .65)
  margin-top: space(1)
  margin-bottom: 0
