@import '../tools'

.o-section
  margin-bottom: space(8)

.o-section--constrained
  @include constrained-container

.o-section__header
  display: flex
  margin-bottom: space(7)

.o-section__header--block
  display: block
