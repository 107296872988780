@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-timeline-descriptions
  flex: 1 1 auto
  position: relative
  min-height: 500px
  &::before,
  &::after
    content: ''
    position: absolute
    width: space(4)
    left: - space(3)
    top: 0
    bottom: 10px // Account for scrollbar
    background: rgb(255,255,255)
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
    z-index: 10
    pointer-events: none
    @include media-breakpoint-up(md)
      left: 0
  &::after
    right: - space(3)
    left: auto
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
    @include media-breakpoint-up(md)
      right: 0

.c-timeline-descriptions__wrapper
  position: relative
  overflow-x: hidden
  max-width: 100%
  @include media-breakpoint-down(md)
    margin-top: space(4)

.c-timeline-descriptions__container
  position: absolute
  width: 100%
  left: 0
  top: 0
  @include media-breakpoint-up(md)
    padding-left: space(4)
    padding-right: space(4)

.c-timeline-descriptions__description
  flex: 1 1 auto

.c-timeline-descriptions__actions
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  a
    text-transform: uppercase
    &.next
      margin-left: auto
