@import '../helpers'

$use-dark-theme: true

.c-nav-dropdown
  display: flex
  width: 100%
  padding: 0
  &:focus,
  &:hover
    .c-nav-dropdown__dropdown
      &,
      &.show
        display: flex

.c-nav-dropdown__trigger
  min-height: rem(50px)

.c-nav-dropdown__dropdown
  &.show
    display: none
  border-radius: 0
  border-top: $page-navbar-border-color 1px solid
  margin-top: 0
  width: 100%
  padding: space(4)
  @if $use-dark-theme
    background: rgba(dash-color(primary-d), 1)
  @else
    background: rgba(dash-color(canvas-bg), 1)
  padding: space(4) 0

.c-nav-dropdown__wrapper
  display: flex
  justify-content: space-between

.c-nav-dropdown__section
  padding-left: space(2)
  padding-right: space(2)

$nav-dropdown-link-size: rem(18px)
$nav-dropdown-link-lh: 24px
$nav-dropdown-link-color: dash-color(link-color-primary)
.c-nav-dropdown__section-link,
.c-nav-dropdown__section-title
  @include font-semibold($size: $nav-dropdown-link-size, $lh: $nav-dropdown-link-lh)
  &,
  &:hover,
  &:focus
    @if $use-dark-theme
      color: $white
    @else
      color: #1a263b

  @include media-breakpoint-up(sm)
    &::after
      content: '\f107'
      @include font-icon
      margin-left: space(2)
      font-size: rem(13px)
    &.disabled
      &::after
        content: ''

.c-nav-dropdown__list
  background: none

.c-nav-dropdown__list-item
  background: none

.c-nav-dropdown__link
  @include font-semibold($size: $nav-dropdown-link-size, $lh: $nav-dropdown-link-lh)
  @if $use-dark-theme
    color: $page-navbar-active-text-color
  @else
    color: $nav-dropdown-link-color
    font-weight: bold
  &:focus,
  &:hover,
  &.active
    @if $use-dark-theme
      color: $page-navbar-color
    @else
      color: darken($nav-dropdown-link-color, 5%)

  @include media-breakpoint-between(md, lg)
    @include font-semibold($size: 14px, $lh: 18px)
