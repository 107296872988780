.card-table
  margin-bottom: 0

.card-table thead th
  border: none
  padding-top: 3px
  padding-bottom: 3px
  font-weight: bold

.card-table th:first-child, .card-table td:first-child
  padding-left: 1.25em

.card-table th:last-child, .card-table td:last-child
  padding-right: 1.25em

.card-table body tr:last-child td
  border: none
