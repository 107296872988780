@import '../../helpers'

.c-inner-card
  min-height: 100px
  background: #f9f9f9
  margin-bottom: 10px
  padding: 30px 20px
  position: relative
  display: flex
  align-items: center
  border-radius: 4px
  @each $ramp-name, $colors in $color-ramps
    $bg-color: rgba(nth($colors, 2), .1)
    $text-color: nth($colors, 1)
    &--#{"" + $ramp-name}
      background: $bg-color
      transition: background .15s ease-in-out
      &:focus,
      &:hover
        background: darken($bg-color, 5%)
      .c-inner-card__title
        color: $text-color

.c-inner-card--link
  padding-right: 50px
  flex: 1 1 100%
  &,
  *
    text-decoration: none !important
  &::after
    @include font-icon
    font-weight: 800
    content: '\f103'
    position: absolute
    right: 20px
    top: 50%
    transform: translateY(-50%)
    font-size: 24px
    color: rgba(black, .2)
    line-height: 1

.c-inner-card--ext-link
  &::after
    content: '\f101'

.c-inner-card--with-viz
  padding: 15px
  min-height: 150px
  .c-inner-card__title
    font-weight: 600
    margin-bottom: space(2)
  &,
  .c-inner-card__content
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: space-between

.c-inner-card__title
  font-weight: 600
  line-height: 18px
  margin-bottom: space(1)

.c-inner-card__content
  padding: 0
  flex: 1 1 100%

.c-inner-card--columnar
  display: flex
  flex-direction: column

.c-inner-card-link__viz
  min-height: 50px
  margin-top: auto
  width: 100%
  flex: 1 1 100%
