@import '../helpers'

.o-search-list
  display: flex
  flex-direction: column
  border-right: 1px solid #CCCCCC
  overflow: auto
  position: static

.o-search-list__form
  width: 100%

.o-search-list__search
  @include flex(0, 1, auto)
  border-bottom: 1px solid $border-color-primary

.o-search-list__results
  @include flex(1, 1, auto)
  overflow: auto
  display: flex
  flex-direction: column

.o-search-list__results-summary
  background: #f9f9f9
  @include flex(0, 1, auto)
  padding: space(2) space(4)
  margin-bottom: 0

.o-search-list__results-list
  @include flex(1, 1, auto)
  overflow: auto
  margin-bottom: 0

.o-search-list__pagination
  @include flex(0, 1, auto)
  padding: space(4)
  border-top: 1px solid $border-color-primary
  &:empty
    display: none

.o-search-list__pagination .pagination
  justify-content: center
  margin-bottom: 0
