@import '../helpers'

.c-animation-controls
  display: flex
  flex-direction: column
  padding: space(2)

.c-animation-controls--padded
  padding: space(4)
  background: $card-color

.c-animation-controls__primary,
.c-animation-controls__secondary
  display: flex
  align-items: center

.c-animation-controls__secondary
  padding: space(2)

.c-animation-controls__playback
  display: flex
  flex-direction: column
  align-items: center
  @include flex(0, 0, auto)
  margin-left: space(2)
  margin-right: space(4)

// .c-animation-controls__playback-buttons

.c-animation-controls__playback-button
  margin-right: space(2)
  font-size: 32px
  color: $brand-primary
  cursor: pointer
  &:last-of-type
    margin: 0
  &:hover
    color: $brand-secondary
  &:active
    color: darken($brand-secondary, 5%)
  &.disabled
    color: $gray-500
    pointer-events: none
  &.active.disabled
    color: $brand-secondary

.c-animation-controls__time
  @include font-default(rem(13px), $gray-700)

.c-animation-controls__slider
  display: flex
  @include flex(1, 1, auto)
  flex-direction: column
  width: 100%
  margin-right: space(2)

$date-controls-bg: desaturate(darken($gray-100, 5%), 100%)
.c-animation-controls__rate,
.c-animation-controls__date
  @include flex(1, 1, auto)
  min-height: 40px
  color: $gray-600
  display: flex
  align-items: center
  justify-content: center
  background: $date-controls-bg
  border-right: 1px solid $white
  border-left: 1px solid $white
  transition: .25s ease-in-out
  text-decoration: none
  span
    display: none
    @include media-breakpoint-up(md)
      display: block
      font-size: 75%
    @include media-breakpoint-up(lg)
      font-size: 100%
  &:first-of-type
    border-left: 0
    border-top-left-radius: $range-slider-border-radius
    border-bottom-left-radius: $range-slider-border-radius
  &:last-of-type
    border-right: 0
    border-top-right-radius: $range-slider-border-radius
    border-bottom-right-radius: $range-slider-border-radius
  &.active
    font-weight: bold
    background: $brand-primary
    color: $white
    letter-spacing: .05em
    padding-bottom: 5px
  &:hover
    text-decoration: none
    background: darken($date-controls-bg, 5%)
    color: $black
  &.active:hover
    background: $brand-primary
    color: $white

.c-animation-controls__rate
  min-height: 25px
  padding: 0 space(2)
  &.active
    padding-bottom: 0
