@import '../../helpers'

.o-story-section
  background: rgba($white, .99)
  display: flex
  flex-direction: column
  position: relative

.o-story-section--viewport-height
  min-height: calc(100vh)

.o-story-container--clipped-bg
  position: absolute
  top: 0
  bottom: 0
  left: $story-content-padding
  right: 0
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%)
  background: transparent //$border-color-secondary
  z-index: 0

.o-story-section__chart
  &:last-child
    margin-bottom: space(8)
