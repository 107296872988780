@import '../helpers'

.c-label
  font-weight: 600

.c-label--capped
  text-transform: capitalize

.c-label--with-remove
  display: flex

.c-label__remove
  margin-left: auto
  font-weight: normal

