@import '../helpers'

@mixin sticky-below-header
  position: sticky
  top: $header-height
  z-index: stack-order(top) + 2

.l-main__wrapper
  position: relative
  min-height: 100vh
  display: flex
  flex-direction: column
  @include flex(1, 1, auto)

.l-main__wrapper--no-overflow
  overflow: hidden

.l-main
  display: flex
  flex-direction: column
  @include flex(1, 1, auto)

.l-main__content-wrapper
  position: relative
  display: flex
  @include flex(1, 1, 100%)
  max-width: 100%
  z-index: stack-order(top)

@include media-breakpoint-down($nav-switch-breakpoint)
  .l-main__content-wrapper.list .l-main__content
    display: none

  .l-main__content-wrapper.list .l-main__nav
    flex: 0 0 100%
    max-width: 100%
    position: static
    opacity: 1

  .l-main__content-wrapper.map .l-main__content
    flex: 0 0 100%
    max-width: 100%

  .l-main__content-wrapper.map .l-main__nav
    display: none

.l-main__nav
  // $duration: $transition-duration, $prop: false, $timing-fn: ease-in-out
  @include transition(.25s, $prop: right)
  position: fixed
  top: 0
  right: -100vw
  display: flex
  flex-direction: column
  z-index: stack-order(modal)
  flex: 0 0 100vw
  width: 100vw
  padding-left: 0
  padding-right: 0
  height: 100vh
  background: $white
  opacity: 0
  &.on-canvas
    opacity: 1
    position: fixed
    right: 0
    top: 0
    opacity: 1
  @include media-breakpoint-up($nav-switch-breakpoint)
    border-right: 1px solid rgba($black, .15)
    box-shadow: 0px 2px 1px -1px rgba($black, 0.2), 0px 1px 1px rgba($black, 0.14), 0px 1px 3px rgba($black, 0.12)
    &,
    &.on-canvas
      @include sticky-below-header
      flex: 1 1 $side-nav-width
      max-width: $side-nav-width
      min-width: $side-nav-width
      opacity: 1
      z-index: stack-order(page)
      padding-bottom: 0
    &.closed
      transition: transform .15s ease-in-out
      transform: none
      transform: translateX(- $side-nav-width)

.l-main__nav--dictionary
  border-right: 0
  min-width: 300px
  display: flex
  flex: 1 1 auto
  align-items: stretch

.l-main__nav--dark
  background: dash-color(primary)
  border-right: 0
  z-index: stack-order(top)
  > nav
    overflow: auto
    @include media-breakpoint-up(md)
      overflow: visible

.l-main__nav--mobile-only
  @include media-breakpoint-up(md2)
    display: none

.l-main__content
  background: get-dash-color(canvas-l)
  flex: 0 0 100%
  max-width: 100%
  @include media-breakpoint-up($nav-switch-breakpoint)
    flex: 0 0 calc(100vw - #{$side-nav-width})
    max-width: calc(100vw - #{$side-nav-width})
    transition: .15s max-width ease-in-out, .15s transform ease-in-out
    &.full-bleed
      max-width: 100vw
      min-width: 100vw
      transform: translateX(- $side-nav-width)

.l-main__content-nav
  @include sticky-below-header

.l-main__content--full-width
  @include media-breakpoint-up($nav-switch-breakpoint)
    flex: 0 0 100%
    max-width: 100%

.l-main__content--padded
  padding: $topic-padding-mobile
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: $topic-padding-desktop
    padding-bottom: rem(100)

.l-main__content--flex
  display: flex
  flex-direction: column
