@import '../helpers'

.c-chart-trend > svg
  overflow: visible !important

.c-chart-trend .c-tooltip-chart__value--2
  color: $metric-comparison !important

.c-chart-trend .bb-tooltip-container
  // Override inline styles added by library
  // Without this change, the tooltip appears over the chart, obstructing the
  // view of the trend lines
  bottom: 100% !important
  top: auto !important
