@import '../helpers'

.c-mini-map--wrapper
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px
  padding: 20px
  padding-bottom: 10px
  padding-top: 0px
  margin-bottom: 16px

.c-mini-map
  display: none
  @include media-breakpoint-up($nav-switch-breakpoint-up)
    display: block

.c-mini-map--lg
  height: 20vh
  min-height: auto
  border: 0
  @include media-breakpoint-up(md)
    min-height: 325px

.c-mini-map .leaflet-bottom.leaflet-right
  bottom: 0
  right: 0

.c-mini-map__button_container
  position: absolute
  display: flex
  width: 100%
  height: 100%
  background-color: rgba(33, 33, 33, 0.6)
  z-index: 1001
  transition: 0.3s
  & .btn-link
    position: relative
    margin: auto
    border-color: white
    color: white
    display: inline-flex
    -webkit-box-align: center
    align-items: center
    -webkit-box-pack: center
    justify-content: center
    box-sizing: border-box
    background-color: transparent
    outline-width: 0px
    cursor: pointer
    -webkit-user-select: none
    vertical-align: middle
    appearance: none
    text-decoration-line: none
    font-family: "Open Sans", sans-serif
    font-weight: 600
    font-size: 0.875rem
    line-height: 1.75
    min-width: 64px
    padding-top: 5px
    padding-bottom: 5px
    padding-right: 15px
    padding-left: 15px
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    border-bottom-left-radius: 4px
    transition-duration: 250ms, 250ms, 250ms, 250ms
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)
    transition-delay: 0ms, 0ms, 0ms, 0ms
    transition-property: background-color, box-shadow, border-color, color
    border-top-width: 1px
    border-right-width: 1px
    border-bottom-width: 1px
    border-left-width: 1px
    border-top-style: solid
    border-right-style: solid
    border-bottom-style: solid
    border-left-style: solid
    box-shadow: none
    text-transform: none
    position: relative
    margin-left: auto
    margin-right: auto
    margin-top: auto
    margin-bottom: auto
    border-top-color: white
    color: white

    &:hover,
    &:focus
      background-color: rgba(33, 33, 33, 0.25)
      border-color: white
    &:focus
      background-color: white
      border-color: white
      color: black
      outline: 3px solid white
      outline-offset: 2px

.c-mini-map-base-button
  text-decoration: underline
  display: flex
  width: 100%
  padding: 8px 12px
  justify-content: center
  align-items: center
  gap: 10px
  align-self: stretch
  border-radius: 4px
  text-decoration: none
  margin-top: 13px !important
  margin-bottom: 13px !important
  &:focus
    outline: 3px solid $brand-primary
  & p
    margin: 0
    font-size: 14px !important
    line-height: 22px !important
    font-family: Open Sans,sans-serif
    text-align: center
    font-feature-settings: 'clig' off,'liga' off
    font-style: normal !important
    font-weight: 600 !important

.c-mini-map-cpr-button-state
  text-decoration-color: rgba(2, 96, 240, 0.4)
  background: #F0F3F8
  color: #245194
  &:hover
    background: #E0E5EE
    color: #16407E

.c-mini-map-cpr-button-cpr-location
  text-decoration-color: rgba(2, 96, 240, 0.4)
  color: #FFFFFF
  background: #0060F0
  &:hover
    color: #FFFFFF
    background: #245194

.c-mini-map__map
  border-top-left-radius: 4px
  border-top-right-radius: 4px
  border-bottom-right-radius: 4px
  border-bottom-left-radius: 4px
  overflow-x: hidden
  overflow-y: hidden
  position: relative
  &:focus-within,
  &:hover
    .c-mini-map__button_container
      visibility: visible
      opacity: 1
  & .c-mini-map__button_container
    visibility: hidden
    opacity: 0


.c-mini-map__map
  .map-wrapper,
  .map-container
    min-height: 125px

.c-mini-map__header_caption
  margin: 0
  margin-bottom: 0px
  font-weight: 400
  font-family: Open Sans,sans-serif
  color: rgb(0,0,0,0.54)
  font-weight: 400
  font-size: 12px !important
  line-height: 18px
  margin-bottom: 0px

.c-mini-map_header_container
  padding-bottom: 7px
  padding-top: 3px

.c-mini-map__header
  font-weight: 600
  line-height: 25px
  font-size: 18px !important
  margin: 0
  font-family: Open Sans,sans-serif
  color: rgba(0, 0, 0, 0.87)

.c-mini-map__metadata
  padding: 0px
  margin: 0px
  display: -webkit-box
  display: -webkit-flex
  display: -ms-flexbox
  display: flex
  -webkit-flex-direction: row
  -ms-flex-direction: row
  flex-direction: row
  &
    span
      margin-top: 0px
      font-family: "Open Sans", sans-serif
      line-height: 18px
      font-size: 12px
      font-weight: 400
      margin-right: auto
    span:first-child
      font-weight: 600
      margin-right: 5px
    a
      margin-left: auto
      margin-top: 0px
      font-weight: 400
      font-family: "Open Sans", sans-serif
      color: rgba(0, 0, 0, 0.87)
      line-height: 18px
      font-size: 12px
      text-decoration-line: underline
      text-decoration-color: rgba(0, 0, 0, 0.4)

.c-mini-map__metadata
  font-size: 14px
  line-height: 18px
  padding-top: 0px
  margin-top: 0px
  display: flex
  flex-direction: row
