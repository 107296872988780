@import '../helpers'

$context-switcher-z-index: stack-order(cosmos)
$context-switcher-margin: 20px
$context-switcher-breakpoint: sm
$content-switcher-primary-color: $brand-primary
$content-switcher-secondary-color: $brand-primary-d

// .c-context-switcher
.c-context-switcher__wrapper
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0
  transition: .35s transform ease-in-out, .35s opacity ease-in-out
  height: 100vh
  height: -webkit-fill-available
  max-height: -webkit-fill-available
  width: 100vw
  z-index: $context-switcher-z-index
  pointer-events: none
  transform: translateY(-105vh)
  &::before
    opacity: 0
    content: ''
    background: rgba(0,0,0,.4)
    z-index: $context-switcher-z-index - 1
    pointer-events: none
    transition: .35s opacity ease-in-out
    transition-delay: .35s
    top: 0
    left: 0
    bottom: 0
    right: 0
  &.active
    transform: translateY(0)
    opacity: 1
    pointer-events: all
    &::before
      position: fixed
      opacity: 1
      display: block

.c-context-switcher__wrapper--inline
  max-height: none
  opacity: 1
  &,
  .c-context-switcher__content
    position: static
    width: auto
    height: auto
    transform: none
    pointer-events: all
    max-width: none
    margin: 0
    z-index: 1
  &,
  .c-context-switcher__content,
  .c-context-switcher__areas
    box-shadow: none
    height: 500px
    @include media-breakpoint-down(sm)
      height: auto
      max-height: 50vh
      min-height: 500px
  .c-context-switcher__areas
    padding: 0
  &::before
    display: none
  .c-context-switcher__area-container
    margin-top: 0
    border: none
  &.loading
    opacity: .5
    *
      pointer-events: none

.c-context-switcher__content
  position: fixed
  height: 100vh
  height: -webkit-fill-available
  max-height: -webkit-fill-available
  width: 100vw
  margin-top: space(4)
  max-width: 1400px
  display: flex
  flex-direction: column
  flex: 1 1 auto
  z-index: $context-switcher-z-index
  background: rgba(white, .99)
  overflow: hidden
  box-shadow: 0 5px 20px 20px rgba(0,0,0,.2)
  border-top-left-radius: $border-radius * 3
  border-top-right-radius: $border-radius * 3
  .c-search__input-wrapper
    border: 1px solid $gray-400
    border-top: 0
  @include media-breakpoint-up($context-switcher-breakpoint)
    max-height: 1000px
    height: calc(100vh - #{$context-switcher-margin*4})
    margin: $context-switcher-margin*2
    border-radius: $border-radius
  // Change classname to be scoped to context switcher
  .c-search__input-wrapper
    margin-bottom: space(2)
    position: relative
    border-radius: 100px
    border: 0
    &::after
      @include font-icon
      content: '\f002'
      position: absolute
      pointer-events: none
      right: space(4)
      top: 50%
      transform: translateY(-50%)
      color: $gray-400
      font-size: 20px
  .c-loading-animation__indicator svg
    margin-bottom: 0
  .c-search__input
    border-radius: 100px
    border-color: $brand-primary
    border-width: 2px
    font-size: 14px
    background: $white
    @include media-breakpoint-up($context-switcher-breakpoint)
      font-size: 16px
    // Move clear icon in IE 11 inputs because it overlaps search icon
    &::-ms-clear
      margin-right: 25px
    &::after
      @include font-icon
      content: 'search'
      position: absolute
      right: 5px
      top: 50%
      transform: translateX(-50%) $context-switcher-margin $context-switcher-margin*0.5 $context-switcher-margin
    &:focus
      border-color: $brand-secondary
      outline: 3px solid $brand-primary
      outline-offset: 1px
      box-shadow: none
      background: #f9f9f9
    &.searching
      &::after
        transform-origin: center
        content: '/f110'
        animation-name: rotate
        animation-iteration-count: infinite
        animation-duration: 1s
    &.with-results
      border-top-left-radius: 25px
      border-top-right-radius: 25px
  .c-search__results
    padding-top: space(1)
    padding-bottom: space(1)
    margin: - space(3) 0 space(4) 0
    border: 1px solid $brand-secondary
    border-top: 0
    border-bottom-left-radius: $border-radius
    border-bottom-right-radius: $border-radius

  > header
    @include flex(0,0, auto)
    display: flex
    justify-content: space-between
    align-items: center
    padding: space(2)
    @include media-breakpoint-up($context-switcher-breakpoint)
      padding: $context-switcher-margin $context-switcher-margin $context-switcher-margin*0.5 $context-switcher-margin
    h1,
    .h1
      &,
      span
        margin: 0
        font-size: 16px
        color: $black
        @include media-breakpoint-up($context-switcher-breakpoint)
          font-size: 20px

.c-context-switcher__close
  cursor: pointer
  display: flex
  align-items: center
  i
    font-size: 24px
    transition: transform .1s ease-in-out
    @include media-breakpoint-up($context-switcher-breakpoint)
      font-size: 32px
  span
    display: none
    font-size: 16px
    margin-right: space(1)
    @include media-breakpoint-up($context-switcher-breakpoint)
      display: inline
  &:hover
    i
      transform: scale(1.1)
      color: $brand-primary

.c-context-switcher__areas
  flex: 1 1 auto
  display: flex
  overflow: hidden
  position: relative
  @include media-breakpoint-up($context-switcher-breakpoint)
    padding: 0 $context-switcher-margin
  .c-loading-animation
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba($white, .9)
    z-index: stack-order(top)


.c-context-switcher__area
  display: none
  &.active
    display: flex
    flex-direction: column
    flex: 1 1 100%
    padding-bottom: space(2)

.c-context-switcher__area-header
  flex: 0 1 auto
  padding: rem(5) space(2) space(1) space(2)
  h3
    font-size: 18px
  @include media-breakpoint-up($context-switcher-breakpoint)
    background: rgba($content-switcher-secondary-color, .05)
    padding: space(2) $context-switcher-margin 40px $context-switcher-margin
    margin-left: - $context-switcher-margin
    margin-right: - $context-switcher-margin
    h3
      font-size: 24px
      margin: 0

.c-context-switcher__area-container
  flex: 1 1 auto
  display: flex
  overflow: hidden
  z-index: 15
  @include media-breakpoint-up($context-switcher-breakpoint)
    margin-top: -25px
    border: 5px solid white
    border-radius: $border-radius
    background: white

.c-context-switcher__area-wrapper
  flex: 1 1 auto
  display: flex

.c-context-switcher__area-content
  position: relative
  flex: 1 1 100%
  max-width: 100%
  display: flex
  flex-direction: column
  padding: space(2)
  &:last-child
    display: none
  @include media-breakpoint-up($context-switcher-breakpoint)
    flex: 1 1 50%
    max-width: 50%
    &:last-child
      display: flex
    &:first-child
      padding-right: space(2)

.c-context-switcher__area-list
  overflow-y: auto
  overflow-x: hidden
  padding: space(2) space(2)
  @include media-breakpoint-up($context-switcher-breakpoint)
    padding: space(2)
    @include scrollbar($thumb-color: get-dash-color(link), $declare-overflow: false)
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb
      border-radius: 20px

.c-context-switcher__nav
  @include flex(0, 0, auto)
  display: flex
  flex-wrap: wrap
  align-items: stretch
  padding-top: space(2)
  @include media-breakpoint-up($context-switcher-breakpoint)
    padding-top: $context-switcher-margin
    border-bottom: 1px solid $content-switcher-secondary-color
  .c-context-switcher__nav-submit
    display: none
    @include media-breakpoint-up($context-switcher-breakpoint)
      display: flex

.c-context-switcher__nav-tab,
.c-context-switcher__nav-submit
  display: flex
  align-items: stretch
  flex: 1 1 auto
  flex-direction: column
  @include media-breakpoint-up($context-switcher-breakpoint)
    flex-direction: row

.c-context-switcher-featured-list
  > span
    font-weight: bold
    font-size: 16px
    padding-right: space(1)
    margin-bottom: space(1)

.c-context-switcher-featured-list__item
  &:first-child
    flex: 1 1 100%

.c-context-switcher__nav-tab
  flex-direction: column
  position: relative
  flex: 1 1 auto
  align-items: center
  @include media-breakpoint-up($context-switcher-breakpoint)
    margin-right: space(2)
    max-width: 50%
    flex: 1 1 auto
    &:first-child
      .c-context-switcher__selector
        border-left: 0
        border-top-left-radius: 0

.c-context-switcher__nav-tab-label
  pointer-events: none
  position: absolute
  top: -10px
  background: $gray-100
  left: calc(#{$context-switcher-margin} - 20px)
  padding: 2px 15px 2px 5px
  border-radius: 2px
  font-size: 12px
  color: $black
  font-weight: font-weight(bold)
  @include media-breakpoint-up($context-switcher-breakpoint)
    font-size: 14px
    padding: 2px 5px
    left: calc(#{$context-switcher-margin} - 10px)

.c-context-switcher__nav-submit
  display: flex
  flex: 1 1 100%
  @include media-breakpoint-up($context-switcher-breakpoint)
    align-items: center
    flex: 0 1 100px
  @include media-breakpoint-up(md)
    flex: 0 1 150px
  @include media-breakpoint-up(lg)
    flex: 0 1 300px
  .btn
    flex: 1 1 auto
    min-width: 120px
    margin: 0
  a.disabled
    background: $gray-200
    color: $gray-600
    border-color: $gray-400
    pointer-events: all
    cursor: not-allowed
  a.bounce
    animation-name: bounce
    animation-duration: .75s
  a.active:not(:hover)
    animation-name: pulse
    animation-duration: 2s
    animation-iteration-count: infinite
  a.loading,
  a.loading:active,
  a.loading:hover
    position: relative
    background: white
    color: $gray-600
    border-color: $gray-400
    overflow: hidden
    cursor: progress !important
    &::after
      top: 0
      left: 0
      right: 0
      bottom: 0
      content: ''
      position: absolute
      animation: skeleton-keyframes-wave 1.6s linear 0.5s infinite
      transform: translateX(-100%)
      background: linear-gradient(90deg, transparent, rgba($brand-secondary, 0.16), transparent)

.c-context-switcher__nav-feedback
  display: none
  font-size: rem(14)
  &::after
    content: ''
    height: 100%
    background: $gray-400
    width: 1px
    margin-right: space(3)
    margin-left: space(3)
    flex: 1 1 1px
    display: block
  @include media-breakpoint-up(md)
    margin-left: space(2)
    display: flex
    white-space: nowrap
    align-items: center

.c-context-switcher__nav-tabs
  flex: 1 1 100%
  display: flex
  justify-content: space-between
  flex-direction: column
  @include media-breakpoint-up($context-switcher-breakpoint)
    flex: 1 1 auto
    margin-bottom: 0
    justify-content: flex-start
    flex-direction: row
    margin-right: space(2)

.c-context-switcher__selector
  flex: 1 1 auto
  align-self: stretch
  padding-left: space(2)
  padding-top: 15px
  padding-bottom: 7px
  display: flex
  justify-content: space-between
  align-items: center
  transform-origin: center
  cursor: pointer
  font-size: 14px
  margin-bottom: space(3)
  margin-left: space(2)
  margin-right: space(2)
  border: 1px solid $gray-100
  border-radius: $border-radius
  background: white
  text-align: left
  span
    width: 100%
    display: block
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  @include media-breakpoint-up($context-switcher-breakpoint)
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    border-bottom-width: 0
    border-bottom-color: transparent
    padding-left: $context-switcher-margin
    padding-right: space(2)
    margin: 0
    font-size: 16px
    min-width: 200px
  @include media-breakpoint-up(md)
    max-width: auto
  @include media-breakpoint-up(lg)
    min-width: 400px
  &:hover,
  &:focus,
  &.active
    background: rgba($content-switcher-secondary-color, .05)
    border-color: rgba($content-switcher-secondary-color, .15)
    & ~ .c-context-switcher__nav-tab-label
      background: #f6fbfc
    &::after
      color: $content-switcher-secondary-color
  &.active
    border-color: $content-switcher-secondary-color
    & ~ .c-context-switcher__nav-tab-label
      background: $content-switcher-secondary-color
      color: $white
  &::after
    @include font-icon
    flex: 0 1 auto
    transform: rotate(180deg)
    margin-left: auto
    margin-left: 10px
    transition: .25s transform ease-in-out
    font-size: 18px
    @include media-breakpoint-up($context-switcher-breakpoint)
      content: '\f106'
      font-size: 24px
  &.active
    font-weight: font-weight(bold)
    &::after
      transform: rotate(0)
  &.highlight
    animation-name: highlight
    animation-duration: .75s


// List
.c-context-switcher__list
  font-size: rem(16px)
  background: $white
  border-radius: $border-radius
  margin-top: space(2)
  [data-context-switcher--accordion-list-searchable-type="County"]
    margin-top: space(2)
    margin-bottom: space(2)
  &,
  ul
    @include list-unstyled
  ul
    padding-left: space(2)
    padding-right: space(2)
    margin-bottom: 0


.c-context-switcher__list-group
  margin-bottom: rem(2px)
  > span
    padding-left: .25rem
    padding-right: .25rem
  > ul
    display: flex
    flex-wrap: wrap
    border: 1px solid $content-switcher-secondary-color
    border-top-width: 0
    border-bottom-left-radius: $border-radius
    border-bottom-right-radius: $border-radius

.c-context-switcher__list-child
  margin: rem(3) 0
  flex: 1 1 100%
  max-width: 100%
  @include media-breakpoint-up($context-switcher-breakpoint)
    flex: 1 1 percentage(1*0.5)
    max-width: percentage(1*0.5)

.c-context-switcher__list-child--search-result
  flex: 1 1 100%
  max-width: 100%
  padding: 0 space(2)
  @include media-breakpoint-up($context-switcher-breakpoint)
    flex: 1 1 100%
    max-width: 100%

.c-context-switcher__list-child--all
  border-top: 1px solid $gray-400
  margin-top: space(2)

.c-context-switcher__list-link,
.c-context-switcher__list-toggle
  padding: space(1)
  border-radius: $border-radius
  display: flex
  text-decoration: none

.c-context-switcher__list-link,
.c-context-switcher__list-explore-all
  flex-direction: column
  max-width: 100%
  position: relative
  &,
  .go
    cursor: pointer
  .go
    position: absolute
    padding-left: space(2)
    padding-right: space(2)
    right: 0
    top: 0
    bottom: 0
    display: flex
    align-items: center
    z-index: 10
    background: $brand-primary
    border-left: 1px solid $white
    border-top-right-radius: $border-radius
    border-bottom-right-radius: $border-radius
    &:hover,
    &:focus
      background: darken($brand-primary, 5%)
  @include media-breakpoint-up($context-switcher-breakpoint)
    span
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
  &.hover,
  &:hover,
  &:focus
    text-decoration: none
    background: $gray-100
    color: $brand-secondary
  &.active
    color: $white
    font-weight: bold
    background: $brand-secondary
    span
      margin-right: space(4)
    .c-context-switcher__list-link-parent-list span
      color: rgba($white, .75)
    &:hover,
    &:focus
      background: lighten($brand-secondary, 5%)
      text-decoration: none

.c-context-switcher__list-link a
  animation-name: pulse
  animation-duration: 2s
  animation-iteration-count: infinite
  background: get-dash-color(link)
  color: $white
  &,
  &:hover,
  &:focus
    text-decoration: none

.c-context-switcher__list-explore-all
  display: block
  margin: space(2) 0
  padding: space(1)
  color: $black
  font-weight: font-weight(semibold)

.c-context-switcher__list-toggle
  transition: .15s ease-in-oout
  &:hover,
  &:focus
    background: $gray-100
    text-decoration: none

.c-context-switcher__list-link.c-context-switcher__list-group
  margin-left: rem(2px)
  margin-right: rem(2px)

// .c-context-switcher__list-link-name
.c-context-switcher__list-link-parent-list
  display: block
  padding-left: space(4)
  span
    font-size: rem(14)
    color: $gray-600

.c-context-switcher__list-toggle
  // margin-right: space(2)
  // padding-left: space(2)
  // padding-right: space(2)
  border: 1px solid transparent
  border-bottom: 0
  &::after
    flex: 0 1 auto
    @include font-icon
    content: '\f107'
    font-size: 18px
    line-height: 1
    margin-left: auto
    transform-origin: center
    transition: .2s transform ease-in-out
  &[aria-expanded='true']
    position: sticky
    top: 0
    background: $gray-100
    border-color: $content-switcher-secondary-color
    border-radius: 0
    z-index: stack-order(btm)
    &::after
      transform: rotate(180deg)

.c-context-switcher__list-toggle-name
  flex: 1 1 auto
  font-size: rem(14)
  line-height: 16px
  @include media-breakpoint-up($context-switcher-breakpoint)
    font-size: rem(16)
    line-height: 18px

.c-context-switcher__list-toggle-count
  flex: 0 1 auto
  margin-right: space(2)
  font-size: rem(14)
  color: $gray-600

.c-context-switcher__map
  background: #f9f9f9
  flex: 1 1 100%

.c-context-switcher__footer
  @include flex(0, 0, auto)
  transition: background .15s ease-in-out
  display:  flex
  padding: 15px $context-switcher-margin space(5) $context-switcher-margin
  background: rgba($content-switcher-secondary-color, .05)
  position: relative
  z-index: 10
  @include media-breakpoint-up($context-switcher-breakpoint)
    padding: 35px $context-switcher-margin $context-switcher-margin
    margin-top: -25px
  // &.enabled
  .c-context-switcher__nav-submit
    width: 100%
    overflow: visible
    @include media-breakpoint-up($context-switcher-breakpoint)
      margin-left: auto
      a
        width: auto
        width: 25%
        min-width: 300px
        margin-left: auto

@keyframes highlight
  0%
    background: $brand-secondary
  30%
    color: $white
  75%
    color: $black
  100%
    background: $white

@keyframes pulse
  0%
    background: $brand-primary
  50%
    background: $brand-primary-l
    border-color: $brand-primary-l
    color: black
  100%
    background: $brand-primary

@keyframes bounce
  0%
    transform: scale(1,1) translate(0px, 0px)
  30%
    transform: scale(.95,.95) translate(0px, 2px)
  75%
    transform: scale(1.05,1.05) translate(0px, -5px)
  100%
    transform: scale(1,1) translate(0px, 0px)

@keyframes rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@keyframes skeleton-keyframes-wave
  0%
    transform: translateX(-100%)
  60%
    transform: translateX(100%)
  100%
    transform: translateX(100%)
