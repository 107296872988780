@import '../helpers'

.c-loading-animation
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  top: 0
  bottom: 0
  transition: .45s opacity ease-in-out
  z-index: stack-order(mid)
  pointer-events: none
  &.hidden
    display: none
  &.dismissing
    opacity: 0

.c-loading-animation--fixed
  position: fixed
  z-index: stack-order(cosmos) + 10

.c-loading-animation--with-veil
  &:before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: rgba($white, .75)
    z-index: stack-order(top)-1

.c-loading-animation--with-veil-opaque
  &:before
    background: $white

.c-loading-animation__indicator
  display: flex
  align-items: center
  flex-direction: column
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  font-size: rem(14px)
  font-weight: bold
  z-index: stack-order(cosmos) + 20
  color: $brand-primary

.c-loading-animation__indicator--with-bg
  background: rgba($white, .75)
  padding: space(4)
  border-radius: $border-radius

.c-loading-animation__indicator--padded
  padding: space(4)

.c-loading-animation__indicator svg
  height: 100px
  margin-bottom: space(2)

.c-loading-animation__indicator span
  margin-left: space(1)
  text-align: center

.c-loading-animation__indicator-bg
  width: 100%
  height: 100%
  animation: loading
  animation-duration: 3s
  animation-iteration-count: infinite
  animation-timing-function: ease-in-out

@keyframes loading
  0%
    transform: translateY(-95%)
  50%
    transform: translateY(95%)
  100%
    transform: translateY(-95%)
