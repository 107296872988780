@import '../helpers'

// .c-report

.c-report__header
  margin-bottom: space(2)

.c-report__dates
  font-weight: 600

.c-report__section
  margin-bottom: space(6)
  &:first-of-type
    margin-top: space(4)

.c-report__section ul
  br
    display: none
  li
    margin-bottom: spac(1)

.c-report__actions
  margin: 0 0 space(4) 0

.c-report__actions a,
.c-report__actions button
  margin-right: space(1)


.c-report__text-content p
  margin: 0
