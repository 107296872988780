@use "sass:math"

@import '../helpers'

$nav-item-count: 6

.c-page-navbar
  background: $page-navbar-bg
  padding: 0
  min-height: $page-navbar-height
  max-width: 100%
  align-items: stretch
  z-index: stack-order(top)
  position: fixed
  bottom: 0
  width: 100%
  flex-wrap: wrap
  @include media-breakpoint-up($page-nav-breakpoint)
    position: static
    position: sticky
    top: 0

.c-page-navbar__title-wrapper
  padding: 0 space(4)
  @include flex(0, 0, auto)

.c-page-navbar__location-search
  @include media-breakpoint-up($page-nav-breakpoint)
    padding: space(4)
    padding: 0
    @include flex(1, 1, auto)
    max-width: 200px
    margin-right: space(4)
  @include media-breakpoint-up(lg)
    margin-right: space(6)

.c-page-navbar__actions
  flex: 1 1 auto
  display: flex
  padding-bottom: space(3)
  justify-content: space-between
  @include media-breakpoint-up(md)
    align-items: center
    padding-bottom: 0
    margin-right: space(2)

.c-page-navbar__actions-primary
  flex: 1 1 auto
  padding-left: space(4)
  padding-right: space(4)
  margin-bottom: space(2)
  @include media-breakpoint-up(sm)
    padding-left: space(4)
    padding-right: space(4)
    margin-bottom: 0
    flex: none
  button
    font-size: 16px
    margin-top: space(1)
    margin-bottom: space(1)
    padding: space(1) space(3)
    width: 100%
    border-color: rgba($page-navbar-border-color, .5)

.c-page-navbar__actions-secondary
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  .c-hero-banner__cta span:last-child
    font-size: 13px
    padding: space(1) space(2)
  .c-hero-banner__cta
    margin-top: space(2)
    span
      padding: space(1) space(3)
  @include media-breakpoint-up(sm)
    flex-direction: row
    .c-hero-banner__cta
      margin-top: 0
  @include media-breakpoint-up(md)
    flex-direction: row
    justify-content: flex-end
    align-items: center
    margin-left: auto
    flex-wrap: wrap
  @include media-breakpoint-between(sm, md)
    .c-hero-banner__cta
      margin-top: space(1)
      margin-bottom: space(1)

.c-page-navbar__actions-secondary-link
  font-size: 14px
  &:last-child
    border: 0

.c-page-navbar__title,
.c-page-navbar__sub-title
  display: block
  font-weight: 800
.c-page-navbar__title
  font-size: 24px
  color: $page-navbar-active-color
  margin-bottom: 0
  line-height: 1
  text-transform: none

.c-page-navbar__sub-title
  display: block
  color: $card-color
  font-weight: 600

.c-page-navbar__nav
  align-items: stretch

.c-page-navbar
  .navbar-nav
    padding-bottom: space(4)
    width: 100%
    @include media-breakpoint-up($page-nav-breakpoint)
      padding-bottom: 0
  .nav-item
    display: flex
    align-items: stretch
    &:first-of-type a
      border-left: 0
  .nav-item,
  .nav-link
    @include flex(1, 1, auto)
    padding-left: 0
    padding-right: 0
    @include media-breakpoint-up(sm)
      min-width: percentage(math.div(1, $nav-item-count))
  .nav-link
    @include font-semibold($size: rem(18px), $lh: 24px)
    display: flex
    padding: space(2) space(4)
    align-items: center
    color: $page-navbar-active-text-color
    letter-spacing: .03em
    border-top: $page-navbar-border-color 1px solid
    transition: .2s ease-in-out
    margin-top: -1px
    @include media-breakpoint-between(md, lg)
      @include font-semibold($size: rem(16px), $lh: 22px)
      padding-top: space(3)
      padding-bottom: space(3)
    @include media-breakpoint-up($page-nav-breakpoint)
      justify-content: center
      border-left: $page-navbar-border-color 1px solid
      padding: space(1) space(2)
    &:focus,
    &:hover,
    &.active
      color: $page-navbar-color
  .nav-link.dropdown-toggle
    &::after
      @include font-icon
      border: 0
      content: '\f0d7'
      margin-left: auto
      position: static
      height: auto
      width: auto
      transform: none
      font-size: 14px
  .navbar-toggler
    font-size: rem(16)
  .navbar-toggler,
  .navbar-toggler-icon
    color: $card-color
    border: none
    @include media-breakpoint-up(md)
      display: none
  .navbar-toggler-icon
    &::after
      content: '\f0c9'
      @include font-icon
      color: $card-color

.c-page-navbar__initial-bar
  display: flex
  @include flex(1, 1, 100%)
  max-width: 100%
  padding-top: space(2)
  padding-bottom: space(2)

.c-page-navbar__initial-bar-link,
.c-page-navbar__actions-secondary-link
  font-weight: 600
  color: rgba($white, .75)
  padding: 0 space(2)
  &:hover,
  &:focus
    color: dash-color(link-color-nav)

a.c-page-navbar__actions-secondary-link
  color: lighten(dash-color(danger), .15)
  &:hover,
  &:focus
    color: dash-color(link-color-nav)

.c-page-navbar__nav-wrapper
  display: flex
  flex-direction: column
  @include flex(1, 1, 100%)
  max-width: 100%
  @include media-breakpoint-up(md)
    flex-direction: row

.c-page-navbar__dropdown-section
  @include media-breakpoint-up(sm)
    border-right: 1px solid rgba($page-navbar-border-color, .75)
    min-width: percentage(math.div(1, $nav-item-count))
    &:last-of-type
      border: 0
