@import '../helpers'

.c-map-legend
  display: flex
  flex-direction: column
  background: $card-color
  margin-bottom: space(4)
  margin-top: 0
  padding: space(2) space(4)
  border-top: 1px solid $border-color-secondary
  @include media-breakpoint-up(sm)
    flex-direction: row

.c-map-legend--bg-white
  &,
  & ~ .c-topic-note
    background: $white
