@use "sass:math"

@import '../../helpers'
@import '../../tools/mixins/dashboard'

$progression-breakpoint-down: lg
$progression-breakpoint-up: md2

.c-progression-vertical-connector
  background-color: #747374
  width: 10px
  border-radius: 5px
  position: absolute
  top: -15px
  bottom: -50px
  right: 25%
  margin-right: -3px
  @include media-breakpoint-down($progression-breakpoint-down)
    display: none

.c-progression-horzontal-connector
  position: absolute
  top: 50%
  margin-top: -3px
  left: 75%
  margin-left: 5px
  right: -25px
  border-bottom: 10px dotted rgba(dash-color(danger), .2)
  @include media-breakpoint-down($progression-breakpoint-down)
    display: none

.c-progression-card--primary,
.c-progression-card--secondary
  justify-content: center

.c-progression-card--primary
  margin-bottom: space(6)
  @include media-breakpoint-up(lg)
    margin-bottom: 0
  .c-progression-card__stat
    @include media-breakpoint-down($progression-breakpoint-down)
      margin-top: space(4)
      justify-content: flex-start !important
      div
        display: block !important
        justify-content: flex-start !important
  @include media-breakpoint-down($progression-breakpoint-down)
    &::after
      content: ''
      border-right: 10px dotted rgba(dash-color(danger), .2)
      width: 1px
      bottom: 0
      right: 25px
      height: space(8)
      position: absolute
      z-index: 11

.c-progression-card--ghost,
.c-progression-card--secondary
  min-height: 0
  margin-top: -15px
  margin-bottom: space(8)
  background: rgba(dash-color(card-bg), .5)
  border: 3px solid dash-color(card-bg)
  @include media-breakpoint-up(lg)
    margin-bottom: -15px
  @include media-breakpoint-down($progression-breakpoint-down)
    margin-left: space(6)
    width: calc(100% - #{space(6)})
    position: relative
    &::before
      content: ''
      margin-right: space(4)
      background-color: #747374
      width: 10px
      border-radius: 5px
      top: -35px
      bottom: -50px
      left: -25px
      height: calc(100% + #{space(8)} + #{space(6)})
      position: absolute
  .c-progression-card__stat
    margin-top: space(4)
    @include media-breakpoint-up(sm)
      margin-top: 0

.c-progression-card--primary
  @include media-breakpoint-down(sm)
    > .row
      flex-direction: column
.c-progression-card--secondary
  background: rgba($white, .85)
  @include media-breakpoint-down(sm)
    > .row
      flex-direction: column-reverse

.c-progression-card--ghost
  min-height: 0
  @include media-breakpoint-up($progression-breakpoint-up)
    min-height: 100px
  background: none
  border: 0
  box-shadow: none

.c-progression-card--ending
  &:after
    display: none

// Old stuff

$step-min-width: 175px
$step-max-width: 200px
$step-summary-height: 150px
$step-chart-height: 350px
$scroll-bar-height: 15px
$default-color: $gray-300
$step-intermediary-width: 40px
$step-colors: $chart-colors


// ~~~~~~~~~~~~~~~~~~~~
// Title
// (Move to other file)
.c-progression-old-title
  padding: space(4)
  margin-left: -(space(4))
  margin-right: -(space(4))
  margin-bottom: -(space(4))
  z-index: 1
  position: relative
  background: $default-color
  p
    margin-bottom: 0
// ~~~~~~~~~~~~~~~~~~~~

.c-progression-old
  position: relative
  margin-left: -(space(4))
  margin-right: -(space(4))

.c-progression-old__scroll-indicator
  position: absolute
  right: 0
  top: 20px
  bottom: 20px
  width: 10%
  background: linear-gradient(90deg, rgba(dash-color(card-bg), 0) 0%, rgba(dash-color(card-bg), 1) 100%)
  z-index: 100
  pointer-events: none

.c-progression-old__wrapper
  padding-top: space(4)
  display: flex
  overflow: hidden
  @include scrollbar($width: $scroll-bar-height, $radius: true)
  padding-right: 10%

.c-progression-old__group
  @include flex(1, 0, auto)
  min-width: 50%
  position: relative
  z-index: 10
  &:last-child
    .c-progression-old__group-container
      padding-right: 100px
      .c-progression-old__step:last-child .c-progression-old__arrow
        &::after
          display: none


.c-progression-old__group--intermediary
  min-width: auto
  max-width: 50px
  z-index: 1
  background: $default-color

.c-progression-old__group-title
  background: $default-color
  padding: space(2) space(4)
  min-height: 50px
  position: relative
  margin-bottom: 0
  z-index: 10

.c-progression-old__group-title--1
  &::after
    content: ''
    position: absolute
    right: -$step-intermediary-width
    top: 50%
    transform: translateY(-50%)
    width: 0
    height: 0
    border-top: $step-intermediary-width solid transparent
    border-bottom: $step-intermediary-width solid transparent
    border-left: $step-intermediary-width solid map-get($step-colors, 1)

@each $index in 1 2 3
  $color: map-get($step-colors, $index)
  .c-progression-old__group-title--#{$index}
    background: $color
    color: determine-color-for-best-contrast($color)

.c-progression-old__group--intermediary
  .c-progression-old__group-title
    z-index: 1

.c-progression-old__group-container
  display: flex

.c-progression-old__step-description
  font-size: 14px
  line-height: math.div(18, 14)
  font-weight: 600
  margin-bottom: space(2)

.c-progression-old__step-metric
  @include font-semibold(26px, $lh: 1, $color: dash-color(metric-color-primary))

.c-progression-old__step-metric--secondary
  @include font-light(20px, $lh: 1, $color: dash-color(metric-color-secondary))

.c-progression-old__step
  display: flex
  flex-direction: column
  max-width: $step-max-width
  @include flex(1, 0, $step-max-width)
  border-right: 1px solid $gray-200
  &:last-child
    border-right: 0

.c-progression-old__step--intermediary
  max-width: $step-intermediary-width
  min-width: $step-intermediary-width

.c-progression-old__chart
  min-height: $step-chart-height
  max-width: $step-max-width

.c-progression-old__summary
  @include flex(0, 0, auto)
  padding: space(4) space(4) 0 space(4)
  min-height: $step-summary-height
  display: flex
  flex-direction: column
  justify-content: space-between
  padding-bottom: space(4)
  margin-bottom: space(4)

.c-progression-old__summary--secondary
  padding: space(4) space(4) space(6) space(4)
  min-height: 125px
  .c-progression-old__step-metric
    font-size: 18px
  .c-progression-old__step-metric--secondary
    font-size: 17px

.c-progression-old__arrow
  height: 8px
  width: 100%
  position: relative
  background: darken($default-color, 10%)
  z-index: 10
  margin-bottom: space(4)
  &::after
    content: ''
    position: absolute
    right: -3px
    top: 50%
    transform: translateY(-50%)
    width: 0
    height: 0
    border-top: 12px solid transparent
    border-bottom: 12px solid transparent
    border-left: 12px solid darken($default-color, 10%)

@each $index in 1 2 3
  $color: map-get($step-colors, $index)
  .c-progression-old__arrow--#{$index}
    background: $color
    &::after
      border-left-color: $color
