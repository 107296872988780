@import '../helpers'

$indicator-pog-size: 50px

.c-indicator-pog
  border: 5px solid white
  border-radius: 50%
  height: $indicator-pog-size
  width: $indicator-pog-size
  display: flex
  overflow: hidden

.c-indicator-pog__left,
.c-indicator-pog__right
  content: ''
  max-width: 50%
  height: 100%
  flex: 1 1 50%
  background: $brand-primary-d
