@import '../helpers'

.c-search
  padding-bottom: space(6)

.c-search__results
  min-height: 50px
  position: relative
  &:empty
    display: none
    min-height: 0

.c-search__input
  &.with-results,
  &.with-results:focus
    border: 1px solid get-dash-color(link)
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    border-bottom: 0

.c-search__input-label
  margin-bottom: space(1)

// .c-search__loading

.c-search__results-wrapper
  margin: - (space(3)) space(4) space(4)
  padding-top: space(4)
  background: $white

.c-search__results
  position: relative
  @include list-unstyled
