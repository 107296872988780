@use "sass:math"

@import '../helpers'

.c-comparison-legend,
.c-comparison-legend__group
  display: flex
  align-items: center

.c-comparison-legend
  display: flex
  align-items: stretch
  flex-wrap: wrap
  padding: 0
  background: $white
  border-bottom: 1px solid $border-color-secondary
  width: 100%
  min-height: 38px
  @include transition($prop: opacity)
  &.hidden
    display: none

.c-comparison-legend--secondary
  margin-bottom: 0
  border-bottom: 0
  display: none
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: flex

.c-comparison-legend--secondary .c-comparison-legend__group
  border-top: 0

.c-comparison-legend__group
  @include flex(1, 1, 50%)
  padding: 0 space(4)
  border-top: 1px solid $border-color-secondary
  border-right: 1px solid $border-color-secondary
  justify-content: center
  &:last-child
    border-right: 0
  @include media-breakpoint-up($nav-switch-breakpoint)
    flex: 1 1 percentage(math.div(1, 3))
  @include media-breakpoint-up(lg)
    @include flex(0, 0, auto)
    &:last-child
      @include flex(1, 1, auto)
      justify-content: flex-start


.c-comparison-legend__group--vertical-padding
  padding: space(1) space(4)

.c-comparison-legend__item-label
  @include font-semibold(rem(13px), $gray-700)
  white-space: nowrap

.c-comparison-legend__line
  width: 30px
  height: 16px
  margin-right: space(2)

.c-comparison-legend__line g
  stroke: $brand-primary
  stroke-width: 2px
  transform: translate(1px, -5px)
  fill: none
  stroke-linecap: round

.c-comparison-legend__line--comparison g
  @include dashed-line($a: 4, $b: 5, $color: $metric-comparison, $width: 2px)
