@import '../helpers';

// Huge padding
@each $space in 75 100 125 150 {
  @each $direction-code, $direction in $directions {
    .p#{$direction-code}-#{$space} {
      padding-#{$direction}: #{$space}px !important;
    }
  }
}
