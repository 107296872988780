@import '../../helpers'

$dropdown-height: 30px

.dropdown--mui
  > a
    color: $black
    display: flex
    justify-content: space-between
    align-items: center
    background: $white
    border: 1px solid rgba($black, .23)
    border-radius: $border-radius
    min-height: $dropdown-height
    padding: space(2) space(2)
    display: flex
    align-items: center
    &[aria-expanded="true"]
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
    &::after
      display: none
  .dropdown-menu
    width: 100%
    transform: translateY($dropdown-height + 11px) !important
    border-top-left-radius: 0
    border-top-right-radius: 0
