@import '../helpers'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Styles for the Delaware nav
// _______________________________________________________________

.c-nav--delaware.navbar
  background-color: $brand-primary-d
  border-top: 10px solid $brand-primary-d
  border-bottom: 1px solid $brand-primary-d
  box-shadow: none
  margin-bottom: 0
  padding-top: 0
  padding-bottom: 0
  height: 60px
  @include media-breakpoint-up(md)
    height: 60px

.c-nav--delaware .navbar-brand
  padding: 0
  @include media-breakpoint-up(md)
    padding-right: 40px

.c-nav--delaware__navbar-nav
  margin-left: auto
  @include media-breakpoint-down(sm)
    display: none

.c-nav--delaware__navbar-nav li
  display: inline-block
  margin-top: -2px

.c-nav--delaware__navbar-nav li + li
  margin-left: 20px

.c-nav--delaware__navbar-nav li a.nav-link
  font-size: 16px
  @include bolden
  padding-right: 0
  padding-left: 0
  border-bottom: 2px solid transparent
  color: rgba($white, .8)
  & svg
    fill: rgba($white, .8)

.c-nav--delaware__navbar-nav li a.nav-link:focus,
.c-nav--delaware__navbar-nav li a.nav-link:hover
  border-bottom-color: rgba($white, .5)
  background-color: transparent
  color: rgba($white, 1)
  & svg
    fill: rgba($white, .8)

.c-nav--delaware .delaware_d_logo
  width: 40px
  height: 40px

.delaware_d_logo
  margin-left: 0

.delaware_text
  width: 160px
  height: 30px
  margin-top: 5px
  margin-left: 0

.c-nav--delaware svg:not(:root).svg-inline--fa,
.c-footer--delaware svg:not(:root).svg-inline--fa
  overflow: visible

.c-nav--delaware .svg-inline--fa.fa-fw
  width: 1.25em

.c-nav--delaware .fa-fw
  text-align: center
  width: 1.25em

.c-nav--delaware .svg-inline--fa.fa-w-10
  width: .625em

.c-footer--delaware .svg-inline--fa.fa-w-14
  width: .875em

.c-nav--delaware .svg-inline--fa,
.c-footer--delaware .svg-inline--fa
  display: inline-block
  font-size: inherit
  height: 1em
  overflow: visible
  vertical-align: -.125em

.c-footer--delaware .fa-3x
  font-size: 3em
