@import '../helpers'

.c-tooltip
  display: inline-flex
  align-items: center

.c-tooltip--reversed
  display: inline-flex
  flex-direction: row-reverse
  align-items: baseline
  justify-content: flex-end
  .c-tooltip__content
    margin-left: space(1)

.c-tooltip__info-message
  display: flex
  align-items: baseline
  margin-top: space(1)

.c-tooltip__info-message span,
.c-tooltip__info-message i
  font-size: 12px
  line-height: 14px

.c-tooltip__info-message i
  color: $brand-info
  margin-right: space(2)

.c-tooltip__icon
  color: $brand-primary

.c-tooltip-icon
  margin: 0 3px

.c-tooltip-icon i
  font-size: 100%

.c-tooltip-icon i ~ span
  margin-left: rem(3)
  white-space: nowrap

.c-tooltip-icon .icon-svg
  &,
  svg
    fill: $brand-primary
    height: 14px
    width: 14px
