@import '../helpers'

$context-header-border-color: #E8E8E8

.c-context-header
  background: white
  border-bottom: 1px solid $context-header-border-color
  margin-bottom: space(4)

.c-context-header__content
  display: flex
  justify-content: space-between
  align-items: stretch
  @include topic-container

.c-context-header__nav-toggle
  padding-right: 24px
  margin-right: 24px
  border-right: 1px solid $context-header-border-color
  button
    @include mui-light-button

.c-context-header__nav
  flex: 1 1 auto
  display: flex
  justify-content: flex-end

.c-context-header__location
  flex: 0 1 auto
  display: flex
  align-items: center
  flex-wrap: wrap
  font-size: 14px
  margin-left: auto

.c-context-header--dashboard
  margin-bottom: 0
  @include media-breakpoint-down(sm)
    padding: space(2) space(3)
  @include media-breakpoint-up(sm)
    padding-left: space(4)
    padding-right: space(4)
    height: $utility-header-height
  position: sticky
  top: 0
  z-index: stack-order(top)
  display: flex
  .c-context-header__content
    display: flex
    flex: 1 1 100%
    flex-wrap: wrap
    justify-content: flex-start
    max-width: none
    padding: 0
  &.nav-closed
    .c-context-header__nav-toggle
      display: flex
    .c-context-header__location-population
      display: block

.c-context-header__nav-toggle
  display: none

.c-context-header__location-population
  display: none
