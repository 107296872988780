@import '../helpers'

.o-metric-cards
  position: relative
  margin-bottom: space(6)
  display: flex
  flex-wrap: wrap

.o-metric-cards--fluid-height
  min-height: auto
