@import '../helpers'

.c-tabs--levels
  // border-bottom: 2px solid $white
  margin-bottom: space(4)
  align-items: stretch
  @each $level-name, $color in $level-colors
    &.#{$level-name}
      border-color: $color

.c-tabs--levels .c-tab
  margin-right: space(2)
  display: flex

.c-tabs--levels .c-tab__link
  color: $black
  text-transform: capitalize
  border-bottom: 0
  opacity: .75
  margin-bottom: 0
  padding-right: space(4)
  padding-left: space(4)
  display: flex
  flex-direction: column
  justify-content: center
  font-size: 18px
  &.active
    @include bolden
    opacity: 1
  span:last-of-type
    font-size: 13px
    color: $gray-800
