@import '../helpers'

.bb svg
  @include font-default(rem(14px), $black)

.bb-title
  @include font-default(rem(16px), $black)
  font-weight: bold

.bb-line
  stroke-width: $chart-line-width

.bb-axis
  overflow: hidden

.bb-axis .tick line,
.bb-axis path
    stroke: $chart-axis-color

.bb-axis-x g
  @include media-breakpoint-down(sm)
    font-size: 90%

.bb-axis-x-label,
.bb-axis-y-label,
.bb-axis-y2-label
  @include font-semibold(rem(16px), $chart-axis-label-color)

.bb-axis .tick text
  fill: $chart-axis-label-color

.bb-chart-granularity--month .bb-axis-x-label
  transform: translate(0, 35px)

.bb-chart-line
  fill: none

.bb-grid line
  stroke: lighten($chart-axis-color, 15%)

.bb-xgrid,
.bb-ygrid
  stroke-dasharray: 4 4

.c-chart .domain
  fill: transparent
  stroke: transparent

.bb-target-bb-line--hidden path,
.bb-line--hidden
  visibility: hidden

.bb-line,
.bb-line--dashed,
.bb-grid-line--dotted,
.bb-target-bb-line--dashed path,
.bb-target-bb-line--dotted-thick path
  stroke-linecap: round

.bb-target-bb-line--dashed path,
.bb-line--dashed
  @include dashed-line

.bb-grid-line--dotted
  @include dotted-line

.bb-target-bb-line--dotted-thick path,
.bb-line--dotted-thick
  stoke-width: 5px
  @include dotted-line($a: 0, $b: 7)

.bb-circle
  @include transition($prop: fill)

.bb-lines path
  @include transition($prop: stroke)

.bb-areas path
  opacity: .25

.bb-line--stroked
  stroke-width: 5px
  stroke: $card-color !important

// .bb-chart-lines .bb-chart-line:nth-of-type(n+4)
//   .bb-lines path
//     stroke-dasharray: 10
//     stroke-linecap: round

.bb-shapes use
  @include media-breakpoint-down(md)
    &:nth-child(2n)
      display: none

.bb-chart-texts .bb-text
  font-size: 15px
  font-weight: bold

// level reference regions
$level-reference-colors: (#30A4E3, #80BDCE, #FFD18A, #FF925C, #C24644, #BF1B1B)

.bb-region.level-ref
  fill-opacity: 1
@for $i from 1 through length($level-reference-colors)
  $color: nth($level-reference-colors, $i)
  .bb-region-#{$i - 1}
    fill: $color
