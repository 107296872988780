@import '../helpers'

.c-comparison-header
  @include topic-header
  background: $white
  display: none
  &.show
    display: flex
  @include media-breakpoint-up($nav-switch-breakpoint)
    width: 100%
    padding-left: space(2)
    padding-right: space(2)
    border-radius: $border-radius
    margin-top: 2px
    &,
    &.hidden
      display: flex

.c-comparison-header__description
  display: none
  @include media-breakpoint-up(md)
    display: block
    @include flex(1, 1, 100%)
    padding-top: space(2)

.c-comparison-header__description p
  @include constrained-container
  margin: 0

.c-comparison-header__text
  margin-right: space(1)
  white-space: nowrap
  padding-top: space(2)
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding-top: 0

.c-comparison-header__text--primary
  @include bolden
  @include data-metric($color: $metric-primary)
  letter-spacing: .01em

.c-comparison-header .c-dropdown
  padding-top: space(2)
  padding-bottom: space(2)
