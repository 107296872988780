@import '../helpers'

$click-area-size: 40px
$item-base-padding: 40px
$nav-active-color: rgb(0, 96, 240)

.c-side-nav
  &,
  ul
    @include list-unstyled

.c-side-nav
  @include flex(1, 1, auto)
  overflow: auto

  & *
    font-size: 14px

.c-side-nav__section
  margin-bottom: 0
  margin-left: 0
  margin-right: 0

.c-side-nav__item-icon
  color: #9E9E9E
  display: inline-flex
  align-items: center
  width: 14px // This is the size of the icons used
  margin-right: 8px

.c-side-nav__item-text,
.c-side-nav__item-text > span
  font-family: $font-family-sans-serif
  color: $brand-primary-dd
  word-wrap: break-word
  overflow-x: hidden

.c-side-nav__item-text
  .category
    font-weight: 700
  .page
    font-weight: 400

.c-side-nav__item-caret
  position: absolute
  top: 0
  bottom: 0
  right: 0
  display: flex
  min-width: $click-area-size
  justify-content: center
  align-items: center
  transform: rotate(0deg)
  transition: transform 0.1s ease-in-out
  color: rgba(0, 0, 0, 0.54)
  font-size: 20px
  border-radius: 50%
  z-index: 10
  &:focus
    outline: 2px solid $link-color
    outline-inset: -4px
  &:focus,
  &:hover
    background: rgba(0, 96, 240, 0.05)
  & *
    font-size: inherit
  &:empty
    display: none
  &.open
    transform: rotate(90deg)

.c-side-nav__item
  position: relative
  display: flex
  cursor: pointer
  align-items: stretch
  padding-left: 16px
  padding-right: 16px
  gap: 8px
  transition: background-color 0.2s
  min-height: $click-area-size // Increase click area
  font-size: 14px !important
  line-height: 26px !important
  font-weight: 400
  word-wrap: break-word
  overflow-x: hidden
  &.has-children
    padding-right: $click-area-size + 6px
  .c-side-nav__item-text,
  .c-side-nav__item-link
    opacity: .85
  &:hover
    background-color: #fafafa
  &.open .c-side-nav__item-caret
    transform: rotate(90deg)
  &.active
    .c-side-nav__item-text,
    .c-side-nav__item-link
      opacity: 1
    .c-side-nav__item-icon
      color: $link-color !important
      opacity: 1
  &.selected
    &.level-1 ,&.level-2
      .c-side-nav__item-icon,
      .c-side-nav__item-text > span,
      .c-side-nav__item_link > span
        color: $link-color !important
    &.level-2
      font-weight: 600
  &.level-2
    padding-left: 50px
    &.child_selected, &.active
      font-weight: 600
      color: $brand-primary-dd
  &.level-3, &.level-4
    padding-top: 2px
    padding-bottom: 2px
    border-left: 2px solid rgba(31, 51, 79, 0.5)
    &.selected, &.child_selected
      border-left-color: $nav-active-color
    &.selected
      background-color: rgba(0, 96, 240, 0.05)
      .c-side-nav__item-icon,
      .c-side-nav__item-text > span,
      .c-side-nav__item_link > span
        opacity: 1
        font-weight: 700
        color: $link-color !important
  &.level-3
    padding-left: 18px
    border-left: 2px solid rgba(31, 51, 79, 0.5)
    &.selected, &.child_selected
      border-left-color: $nav-active-color
      .c-side-nav__item-icon,
      .c-side-nav__item-text > span,
      .c-side-nav__item_link > span
        opacity: 1
        font-weight: 600
    &.selected
      .c-side-nav__item-icon,
      .c-side-nav__item-text > span,
      .c-side-nav__item_link > span
        color: $link-color !important
    &.child_selected
      .c-side-nav__item-icon,
      .c-side-nav__item-text > span,
      .c-side-nav__item_link > span
        color: $nav-active-color !important
    &.active
      color
  &.level-4
    padding-left: 20px
    &.selected
      font-weight: 600
      border-left-color: $nav-active-color
.level-3
  &.active
    .c-side-nav__section > .level-4
      border-left-color: $nav-active-color !important

.level-1 > .c-side-nav__section > .level-4
  margin-left: 21px
  padding-left: 16px



.c-side-nav__collapse
  transition: max-height 0.2s, visibility 0.2s
  &:not(.open)
    max-height: 0px
    visibility: hidden
    overflow: hidden

.c-side-nav__group
  &.level-2
    margin-left: 50px
    background-clip: padding-box
  &.level-3
    margin-left: 0px
    background-clip: padding-box


.c-side-nav__item-text,
.c-side-nav__item-text_link
  display: flex
  align-items: center
  flex: 1 1 100%

.c-side-nav__item-text_link
  text-decoration: none
  color: inherit
  outline-offset: -2px
