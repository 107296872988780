@import '../helpers'
@import '../tools/mixins/dashboard'

$banner-title-height: $banner-height - 100

.c-hero-banner
  position: relative
  overflow: hidden
  border-bottom: 1px solid $brand-secondary
  height: $banner-height
  &::after
    content: ''
    position: absolute
    z-index: 10
    right: -50px
    top: -25%
    height: 130%
    width: 200px
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,.6) 100%)
    pointer-events: none
    transform: rotate(-15deg)

.c-hero-banner--full-width
  $bg: dash-color(primary-d)
  width: auto
  margin: 0
  height: auto
  position: relative
  border-bottom: 0
  background: $bg
  &::after
    display: none
  figure
    position: relative
    width: 77%
    max-width: 996px
    position: absolute
    right: 0
    &::before
      content: ''
      position: absolute
      top: 0
      bottom: 0
      right: 0
      left: 0
      background: $bg
      background: linear-gradient(270deg, rgba($bg,.35) 10%, rgba($bg,1) 75%)
      @include media-breakpoint-up(lg)
        background: linear-gradient(270deg, rgba($bg,.1) 10%, rgba($bg,1) 75%)
  .c-hero-banner__content
    position: relative
    padding: space(4) space(4) space(1) space(4)
    min-height: 100px
    display: flex
    flex-direction: column
    justify-content: space-between

.c-hero-banner--placeholder
  background: $card-color
  overflow:  hidden
  &::after
    display: none

.c-hero-banner--with-gradient
  position: relative
  border-bottom: 0
  figure
    position: relative
    &::before
      content: ''
      $bg: dash-color(primary)
      position: absolute
      top: 0
      bottom: 0
      left: 0
      width: 100%
      background: rgba($bg, .6)
      @include media-breakpoint-up(md)
        background: linear-gradient(270deg, rgba($bg,.6) 45%, rgba($bg,1) 75%)

.c-hero-banner__title
  margin: auto 0 0 space(3)
  @include flex(1, 1, auto)
  @include font-heading($size: rem($banner-title-height), $color: $border-color-secondary, $lh: line-height(strip-unit($banner-title-height) - 35, strip-unit($banner-title-height)))
  text-transform: uppercase
  white-space: normal
  max-height: 180px
  overflow: hidden
.c-hero-banner__location-name
  display: block
  font-weight: 800
  text-transform: none
  color: dash-color(danger)
  @include media-breakpoint-up(md)
    display: none
.c-hero-banner figure
  $bg: dash-color(primary-d)
  height: 100%
  width: 100%
  margin: 0
  position: absolute
  background: $bg
  display: flex
  justify-content: flex-end

.c-hero-banner__hero-img
  object-fit: cover
  width: 100%
  height: 100%

.c-hero-banner__info
  position: absolute
  top: space(4)
  right: space(4)
  z-index: 10

.c-hero-banner__tooltip-icon.c-tooltip-icon .icon-svg
  svg
    height: 20px
    width: 20px
    fill: $white
    bottom: 0

.c-hero-banner__utility
  color: white
  text-transform: uppercase

.c-hero-banner__content
  $bg: dash-color(primary-d)
  position: absolute
  top: 0
  left: 0
  width: 100%
  display: flex
  flex-direction: column
  padding: space(2) space(4) 0 space(4)
  height: 100%
  z-index: 10
  @include media-breakpoint-up(md)
    padding: space(4)
  .c-hero-banner__text
    margin-top: 0
    color: #FAFBFF
  .c-hero-banner__title
    font-size: 32px
    line-height: 42px
    font-weight: 600
    letter-spacing: 0
    text-transform: none
    margin-left: 0
    color: dash-color(text-light)
    text-shadow: 0px 0px 7px $bg
    @include media-breakpoint-up(md)
      font-size: 40px
      line-height: 50px
  .c-hero-banner__title-sub
    font-size: 24px
    line-height: 32px
    color: $gray-400
    font-weight: 500
    text-shadow: 0px 0px 7px $bg
    margin-bottom: 0
    @include media-breakpoint-up(md)
      font-size: 30px
      line-height: 36px

.c-hero-banner__description
  font-size: rem(15px)
  color: $gray-400
  letter-spacing: 0.03em
  font-weight: 600
  margin-top: space(2)

.c-hero-banner__ctas
  margin-top: space(4)
  text-align: right
  display: flex
  flex-direction: column
  align-items: flex-end
  @include media-breakpoint-down(lg)
    width: 100%
    flex-direction: row
    flex-wrap: wrap
    & > a:first-child
      margin-right: space(4)

.c-hero-banner__cta + .c-hero-banner__cta
  margin-top: space(2)

.c-hero-banner__cta
  @include dashboard-cta
  .fa
    transition: .15s transform ease-in-out
  &[aria-expanded=true]
    .fa
      transform: rotate(180deg)

.c-breadcrumbs--light .c-breadcrumbs__slash
  color: dash-color(link-color-nav)
