@import '../helpers'

@each $name, $color in $brand-colors
  .fill--#{"" + $name}
    fill: $color

.fill--success
  fill: $success

.fill--danger
  fill: $danger

// Based on aligning svg with text:
// https://goo.gl/bA78mP
.icon-svg
  position: relative
  display: inline-flex
  &,
  svg
    height: 20px
    width: 20px

  svg
    display: inline-block
    vertical-align: middle
    position: absolute

@each $size, $value in ( xs: .25, sm: .5, md: 1.5, lg: 3, xl: 5 )
  .icon-svg--#{"" + $size}
    &,
    svg
      height: $font-size-base * $value
      width: $font-size-base * $value
      bottom: 0
