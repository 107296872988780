@import '../helpers'

.c-context-switcher-featured-list
  @include list-unstyled

.c-context-switcher-featured-list__item
  margin-bottom: space(3)

.c-context-switcher-featured-list__link
  background: #f9f9f9
  font-size: 16px
  font-weight: font-weight(semibold)
  transition: .2s ease-in-out
  border: 2px solid transparent
  border-radius: $border-radius
  cursor: pointer
  &:hover,
  &:focus
    box-shadow: 0 2px 17px -5px rgba(0, 0, 0, .2)
    border-radius: 10px
  &.active
    border-color: $brand-secondary
  img
    max-width: 100%
  .card-body
    padding: space(2) space(4)
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    flex: 1 1 auto
    .card-title
      font-size: 20px
      line-height: 26px
      font-weight: font-weight(semibold)


.c-context-switcher-featured-list__title
    flex: 0 1 auto
