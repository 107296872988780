@import '../helpers'

.c-header
  > .navbar,
  > .c-nav-bar,
  > .c-app-alert__content
    padding-left: 20px
    padding-right: 20px
  a
    text-decoration: none

.c-header--main
  border-bottom: 1px solid $gray-200
  position: sticky
  top: 0
  height: $header-height
  z-index: stack-order(top) + 3
  display: flex
  > nav
    flex: 1 1 100%

.c-header--admin
  padding-left: space(4)
  padding-right: space(4)
  border-bottom: 2px solid $brand-secondary

.c-sub-header
  display: flex
  align-items: center
  padding: space(3) space(4)
  border-bottom: 1px solid $brand-secondary
