@import '../../helpers'

.c-dash-btn
  @include button-variant(get-dash-color(link), darken(get-dash-color(link), 8%))
  border-radius: 4px

.c-dash-btn--secondary
  $dash-btn-secondary-border: #EAEAEA
  @include button-variant($dash-btn-secondary-border, darken($dash-btn-secondary-border, 10%), $active-background: get-dash-color(link), $active-border: darken(get-dash-color(link), 8%))

.c-dash-btn--default
  @include button-variant(#1163930D, get-dash-color(link), $hover-background: get-dash-color(link), $hover-border: darken(get-dash-color(link), 8%), $active-background: lighten(get-dash-color(link), 10%), $active-border: lighen(get-dash-color(link), 5%))
  color: get-dash-color(link)
  &:hover,
  &:focus,
  &:active
    color: white
