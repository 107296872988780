@use "sass:math";

// Original source:
// https://github.com/m18ru/preact-range-slider/blob/master/assets/_mixin.scss
// A few custom variables were added to make the slider UI elements more
// flexible -- easier to resize

@mixin range-slider( $prefix: "" )
{
  $range-slider-clr-primary: #2DB7F5 !default;
  $range-slider-clr-secondary: #E9E9E9 !default;
  $range-slider-clr-disabled: #CCCCCC !default;
  $range-slider-clr-obscured: lighten($range-slider-clr-primary, 20%) !default;
  $range-slider-clr-text: #333333 !default;
  $range-slider-clr-dot-bg: white !default;
  $range-slider-clr-handle-bg: white !default;
  $range-slider-clr-tooltip-fg: white !default;
  $range-slider-clr-tooltip-bg: #6C6C6C !default;

  // Custom Variables added
  $range-slider-add-vertical-styles: false !default;
  $range-slider-clr-handle-border: $white !default;
  $range-slider-clr-handle-border-active: $range-slider-clr-primary !default;
  $range-slider-clr-handle-border-width: 2px !default;
  $range-slider-rail-height: 8px !default;
  $range-slider-handle-size: 20px !default;
  $range-slider-show-tooltip: true !default;
  $range-slider-handle-offset-x: math.div($range-slider-handle-size, 2) !default;
  $range-slider-handle-offset-y: math.div($range-slider-handle-size, 2) - math.div($range-slider-rail-height, 2) !default;

  $range-slider-border-radius: 6px !default;

  position: relative;
  z-index: 1;

  box-sizing: border-box;
  width: 100%;
  height: 1$range-slider-rail-height;
  padding: 5px 0;

  font-size: 12px;

  border-radius: $range-slider-border-radius;

  * {
    // Remove tap highlight color for mobile safari
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  > div.#{$prefix}rail {
    position: absolute;

    width: 100%;
    height: $range-slider-rail-height;

    background-color: $range-slider-clr-secondary;

    border-radius: $range-slider-border-radius;
  }

  > div.#{$prefix}track {
    position: absolute;
    left: 0;
    height: $range-slider-rail-height;
    background-color: $range-slider-clr-obscured;
    border-radius: $range-slider-border-radius;
  }

  > div.#{$prefix}handle {
    position: absolute;

    box-sizing: border-box;
    width: $range-slider-handle-size;
    height: $range-slider-handle-size;
    margin-left: $range-slider-handle-offset-x * -1;
    margin-top: $range-slider-handle-offset-y * -1;

    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;

    background-color: $range-slider-clr-handle-bg;

    border: solid $range-slider-clr-handle-border-width $range-slider-clr-handle-border;
    border-radius: 50%;

    transition: .15s;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 5px $range-slider-clr-primary;
    }

    &:active,
    &.#{$prefix}dragging {
      cursor: -webkit-grabbing;
      cursor: grabbing;

      border-color: $range-slider-clr-handle-border;
      box-shadow: 0 0 5px $range-slider-clr-primary;
      background: $range-slider-clr-handle-bg-active;
      transition: none;
    }

    > span.#{$prefix}tip {
      $arrow-half-width: 3px;
      $arrow-height: 6px;
      @if $range-slider-show-tooltip == false {
        display: none !important;
      }
      position: absolute;
      bottom: calc(100% + #{$arrow-height});
      left: 50%;
      z-index: 1;

      box-sizing: border-box;
      min-width: 2$range-slider-rail-height;
      padding: 0 6px;

      line-height: 2$range-slider-rail-height;
      text-align: center;
      white-space: nowrap;

      pointer-events: none;

      color: $range-slider-clr-tooltip-fg;
      background: $range-slider-clr-tooltip-bg;

      border-radius: $range-slider-border-radius;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

      transform: translateX(-50%) scale(0);
      transform-origin: center calc(100% + #{$arrow-height});

      transition-property: transform;
      transition-duration: 0.1s;
      transition-delay: 0.1s;
      transition-timing-function: ease-in;

      &::after {
        $clr-transparent: rgba($range-slider-clr-tooltip-bg, 0);

        position: absolute;
        top: 100%;
        left: 50%;

        width: 0;
        height: 0;
        margin-left: -$arrow-half-width;

        content: "";

        border-width: $arrow-height $arrow-half-width 0;
        border-color: $range-slider-clr-tooltip-bg $clr-transparent $clr-transparent;
        border-style: solid;
      }
    }

    &:hover,
    &:active,
    &.#{$prefix}dragging {
      > span.#{$prefix}tip
      {
        transform: translateX(-50%) scale(1);

        transition-timing-function: ease-out;
      }
    }
  }

  > div.#{$prefix}marks {
    position: absolute;
    top: 18px;
    left: 0;

    width: 100%;

    > span.#{$prefix}text
    {
      position: absolute;

      display: inline-block;

      text-align: center;
      vertical-align: middle;
      white-space: nowrap;

      cursor: default;

      color: lighten($range-slider-clr-text, 30%);

      &.#{$prefix}active
      {
        color: $range-slider-clr-text;
      }
    }
  }

  > div.#{$prefix}steps {
    position: absolute;

    width: 100%;
    height: $range-slider-rail-height;

    > span.#{$prefix}dot
    {
      position: absolute;
      bottom: -2px;

      box-sizing: border-box;
      width: 8px;
      height: 8px;
      margin-left: -$range-slider-rail-height;

      cursor: pointer;

      background-color: $range-slider-clr-dot-bg;

      border: 2px solid $range-slider-clr-secondary;
      border-radius: 50%;

      &.#{$prefix}active
      {
        border-color: $range-slider-clr-obscured;
      }
    }
  }

  &.#{$prefix}disabled
  {
    background-color: $range-slider-clr-secondary;

    > div.#{$prefix}track
    {
      background-color: $range-slider-clr-disabled;
    }

    > div.#{$prefix}handle
    {
      cursor: not-allowed;

      border-color: $range-slider-clr-disabled;
      box-shadow: none;
    }

    > div.#{$prefix}steps
    {
      > span.#{$prefix}dot
      {
        cursor: not-allowed;

        border-color: $range-slider-clr-disabled;
      }
    }
  }

  @if ($range-slider-add-vertical-styles == true) {
    &.#{$prefix}vertical {
      width: 1$range-slider-rail-height;
      height: 100%;
      padding: 0 5px;

      > div.#{$prefix}rail
      {
        width: $range-slider-rail-height;
        height: 100%;
      }

      > div.#{$prefix}track
      {
        left: 5px;
        bottom: 0;

        width: $range-slider-rail-height;
      }

      > div.#{$prefix}handle
      {
        margin-bottom: -7px;
        margin-left: -5px;
      }

      > div.#{$prefix}marks
      {
        top: 0;
        left: 18px;

        height: 100%;
      }

      > div.#{$prefix}steps
      {
        width: $range-slider-rail-height;
        height: 100%;

        > span.#{$prefix}dot
        {
          left: 2px;

          margin-bottom: -$range-slider-rail-height;
        }
      }
    }
  }
}
