@import '../helpers'

h1,
.h1
  @include h1

h2,
.h2
  @include h2

h3,
.h3
  @include h3

h4,
.h4
  @include h4

h5,
.h5
  @include h5

.h1.bordered,
h1.bordered
  padding-bottom: space(2)
  border-bottom: 2px solid $brand-secondary

.h2.bordered,
h2.bordered,
.h3.bordered,
h3.bordered
  padding-bottom: space(2)
  border-bottom: 2px solid $brand-primary

h4.bordered,
.h4.bordered
  padding-bottom: space(1)
  border-bottom: 2px solid $gray-300

.headings__hint-text
  @include font-default(13px, $lh: line-height(15, 13))

@for $i from 1 through 4
  .h#{$i}--muted
    color: $gray-600
