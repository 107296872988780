@import '../helpers'

.c-map-info
  padding: 6px 8px
  background: white
  background: rgba(255,255,255,0.9)
  box-shadow: 0 0 15px rgba(0,0,0,0.2)
  border-radius: 5px

.c-map-info__value-wrapper
  font-family: 'open sans'
  padding: space(2)
  .secondary
    font-weight: normal
    font-style: italic


.c-map-info__date
  display: block

.c-map-info__title
  font-family: 'open sans'
  color: $black
  border-bottom: 1px solid $gray-300
  padding-bottom: space(2)
  margin-bottom: space(2)

.c-map-info__location
  display: block
  margin-bottom: space(1)
  font-weight: bold
  font-size: larger

.c-map-info__value
  font-size: rem(16)
  line-height: 20px
  display: block
  margin-bottom: space(1)

.c-map-info__legend.leaflet-control
  margin-right: 0
  line-height: 18px
  color: #555

.c-map-info__legend--custom-html
  max-height: 300px
  max-width: 300px
  .table th, .table td
    padding: rem(3)

.c-map-info__legend-item
  display: flex
  align-items: center
  margin-bottom: 3px

.c-map-info__legend-label
  white-space: nowrap

.c-map-info__legend-swatch
  width: 18px
  height: 18px
  margin-right: space(2)
  opacity: 1

.c-map-info__value-label
  font-weight: 600
  margin-bottom: space(1)
  display: block

.c-map-info__value-number
  font-size: 16px
  line-height: 1
  font-weight: 700

.c-map-info__values
  font-size: 14px
  line-height: 20px
  .c-map-info__value-container
    margin-bottom: space(3)
    &:last-of-type
      margin-bottom: 0

.c-map-info__title-link
  &,
  *
    text-decoration: none

.c-map-info__link
  margin-top: space(3)
  display: block
  font-weight: 600
  text-align: right
  margin-bottom: space(2)

.c-map-info__value-wrapper
  .secondary
    font-weight: normal
    font-style: italic

.c-map-info__value-wrapper h5
  font-weight: normal
  font-size: 15px
