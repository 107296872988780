@import '../helpers'

.o-static
  align-items: flex-start
  width: 100%
  min-height: 60vh

.o-static__container
  padding: space(4)
  max-width: map-get($grid-breakpoints, xl)

.o-static__hero
  height: 20vh
  max-height: 400px
  background: $brand-primary-l
  width: 100%
  position: relative

.o-static__hero-image
  @include hero-image($position: 50% 0)
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 10
  opacity: .5
