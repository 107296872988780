@use "sass:math"

@import '../../helpers'

.c-delaware-svg
  // margin-right: space(8)

.c-delaware-svg svg
  height: 50vh

.c-delaware-svg--wilmington
  transition: transform .2s ease-in-out
  &.zoomed
    overflow: hidden
    svg
      transform: scale(3.5) translate(28%, 35%)

.c-delaware-svg__value
  @include font-montserrat($size: 28px, $weight: 700, $lh: math.div(34, 30), $color: $card-color)
  text-align: center
  fill: #fff

.c-delaware-svg__state-border
  fill: $white

.c-delaware-svg__state
  fill: $border-color-secondary

.c-delaware-svg__county
  fill: $brand-primary
  stroke: $white
  path
    stroke-linecap: round
    stroke-linejoin: round
    stroke-width: 0

.c-delaware-svg__county--new-castle
  fill: map-get($chart-colors, 1)

.c-delaware-svg__wilmington
  fill: map-get($chart-colors, 1)

.c-delaware-svg__county--kent
  fill: map-get($chart-colors, 2)

.c-delaware-svg__county--sussex
  fill: map-get($chart-colors, 3)
