@import '../helpers'

.highcharts-custom-legend
  margin-bottom: space(3)
  p
    margin: 0
    line-height: 18px
  .name
    font-weight: bold
    margin-top: -2px
    margin-bottom: 5px
  .context
    font-size: 14px
    line-height: 16px
    font-weight: normal

.highcharts__donut-center-content
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  text-align: center
  .value,
  .label
    font-weight: font-weight(semibold)
  .label
    font-size: rem(14)
  .value
    font-size: rem(35)
    line-height: 1
