@import '../../helpers'

.dash-intro-card__illustration
  display: flex
  justify-content: center
  svg
    max-height: 200px
    @include media-breakpoint-up(md)
      max-height: 175px

.dash-intro-card__heading p
  font-weight: 700
  font-size: rem(24px)
  line-height: 32px
  margin-bottom: 16px

.dash-intro-card__copy
  text-align: center
  @include media-breakpoint-up(sm)
    text-align: left
  @include media-breakpoint-between(md, lg)
    text-align: center
  @include media-breakpoint-up(lg)
    text-align: left
  a
    background: #942C1B
    color: white
    &:hover,
    &:focus
      background: $brand-primary
