.c-timeline-content
  position: relative
  left: -100px
  width: 100vw
  // height: 100vh
  // overflow: hidden
  // display: flex
  // flex-wrap: nowrap
  // position: relative

.c-timeline-content__container
  width: 100%
  height: 100vh
  overflow: hidden
  position: relative

.c-timeline-content__section,
.c-timeline-content .c-story-chapter-intro
  flex: 1 1 100%
  min-width: 100%
  height: 100%
  position: absolute
  border: 0
  margin-top: 0
  padding-top: 0
  z-index: 100
