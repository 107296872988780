@import '../helpers'
@import '../tools/mixins/dashboard'

.c-tabbed-map
  position: relative
  border-radius: $border-radius
  width: 100%

.c-tabbed-map__container
  position: relative
  height: $video-height
  width: 100%
  display: flex

.c-tabbed-map__dashboard-container
  position: relative
  width: 100%
  height: 100%
  display: flex

.c-tabbed-map__title
  position: absolute
  right: space(4)
  top: space(4)
  padding: space(1)
  @include data-metric($color: $body-color)
  z-index: 1000
  background: rgba($card-color, .75)

.c-tabbed-map__details
  flex: 1 1 35%
  flex-direction: column
  display: flex
  max-width: 35%
  border-right: 2px solid $card-header-bg-color

.c-tabbed-map__map-wrapper
  flex: 1 1 65%
  max-width: 65%
  position: relative

.c-tabbed-map__map
  height: 100%
  width: 100%
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 1
  visibility: hidden
  &.active
    visibility: visible

.c-tabbed-map__map > div
  height: 100%
  width: 100%

.c-tabbed-map__details-wrapper
  @include flex(1, 1, auto)
  overflow: auto
  @include scrollbar

.c-tabbed-map__detail-list-item
  border-bottom: 1px solid $border-color-secondary

.c-tabbed-map__detail-list-link
  padding: space(2) space(4)
  padding-right: space(1)
  display: flex
  color: $link-color
  align-items: center
  text-decoration: none
  appearance: none
  border: none
  background: none
  border: 1px solid transparent
  min-width: 100%
  display: flex
  align-items: center
  &::after
    @include font-icon
    content: '\f105'
    margin-left: auto
    font-size: 22px
    min-width: 50px
    text-align: center
  &:focus,
  &:hover
    text-decoration: none
    background: $card-header-bg-color
    color: $brand-secondary
    background: $gray-090
    font-weight: 700
    &::after
      content: '\f138'
  &:focus
    outline: 1px solid $brand-primary
  &.active
    pointer-events: none
    background: $card-header-bg-color
    font-weight: 800
    &::after
      content: '\f13a'

.c-tabbed-map__detail-list-details
  border-top: 1px solid $border-color-secondary
  background: $card-color

.c-tabbed-map__detail-list-sub-item
  padding: space(2) space(4)
  border-top: 1px solid transparent
  border-bottom: 1px solid transparent
  &:hover
    border-color: $card-header-bg-color
    background-color: $white

.c-tabbed-map__detail-list-details h4
  font-weight: normal
  font-size: rem(16px)
  color: $body-color

.c-tabbed-map__error
  @include dashboard-card
  position: absolute
  left: 0
  top: 0
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  color: $black
