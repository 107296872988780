@import '../../helpers'

.o-story-container
  @include flex(1, 1, auto)
  position: relative
  z-index: 10
  background: transparent

.o-story-container--bordered
  border-bottom: 3px solid $white

.o-story-container--padded
  @include story-content-padding
  @include media-breakpoint-up(lg)
    padding-right: space(8)

.o-story-container--padded-y
  padding-top: space(4)
  padding-bottom: space(4)
  @include media-breakpoint-up(md)
    padding-top: space(8)
    padding-bottom: space(8)

.o-story-container--fill-vertical
  @include flex(1, 1, auto)

@each $index, $color in $chart-colors
  .o-story-container--#{$index}
    background-color: $color

.o-story-container--light
  background-color: transparent

.o-story-container--flex
  display: flex
  align-items: center
  flex-wrap: wrap

.o-story-container--centered
  display: flex
  justify-content: center

.o-story-container--centered
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.o-story-container--overlap
  margin-top: space(4)
  @include media-breakpoint-up(md)
    margin-top: -75px

.o-story-container--gradient-top
  background: linear-gradient(to top, rgba($white,.5) 80%,rgba($white,0) 100%)
