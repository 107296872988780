@import '../helpers'

a.no-hover:hover
  color: $brand-primary !important
  text-decoration: none !important

a.decorated
  text-decoration: underline !important
  &:hover
    text-decoration: none !important

.link
  font-weight: font-weight(semibold)
  &,
  ~ i
    color: $brand-primary !important
    text-decoration: none !important
