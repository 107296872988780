.btn.btn-primary a,
.btn.btn-primary a:hover,
.btn.btn-primary a:focus,
.btn.btn-primary a:active
  color: #fff
  text-decoration: none

.btn-unstyled
  background: none
    color: inherit
    border: none
    padding: 0
    font: inherit
    cursor: pointer
    outline: inherit