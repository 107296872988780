@import '../helpers'

.c-image
  position: relative
  margin: 0
  background: $brand-primary-dd
  flex: 1 1 100%

.c-image__img
  height: 100%
  width: 100%
  object-fit: cover

.c-image__source
  position: absolute
  right: space(2)
  bottom: space(2)
  background: rgba(0,0,0, .5)
  color: rgba(white, .75)
  transition: .25s ease-in-out
  padding: 3px
  &:hover,
  &:focus
    background: rgba(0,0,0, .95)
    color: white

.c-image__img--veiled
  opacity: .85
  transition: opacity .15s ease-in-out
