@import '../helpers'

.o-search
  .c-context-switcher__wrapper
    position: static
    flex: 1 1 auto
    height: 100%
    width: auto
  .c-context-switcher__content
    width: 100%
    margin: 0
    box-shadow: none
  .c-context-switcher
    flex: 1 1 100%
  .c-context-switcher__content
    max-width: none
    max-height: none
  .c-context-switcher__area-container
    margin-top: 0
    padding: space(4) 0
