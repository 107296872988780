@use "sass:math"

@import '../helpers'

.leaflet-popup-content-wrapper
  @include tooltip
  padding: 0
  font-family: $font-family-sans-serif
  font-size: 16px

.leaflet-popup-content
  border-radius: 0
  padding: 0
  margin: 0
  width: auto
  min-width: 200px
  p
    margin: 0 0 space(1) 0

.leaflet-popup-tip-container
  transform: translate(0, -1px)

.leaflet-popup-tip
  box-shadow: 0 2px 8px rgba($black, .2)
  border-right: 1px solid $brand-primary
  border-bottom: 1px solid $brand-primary
  background: $card-color

.leaflet-popup-close-button
  top: space(2)

.leaflet-popup-pane
  .c-tooltip-chart__value
    font-size: 22px
    line-height: math.div(26, 22)
  .c-tooltip-chart__location
    margin-bottom: 5px

.leaflet-control-container .c-chart-legend
  position: absolute
  top: space(2)
  right: space(2)
  background: $white
  paadding: space(2)
  display: flex
  flex-direction: column
  border: 0
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15)
  border-radius: 2px
  .date
    font-weight: bold
    margin-bottom: space(2)
.leaflet-control-container .c-chart-legend__item
  margin: 0
  flex-direction: row
  margin-bottom: 2px
  opacity: 1
  font-size: 13px
  white-space: nowrap
  pointer-events: none

.leaflet-bottom.leaflet-left
  bottom: space(2)
  left: space(2)
  margin: 0

.leaflet-bottom.leaflet-right
  bottom: space(2)
  right: space(2)
  margin: 0

.leaflet-top.leaflet-right
  max-width: 65%
  @include media-breakpoint-up(md)
    max-width: 50%

.leaflet-top,
.leaflet-bottom
  z-index: stack-order(map-controls)

.leaflet-container
  &:focus,
  * &:focus
    outline: 3px solid $link-color !important
  &:focus
    outline-offset: 4px !important
