@import '../helpers'

.c-metric-table
  width: 100%
  @include media-breakpoint-down($nav-switch-breakpoint)
    &,
    tbody
      display: flex
      width: 100%
      flex-direction: column

.c-metric-table__row
  &:first-child td
    padding-top: 0
  &:last-child td
    border: 0
    padding-bottom: 0
  td
    padding-top: space(2)
    padding-bottom: space(2)
    border-bottom: 1px solid $border-color-secondary
  @include media-breakpoint-down($nav-switch-breakpoint)
    display: flex
    flex-direction: column
    margin-bottom: space(2)

.c-metric-table__row td
  @include flex(1, 1, 50%)
  &:first-child
    @include flex(1, 1, 100%)
    min-width: 100%
  @include media-breakpoint-down($nav-switch-breakpoint)
    padding: space(1) 0
    &:not(:last-child)
      border: 0
    &:last-child
      padding-bottom: space(3)
    .c-metric-table__viz
      max-width: none

.c-metric-table__values
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding-left: space(4)
    padding-right: space(4)

.c-metric-table__label
  display: flex
  align-items: center

.c-metric-table__label > span
  padding-right: space(4)
  display: flex
  align-items: center
  &[class^='legend']
    &::before
      content: ''
      flex: 1 0 auto
      height: 12px
      width: 12px
      border-radius: 2px
      margin-right: space(1)
  @each $name, $color in $chart-colors
    &.legend--#{$name}
      &::before
        background: $color

.c-metric-table__value
  @include flex(0, 0, auto)
  display: flex

.c-metric-table__value > div
  margin-left: auto
  @include media-breakpoint-down($nav-switch-breakpoint)
    margin-left: 0

.c-metric-table__value.c-metric-table__value-no-separator span:nth-of-type(2)
  background: none

.c-metric-table__row.c-metric-table__header-row th:last-of-type
  text-align: center
  padding-left: 50px // ack magic number
  @include font-default(14px)

.c-metric-table__row .c-metric-table__value
  @include media-breakpoint-down($nav-switch-breakpoint)
    margin-left: auto

.c-metric-table__viz
  display: flex
  width: 100%

.c-metric-table .c-metric-table__viz
  @include media-breakpoint-up($nav-switch-breakpoint)
    margin-left: auto
    max-width: 250px
    min-width: 175px
