@import '../../helpers'

.c-donut-with-detail
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-bottom: space(6)
  max-width: 100%
  @include media-breakpoint-up(lg)
    justify-content: flex-start
    flex-direction: row

.c-donut-with-detail__chart
  height: 25vh
  min-height: 400px
  min-width: 400px
  position: relative
  z-index: 10
  margin-bottom: space(6)
  transform: scale(.8)
  max-width: 100%
  @include media-breakpoint-up(md)
    transform: scale(1)

.c-donut-with-detail__table
  @include flex(1, 1, 100%)
  max-width: 100%
  @include media-breakpoint-up(lg)
    @include flex(0, 0, 40%)
    max-width: 40%
  @include media-breakpoint-up(xl)
    @include flex(0, 0, 60%)
    max-width: 60%

.c-donut-with-detail__table table
  border: $border-color-secondary
  border-top: $border-color-secondary 1px solid
  tr th,
  tr td
    padding: space(2) space(4)
    border-bottom: $border-color-secondary 1px solid
    background: $white
    &:first-child
      border-left: $border-color-secondary 1px solid
    &:last-child
      border-right: $border-color-secondary 1px solid
  tr td
    &:first-child
      text-align: right

.c-donut-with-detail__table table
  .c-donut-with-detail__table-call-out td
    background: $border-color-secondary
    font-weight: 700

.c-donut-with-detail__table-title
  display: block

.c-donut-with-detail__description
  padding: space(4)
  color: $white
  margin-top: -25px
  padding-top: 50px
  text-align: center
  max-width: calc(100% - #{space(4)} - #{space(4)} )
  @include media-breakpoint-up(lg)
    margin-top: 0
    margin-left: -75px
    padding: space(6)
    padding-left: 150px
    text-align: left

@each $name, $color in $chart-colors
  .c-donut-with-detail__description--#{$name}
    background-color: $color
