@import '../helpers'

$color-primary: #245194
$color-primary-d: #1f334f
$text-color-body: #1A263C
$text-color-primary: #942c1b
$text-color-secondary: #484848
$font-family: Open Sans

.l-homepage
  section
    margin-bottom: 75px
    font-family: $font-family
  section h2
    margin-bottom: 15px
    font-weight: 600
    font-size: 20px
    line-height: 27px
    color: $text-color-body
    text-transform: initial
    font-family: $font-family
  .hero
    background: #fafafa
  .heading
    text-align: center
  .heading h1
    font-size: 48px
    line-height: 52px
    font-weight: 600
    text-transform: initial
    font-family: $font-family
    color: $text-color-primary
    margin-bottom: 15px
    span
      display: block
      margin-top: space(3)
    @include media-breakpoint-up(md)
      font-size: 64px
      line-height: 72px
  .heading span
    font-weight: 500
    font-size: 24px
    line-height: 32px
    font-family: $font-family
    color: $text-color-body

  .card
    h3,
    .h2,
    .card-title,
    .card-description
      font-family: $font-family
      text-transform: initial
  .card--dark
    overflow: hidden
    background: $color-primary-d
    &:hover,
    &:focus
      background: $color-primary
      .card-title
        letter-spacing: .03em
    .card-title
      font-size: 20px
      line-height: 24px
      font-weight: normal
      letter-spacing: .025em
    .card-body
      color: white
  .coming-soon
    background: rgba($color-primary-d,.5)
    position: absolute
    top: 50%
    transform: translateY(-50%)
    width: 100%
    text-align: center
    color: white
    font-size: 24px
    line-height: 1

  .card-image--sized
    @include media-breakpoint-down(lg)
      height: 250px
