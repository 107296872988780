@import "../helpers"

.c-swatch
  @include swatch(rem(15px))
  display: inline-block

.c-swatch--positive
  background: $metric-positive

.c-swatch--negative
  background: $metric-negative

.c-swatch--primary
  background: $metric-primary

.c-swatch--comparison
  background: $metric-comparison

.c-swatch--lg
  height: rem(25px)
  width: rem(25px)
