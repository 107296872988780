@use "sass:math"

@import '../../helpers'

$wayfinder-point-size: 15px

@mixin wayfinder-point
  display: flex
  align-items: center
  justify-content: center
  margin-top: (-#{$wayfinder-point-size})
  &::after
    content: ''
    display: block // IE11 fix
    height: 3px
    background: $brand-primary
    width: $wayfinder-point-size

@mixin wayfinder-point-active
  .c-wayfinder__point-label
    background: $brand-primary
    color: $white
    transform: scale(1.5)
  &,
  .c-wayfinder__point-label
    text-decoration: none

.c-wayfinder
  display: flex
  height: 100vh
  background: $card-bg

.c-wayfinder__title,
.c-wayfinder__action-call
  position: absolute
  width: 50vh
  top: space(4)
  left: space(5)
  transform-origin: 0 0
  transform: rotate(90deg)
  @include font-montserrat($size: 18px, $weight: 600, $lh: 1, $color: $brand-secondary)
  text-transform: uppercase

.c-wayfinder__track
  display: flex
  @include flex(0, 1, auto)
  margin-left: auto
  position: relative
  &::after
    content: ''
    display: block // IE11 fix
    background: $brand-primary
    width: 10px
    border-right: 2px solid $white

.c-wayfinder__guide
  @include wayfinder-point
  border: 0
  bakground: $brand-primary
  position: absolute
  left: 0
  top: space(4)
  z-index: 1
  pointer-events: none
  &::after
    background: $brand-secondary

.c-wayfinder__guide .c-wayfinder__point
  &::after
    width: auto

.c-wayfinder__guide .c-wayfinder__point-label
  padding-top: 2px
  background: $brand-secondary
  &::before
    color: $white
    content: '\f124'
    display: block //IE11 fix
    @include font-icon
    font-size: 12px

.c-wayfinder__point-wrapper
  display: flex
  @include flex(1, 1, auto)
  align-items: flex-start
  position: relative
  &.active
    .c-wayfinder__guide
      opacity: 1
    > .c-wayfinder__point
      @include wayfinder-point-active

.c-wayfinder__point-wrapper .c-wayfinder__guide
  position: absolute
  top: $wayfinder-point-size * 0.5
  right: 0
  justify-content: flex-end
  margin-top: 0
  transform: translateY(-100%)
  transition: top .01s ease-in-out, opacity .1s ease-in-out
  opacity: 0

.c-wayfinder__guide .c-wayfinder__point
  border: 0
  margin: 0

.c-wayfinder__points
  display: flex
  flex-direction: column
  padding-top: space(4)
  padding-bottom: space(4)

.c-wayfinder__point
  @include wayfinder-point
  z-index: 10
  text-decoration: none
  &.active,
  &:focus,
  &:hover
    @include wayfinder-point-active

.c-wayfinder__point-label
  @include font-montserrat($size: 15px, $weight: 600, $lh: math.div(18, 15), $color: $gray-800)
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  text-align: center
  border-radius: 50%
  height: 35px
  width: 35px
  background: $gray-300
  border: 2px solid $white
  transition: .15s ease-in-out

.c-wayfinder__action-call
  top: 0
  width: 100vh
  display: flex
  justify-content: flex-end
  padding-right: space(4)
  a
    text-decoration: none

.c-wayfinder__action-call i
  transform: rotate(-90deg)
  transform-origin: center center
