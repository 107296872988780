@use "sass:math"

@import '../../helpers'
@import '../../tools/mixins/dashboard'

$dashboard-block-border-color: dash-color(border-color)

.c-dashboard-block
  @include flex(1, 1, 100%)
  margin-bottom: space(4)
  max-width: 100%
  @include media-breakpoint-up(lg)
    margin-bottom: space(6)

.c-dashboard-block__title
  @include font-heading(26px, $weight: 600, $lh: 36px, $color: dash-color(dark-text))
  margin-right: space(4)

.c-dashboard-block__content p
  font-size: rem(16px)
  line-height: math.div(26, 15)
  color: $card-sidenote-color

.c-dashboard-block__table
  margin-bottom: 0
  height: 100%

.c-dashboard-block__row
  margin-bottom: space(5)

.c-dashboard-block__card-wrapper
  display: flex

.c-dashboard-block__table--secondary table tbody tr
  td
    font-weight: normal

.c-dashboard-block__table table thead tr,
.c-dashboard-block__table table tbody tr
  // Original implementation didn't account for
  // a table wrapped in a block it will take
  // too long to fix it at this point so
  // alas `!important`
  td,
  th
    border-left: 0 !important
    border-right: 0 !important
  &:last-child
    td
      border-bottom: 0 !important
.c-dashboard-block__table table thead tr
  th
    border-top: 0 !important

.c-dashboard-block__table thead tr th,
.c-dashboard-block__table tbody tr td
  padding: space(2)
  border-bottom: 1px solid dash-color(primary-l)
  vertical-align: middle
  font-size: rem(16px)
  font-weight: 600
  &:first-of-type
    border-left: 1px solid $dashboard-block-border-color
  &:last-of-type
    border-right: 1px solid $dashboard-block-border-color

.c-dashboard-block__table thead tr th
  @include h4
  padding-top:  space(2)
  padding-bottom:  space(2)
  border-top: 1px solid $dashboard-block-border-color
  border-bottom-width: 2px

.c-dashboard-block__table tbody tr
  &:last-of-type
    td
      border-bottom: 1px solid $dashboard-block-border-color
