@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-dash-key-metric
  // max-width: 250px

.c-dash-key-metric__value,
.c-dash-key-metric__title
  font-weight: font-weight(bold)
  color: get-dash-color(h1)
  letter-spacing: 0

.c-dash-key-metric__value
  line-height: 68px
  font-size: rem(50px)

.c-dash-key-metric__title
  font-size: rem($lg-font-size)
  line-height: 24px
  letter-spacing: 0

.c-dash-key-metric__date,
.c-dash-key-metric__note,
.c-dash-key-metric__tooltip
  color: #838383
  font-size: rem($sm-font-size)
  letter-spacing: 0

.c-dash-key-metric__tooltip
  text-decoration: underline
  display: inline-block

.c-dash-key-metric__link-lg
  color: darken($delaware-blue, 10%)
  font-size: 1.125rem

  // Variant: `highlighted`
// Color of value is blue
.c-dash-key-metric--highlighted
  background: #f9fbfe
  .c-dash-key-metric__value
    color: get-dash-color(link)

// Variant: `primary`
// Text is larger and order of title/value is swapped (handled in viewcomponent)
.c-dash-key-metric--primary
  .c-dash-key-metric__value
    font-size: rem(70px)
    margin-bottom: space(1)
  .c-dash-key-metric__title
    font-size: rem(22px)
    line-height: 18px
    margin-bottom: space(4)

// Variant: `centered`
// Text/content is centered
.c-dash-key-metric--centered
  text-align: center

// Variant: `padded`
// Text/content is centered
.c-dash-key-metric--padded
  padding: space(6)

// Variant: `rounded`
// Text/content is centered
.c-dash-key-metric--rounded
  border-radius: 10px

// Variant: `flex`
// flex: 1
.c-dash-key-metric--flex
  flex: 1
  display: flex
  flex-direction: column
  justify-content: center

// Variant: `level-1`
// Text is largest
.c-dash-key-metric--level-1
  .c-dash-key-metric__value
    font-size: rem(85px)
    line-height: 1
    margin-bottom: space(2)
  .c-dash-key-metric__title
    font-size: rem(22px)
    line-height: 18px
    margin-bottom: space(4)
  [data-bs-toggle="tooltip"].far
    font-size: rem(44px)

// Variant: `level-2`
// Text is medium
.c-dash-key-metric--level-2
  .c-dash-key-metric__value
    font-size: rem(44px)
    line-height: 1
    margin-bottom: space(2)
  .c-dash-key-metric__title
    font-size: rem(16px)
    line-height: 18px

// Variant: `level-3`
// Text is smallest
.c-dash-key-metric--level-3
  .c-dash-key-metric__value
    font-size: rem(25px)
    line-height: 1
    margin-bottom: space(1)
  .c-dash-key-metric__title
    font-size: rem(16px)
    line-height: 18px
