// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Variables
// ---------------------------------------------------------------------------
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and
// $modal-content-box-shadow-xs.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Typography
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Flag to show default body text as light or normal
$use-light-font: false !default;
$font-size-default: 16px !default;
$font-body-size: 15px !default;
$max-paragraph-width: 800px !default;
$font-weights: (
  light    : 300,
  normal   : 400,
  default  : 400,
  semibold : 600,
  bold     : 700,
  extrabold: 800
) !default;

// UI
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$header-height: 60px !default;
$utility-header-height: 50px !default;
$footer-height: 70px !default;
$border-radius-default: 3px;
$max-container-width: 1200px;

$side-nav-width: 300px;
$nav-switch-breakpoint: md2;
$nav-switch-breakpoint-up: md2;
$page-nav-breakpoint: md;

// Padding
$topic-padding-desktop: 15px;
$topic-padding-mobile: space(2);

$transition-duration: .35s !default;

$heading-min-height: 55px;

$input-min-height: 40px;

// UI - Charts
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$chart-line-width: 2px !default;
$no-data-color: rgb(97, 106, 111);

// Utilities
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$directions: (
 't': top,
 'l': left,
 'b': bottom,
 'r': right
) !default;

// Range slider
$range-slider-class-name: 'c-range-slider';
$range-slider-show-tooltip: false;
$range-slider-rail-height: 10px;
$range-slider-handle-size: 40px;
$range-slider-border-radius: 3px;

$screen-lg: 1200px;

// Horizontal Stacked Bars
$stacked-bar-height: 7px;
$stacked-bar-selected-size: $stacked-bar-height * 3;
$stacked-bar-border-radius: 2px;
$stacked-bar-selected-size-offset: 8px;

// Percent Bar
$percent-bar-height: 20px;

// Z-Index stack order
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$stack-order: (
  sub: -1,
  page: 0,
  btm: 10,
  mid: 100,
  map-controls: 900,
  top: 1000,
  modal: 10000,
  cosmos: 100000,
  tooltip: 100010
);

// Banner
$banner-height: 225px;
$banner-height-lg: 325px;

$default-scrollbar-width: 10px;


// Video
$video-height: 650px;


// Stories
$story-wayfinder-width: 100px;
$story-content-padding: space(8);
$story-content-padding-negative: (-#{space(8)});
$story-max-width: 1250px;
$story-chart-height: 600px;

// Alerts
$alert-type-icons: (
  warning: '\f071',
);

// Page navbar
$page-navbar-height: 50px;
$page-navbar-nav-max-width: 1200px;

// Column
$default-col-count: 3;
$col-counts: (
  counties: $default-col-count,
  cities: $default-col-count,
  zip-codes: $default-col-count + 1,
  census-tract: $default-col-count + 1,
  neighborhood: $default-col-count,
);

// Basic font-sizes
$sm-font-size: 14px;
$sm-line-height: $sm-font-size * 1.25;
$sm-line-height-mobile: $sm-font-size * 2;
$md-font-size: $sm-font-size + 2;
$md-font-size-mobile: $sm-font-size;
$md-line-height-mobile: $sm-font-size * 2;
$md-font-size: $sm-font-size + 2;
$md-line-height: $md-font-size * 1.75;
$lg-font-size: $md-font-size + 2;
$lg-font-size-mobile: $md-font-size;
$h1-font-size: 30px;
$h1-font-size-mobile: 24px;

// Dashboard
$dash-card-gutter: 20px
