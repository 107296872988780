@import '../helpers'
@import '../tools/mixins/dashboard'

body
  display: flex
  flex-direction: column
  min-height: 100vh
  min-height: -webkit-fill-available
  width: 100%
  font-size: $font-size-default
  &::after
    content: ''
    z-index: stack-order(modal) - 1
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    @include transition($prop: background)
    pointer-events: none
    background: rgba($black, 0)
    @include media-breakpoint-up($nav-switch-breakpoint)
      display: none
  &.l-dashboard
    @include dasboard-modal
  &.l-embed,
  &.l-dashboard
    @include dashboard-tooltip
  &.l-embed
    background: white
  &.loading
    pointer-events: none

body.with-veil
  overflow: hidden
  &::after
    content: ''
    display: block
    background: rgba($black, .7)
    @include media-breakpoint-up($nav-switch-breakpoint)
      display: none

body.with-mobile-nav
  @include media-breakpoint-down(md2)
    &::after
      display: none
    .c-header--main
      transform: translateX(-100vw)
