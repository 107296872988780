@use "sass:math"

@import '../helpers'

.c-banner
  position: relative
  overflow: hidden
  display: flex

.c-banner--full-bleed
  height: 100vh
  width: 100%

.c-banner__image,
.c-banner__image-filter
  height: 100%
  width: 100%
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

.c-banner__image
  z-index: 1
  object-fit: cover
  background: $black

.c-banner__image-filter
  opacity: .5
  background: $brand-secondary
  z-index: 2

.c-banner__content,
.c-banner__titles
  display: flex
  flex-direction: column
  align-items: center

.c-banner__content
  z-index: 10
  position: relative
  margin-top: 5rem
  flex: 1 1 auto

.c-banner__titles
  margin-bottom: space(8)
  position: relative

.c-banner__title
  margin-bottom: space(2)
  text-align: center
  position: relative
  z-index: 10

.c-banner__title span
  @include font-montserrat($size: 100px, $weight: 600, $lh: 1, $color: $card-color)
  text-transform: uppercase
  display: block
  @include media-breakpoint-up(md)
    font-size: 150px
  &:nth-child(1)
    font-weight: 100
    font-size: 50px
    @include media-breakpoint-up(md)
      font-size: 100px

.c-banner__sub-title
  @include font-montserrat($size: 20px, $weight: 300, $lh: (24*0.05), $color: $card-color)
  flex: 0 0 auto
  margin: space(2) space(6)
  text-align: center
  border-top: 2px solid $card-color
  padding-top: space(4)
  &::before
    content: ''
    width: 100%
    height: 1px
  @include media-breakpoint-up(md)
    font-size: 30px

.c-banner__credit
  color: $white
  text-align: center
  margin-top: auto
  margin-bottom: space(6)
  padding: space(4)
  &::empty
    display: none
  @include media-breakpoint-up(md)
    max-width: math.div($story-max-width, 1.25)
    padding: space(4) space(6)

.c-banner__title-watermark
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  height: 120%
  opacity: .2
  z-index: -1

.c-banner__action-call
  @include font-montserrat($size: 20px, $weight: 400, $lh: 1)
  position: relative
  background: $card-color
  border--top-left-radius: 3px
  border--top-right-radius: 3px
  padding: space(4) 100px space(3) 100px
  box-shadow: 0 15px 35px -10px rgba(0, 0, 0, 0.2)
  text-align: center
  margin: 0 space(4) 0 space(4)
  transition: .2s opacity ease-in-out
  opacity: 1
  &::after
    @include font-icon
    content: '\f103'
    position: absolute
    background: $card-color
    min-height: 50px
    min-width: 50px
    border-radius: 50%
    color: $brand-primary
    top: 0
    left: 50%
    transform: translate(-50%, -50%)
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    animation-name: tease
    animation-timing-function: ease-in-out
    animation-duration: 2s
    animation-iteration-count: infinite

@keyframes tease
  0%
    padding-top: 0
  50%
    padding-top: 10px
  100%
    padding-top: 0
