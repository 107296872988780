@use "sass:math"

@import '../../helpers'

$spacer-height: 600px

.c-story-spacer
  min-height: 100vh
  position: relative
  @include media-breakpoint-up(md)
    min-height: $spacer-height

.c-story-spacer--short
  min-height: 100vh
  @include media-breakpoint-up(md)
    min-height: math.div($spacer-height, 1.5)

.c-story-spacer--chart
  min-height: 100vh
  @include media-breakpoint-up(md)
    min-height: calc(#{$story-chart-height} + 100px)
