@import '../helpers'

.c-viz-metric
  margin-bottom: space(5)
  padding-left: space(3)
  padding-right: space(3)
  width: 100%
  @include media-breakpoint-up(md)
    width: 50%
  @include media-breakpoint-up(xl)
    width: 33.3333333333333333333%

.c-viz-metric__top-level
  display: flex
  align-items: flex-end
  margin-bottom: space(2)

.c-viz-metric__heading
  margin-right: space(4)

.c-viz-metric__title
  @include font-bold(16px, $body-color, $lh: line-height(19, 16))

.c-viz-metric__date
  @include font-light(14px, $body-color, $lh: line-height(16, 14))
  margin-bottom: space(1)

.c-viz-metric__value-wrapper
  margin-left: auto

.c-viz-metric__value
  @include data-metric
  display: inline

.c-viz-metric__unit
  @include font-semibold(13px, $body-color, $lh: line-height(15, 13))
  display: inline

.c-viz-metric__rule
  border: 1px solid $gray-200
  width: 100%
  margin-bottom: space(1)

.c-viz-metric__btm-level
  display: flex

.c-viz-metric__limit
  @include font-semibold(13px, $brand-danger, $lh: line-height(15, 13))
  margin-left: auto
.c-viz-metric__limit-label
  @include font-light(12px, $body-color, $lh: line-height(14, 12))
  text-transform: uppercase
