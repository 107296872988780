@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-dashboard-animation
  @include dashboard-card
  flex: 1 1 auto
  .c-video,
  .o-map__title,
  .c-video__wrapper,
  .c-map-legend,
  .c-topic-note
    background: map-get($he-colors, card-bg)
    border: 0


  .c-video__load-error-message
    background: map-get($he-colors, card-bg)

  .o-map__title
    @include dashboard-card-title
    padding-bottom: 0

  .c-animation-controls__date:not([class*='btn']),
  .c-animation-controls__rate:not([class*='btn'])
    background: dash-color(canvas-bg)
    &:hover,
    &:focus,
    &.active
      background: dash-color(link-color-primary)
      color: $white
    .active
      color: $white

  .c-range-slider > div.c-range-slider__handle
    &,
    &:hover,
    &:focus
      background-color: dash-color(link-color-primary)

  .c-video__play
    .c-video__play-icon
      color: dash-color(link-color-primary)
    &:hover
      .c-video__play-icon
        color: lighten(dash-color(link-color-primary), 5%)

  .c-range-slider > div.c-range-slider__track
    background-color: lighten(dash-color(link-color-primary), 5%)

// Expand animation to fill container wiht negative margin
// we should refactor this such that the content of the card header and body are controlled
// independently and can be overridden in the component partial
.tab-content .c-dashboard-animation
  margin: - space(4)
  box-shadow: none
