@use "sass:math"

@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-dashboard-section
  @include flex(1, 1, 100%)
  margin-bottom: space(4)
  max-width: 100%

.c-dashboard-section__title
  @include font-heading(34px, $weight: 800, $lh: 42px, $color: dash-color(dark-text))
  margin-right: space(4)
  margin-bottom: 0

.c-dashboard-section__title ~ p
  margin-bottom: space(2)

.c-dashboard-section__header
  margin-bottom: space(5)

.c-dashboard-section__header a
  font-size: 16px
  line-height: 22px
  font-weight: bold

.c-dashboard-section__header p
  margin: 0

.c-dashboard-section__descriptions p,
.c-dashboard-section__intro
  font-size: rem(18px)
  line-height: math.div(26, 18)
  max-width: $max-container-width
.c-dashboard-section__intro
  &:last-child
    margin-bottom: 0

.c-dashboard-section__row
  display: flex
  flex-wrap: wrap
