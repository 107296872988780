@import '../../helpers'

.c-story-chart
  @include story-content-padding
  @include story-content-padding($direction: right)
  padding-top: space(6)
  padding-bottom: space(8)
  display: flex
  flex-wrap: wrap
  margin: 0
  position: static
  min-height: none
  @include media-breakpoint-down(sm)
    padding-top: space(10)
  @include media-breakpoint-down(md)
    padding-top: space(4)
    padding-bottom: space(4)
    padding-left: 0
    padding-right: 0

.c-story-chart--pull-left
  @include media-breakpoint-up(lg)
    margin-left: $story-content-padding-negative
    padding-left: $story-content-padding
    background: $white

.c-story-chart .c-chart
  background-color: transparent
  min-height: none

.c-story-chart--row .chart
  @include flex(1, 1, 100%)
  min-width: 100%
  margin: 0
  padding: 0
  height: 50vh
  @include media-breakpoint-up(sm)
    height: auto
    min-height: 600px
  @include media-breakpoint-up(md)
    @include flex(1, 1, 75%)
    max-width: 75%
    min-width: auto

.c-story-chart .c-chart-legend
  padding-left: 0 !important
  padding-top: 0
  border-top: 0
  display: block
  flex-wrap: wrap
  justify-content: center
  // @include media-breakpoint-down(sm)
  //   display: none
  @include media-breakpoint-down(sm)
    margin: 0
    .c-chart-legend__item
      flex-direction: row
      margin-bottom: 3px
    .c-chart-legend__visual
      margin: 0 space(2) 0 0
      &::after
        display: none
    .c-chart-legend__label
      font-size: 14px
      line-height: 16px
      padding-top: 18px

.c-story-chart--row .c-chart-legend
  @include flex(1, 1, 100%)
  min-width: 100%
  margin-top: -5vh
  @include media-breakpoint-up(md)
    margin-top: 0
    @include flex(1, 1, 25%)
    max-width: 25%
    min-width: auto

.c-story-chart .c-chart__title
  padding-left: space(4)
  min-width: 100%
  @include media-breakpoint-up(md)
    padding-left: 0
