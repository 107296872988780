@import '../helpers'

$alert-min-height: 65px

.c-topic-alert
  position: absolute
  z-index: 11
  bottom: 0
  left: 0
  right: 0
  border-bottom: 2px solid transparent
  display: flex
  min-height: $alert-min-height
  &:hover,
  &:focus
    background: $brand-secondary
    text-decoration: none !important
    .c-topic-alert__icon
      border-color: $white

.c-topic-alert__icon
  display: flex
  align-items: center
  justify-content: center
  margin-right: space(4)
  min-width: $alert-min-height
  color: $white
  border-right: 2px solid transparent
  &::before
    @include font-icon($size: rem(32px))
    content: ''

.c-topic-alert__text
  padding: space(2) 0

.c-topic-alert__title
  @include h4

.c-topic-alert,
.c-topic-alert__title,
.c-topic-alert__description,
.c-topic-alert__cta
  text-decoration: none

.c-topic-alert__description
  color: $body-color

.c-topic-alert__cta
  margin-left: space(6)
  position: relative
  z-index: 12
  bottom: 0

.c-topic-alert__cta-overflow
  position: absolute
  bottom: 0
  border: 2px solid $white
  border-bottom: 0


@each $name, $attrs in $alert-type-colors
  $type-color-primary: map-get($attrs, primary)
  $type-color-secondary: map-get($attrs, secondary)
  $type-icon: map-get($alert-type-icons, $name)

  .c-topic-alert--#{$name}
    background: rgba($type-color-secondary, .9)
    border-color: $type-color-primary
    .c-topic-alert__title
      color: $type-color-primary
    .c-topic-alert__icon
      background: $type-color-primary
      color: $type-color-secondary
      &::before
        content: $type-icon
    &:hover,
    &:focus
      .c-topic-alert__title,
      .c-topic-alert__description
        color: $white
      .c-topic-alert__icon
        &::before
          content: '\f35d'
