@import '../../helpers'
@import '../../tools/mixins/dashboard'

$dashboard-map-padding: space(2)
.c-dashboard-map
  min-height: 500px
  width: 100%
  flex: 1 1 100%
  display: flex
  flex-direction: column
  margin-bottom: space(4)
  background: $white
  @include media-breakpoint-up(md)
    max-height: calc(100vh - #{$page-navbar-height})
  @include media-breakpoint-up(lg)
    min-height: auto
    margin-bottom: 0
  .c-chart,
  .c-tabbed-map__wrapper
    border: 0
  .c-chart-legend
    margin: 0
    padding: $dashboard-map-padding !important // Override utility classes
    padding-bottom: 0
    margin-bottom: 0
  .c-dashboard-map__title
    padding: space(4) space(4) space(3) space(4)
  .c-dashboard-map__footer
    background: dash-color(card-bg)
  .c-dashboard-map__title-wrapper
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center

.c-dashboard-map--transparent
  background: none

.c-dashboard-map__map
  margin: 0 space(4) space(4)
  width: calc(100% - #{space(6)})
  @include media-breakpoint-up(md)
    width: 100%
    margin: 0
  flex: 1 1 100%
  height: 100%
  // min-height: 500px
  display: flex
  background: get-dash-color(canvas)
  border: 0
  > div
    flex: 1 1 100%

.c-dashboard-map__map--embed
  margin: 0 0 space(4) 0
  width: 100%
  @include media-breakpoint-up(md)
    margin: 0

.c-dashboard-map__title
  @include dashboard-card-title
  padding: space(2)
  margin: 0

.c-dashboard-map__footer
  padding: $dashboard-map-padding

// Darker theme
.c-dashboard-map--dark
  .c-dashboard-map__title,
  .c-dashboard-map__footer
    background: dash-color(primary-d)
    color: dash-color(text-color-l)

.c-dashboard-map--transparent .c-chart-legend,
.c-dashboard-map--transparent .c-tabbed-map
  background: none
