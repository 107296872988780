@import '../helpers'

.c-filters
  padding: space(2)
  margin-bottom: space(4)
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: 0 space(2)

.c-filters__filter-wrapper
  display: flex
  @include flex(1, 1, auto)

.c-filters__filter-wrapper label
  margin-bottom: space(2)

.c-filters__filter
  flex: 0 1 auto
  max-width: 100%
  display: flex
  align-items: center
  padding-top: space(1)
  padding-bottom: space(1)
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: space(2)
  &:last-of-type
    margin: 0
    padding-right: 0
    border: 0

.c-filters__filter--right
  margin-left: auto

.c-filters__filter--right label
  text-align: right

.c-filters__filter--toggle
  align-self: stretch
  align-items: flex-end
  margin-top: space(2)

.c-filters__filter--toggle .c-filters__filter-wrapper
  display: flex
  flex-direction: column
  align-items: flex-start

.c-filters__filter--toggle label
  @include input-label

.c-filters__title
  @include bolden
  &:empty
    display: none

.c-filters__title,
.c-filters__selector,
.c-filters__label-text
  margin: space(2) space(2) 0 space(2)

.c-filters__clear
  cursor: pointer
  color: $link-color
  margin-left: space(4)
  font-size: rem(30px)
  align-self: flex-end
  margin-bottom: 8px
  &:hover,
  &:focus
    color: $link-hover-color
