@import '../../helpers'

.c-mobile-footer-nav
  display: none
  position: fixed
  bottom: 0
  left: 0
  right: 0
  height: 5vh
  min-height: 50px
  width: 100%
  display: flex
  align-items: center
  background: $brand-primary
  border-top: $card-color 2px solid
  z-index: stack-order(modal) + 1
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none

.c-mobile-footer-nav__title
  margin: 0
  color: $card-color

.c-mobile-footer-nav__toggle
  color: $card-color
  &:active
    background: rgba($black, .2)
