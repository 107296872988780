@import '../../helpers'

.c-timeline-section
  @include flex(1, 1, auto)
  position: relative
  min-height: 100vh

.c-timeline-section__date
  position: absolute
  right: space(4)
  top: space(4)
  z-index: 10

.c-timeline-section__date span
  @include font-montserrat($size: 125px, $weight: 600, $lh:.75, $color: $brand-secondary)
  text-transform: uppercase
  white-space: nowrap
  opacity: .25

.c-timeline-section__content
  position: relative
  z-index: 110
  min-height: 150px
  @include flex(1, 1, auto)
  display: flex
  @include story-content-padding
  z-index: 10
  padding-top: space(4)
  padding-bottom: rem(125px)
  &::after
    content: ''
    position: absolute
    z-index: 5
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.8) 50%)

.c-timeline-section__assets
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap

.c-timeline-section__assets img
  margin-right: space(2)
  margin-bottom: space(2)
  &:last-child
    margin: 0

.c-timeline-section__assets,
.c-timeline-section__asset
  overflow: hidden
  position: absolute
  top: 10%
  bottom: 0
  left: 0
  right: 0
  z-index: 0
  &::after
    content: ''
    position: absolute
    z-index: 5
    top: 0
    left: 0
    right: 0
    min-height: 30%
    background: linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)

.c-timeline-section__asset img,
.c-timeline-section__asset video
  height: 100%
  width: 100%
  object-fit: cover
  font-family: 'object-fit: cover;'

.c-timeline-section__title
  z-index: 10
  padding-left: 0
  @include media-breakpoint-up(md)
    max-width: calc(100% - #{$story-wayfinder-width})
    margin-left: $story-wayfinder-width

.c-timeline-section__description
  @include flex(1, 1, auto)
  margin-top: auto
  position: relative
  z-index: 10
  p,
  ul
    @include story-copy($color: $gray-900)
  p:last-child,
  p:only-child
    margin-bottom: 0

.c-timeline-section__audio
  max-width: 100%
  background: rgba($white, .6)
  padding: space(2) space(8) space(2) space(6)
  position: absolute
  right: 0
  bottom: space(6)
  display: flex
  align-items: center
  cursor: pointer
  transition: .1s background ease-in-out
  &:hover
    background: rgba($white, .9)
    .c-timeline-section__audio-play
      color: $brand-secondary
      transform: scale(1.2)

.c-timeline-section__audio h4
  margin: 0 space(2) 0 0

.c-timeline-section__audio-play
  font-size: 42px
  color: $brand-primary
  transition: .1s ease-in-out
