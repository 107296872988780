@import '../helpers'

@function reverse($list, $recursive: false)
  $result: ()
  @for $i from length($list)*-1 through -1
    @if type-of(nth($list, abs($i))) == list and $recursive
      $result: append($result, reverse(nth($list, abs($i)), $recursive))
    @else
      $result: append($result, nth($list, abs($i)))
  @return $result

$indicator-height: 75px

.c-horizontal-score-viz
  display: flex
  flex-direction: column
  &--value-at-extent
    padding: 0 space(3)
    .c-horizontal-score-viz__values
      transform: translateY(100%) translateX(-2%)
      min-width: 104%


.c-horizontal-score-viz__indicator-wrapper
  min-height: $indicator-height
  position: relative
  display: flex
  align-items: flex-end

.c-horizontal-score-viz__indicator
  position: absolute
  display: flex
  min-height: 100%
  $indicator-extent-adjustment: .35
  &--at-extent-left
    .c-horizontal-score-viz__indicator-container
      transform: translateX(-50%)
    .c-horizontal-score-viz__indicator-title
     transform: translateX(percentage($indicator-extent-adjustment))
    .c-horizontal-score-viz__indicator-value
      transform: translateX(percentage($indicator-extent-adjustment))
  &--at-extent-right
    .c-horizontal-score-viz__indicator-title
     transform: translateX(percentage($indicator-extent-adjustment * -1))
    .c-horizontal-score-viz__indicator-value
      transform: translateX(percentage($indicator-extent-adjustment * -1))

@each $variant-name, $colors in $color-ramps
  .c-horizontal-score-viz__indicator-wrapper--#{"" + $variant-name}
    @for $i from 1 through length($colors)
      $color: nth($colors, $i)
      .c-horizontal-score-viz__indicator--#{$i}
        .c-horizontal-score-viz__indicator-container
          .c-horizontal-score-viz__indicator-line,
          .c-horizontal-score-viz__indicator-point
            background: $color
        .c-horizontal-score-viz__indicator-value
          color: determine-color-for-best-contrast($color)
          background: $color

.c-horizontal-score-viz__indicator-container
  transform: translateX(-50%)
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column

.c-horizontal-score-viz__indicator-connector
  flex: 1 1 auto
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-bottom: space(1)
.c-horizontal-score-viz__indicator-line
  width: 5px
  flex: 1 1 auto
.c-horizontal-score-viz__indicator-point
  display: block
  width: .5rem
  height: .5rem
  border-top-right-radius: 20%
  transform: rotate( -120deg) skewX( -30deg) scale(1, 0.866) translateY(-0.5px)
  transform-origin: 30% 45%
  &::before,
  &::after
    content: ''
    position: absolute
    background-color: inherit
    width: 100%
    height: 100%
    border-top-right-radius: 20%
  &::before
    transform: rotate( 135deg) skewY( -45deg) scale(0.707, 1.414) translate(50%)
  &::after
    transform: rotate( -135deg) skewX( -45deg) scale(1.414, 0.707) translate(0, -50%)

.c-horizontal-score-viz__indicator-title
  font-size: 12px
  line-height: 1
  text-transform: uppercase
  white-space: nowrap
  flex: 0 1 auto
  margin-bottom: rem(4)

.c-horizontal-score-viz__indicator-value
  font-weight: bold
  padding: 2px space(3)
  border-radius: 5px
  flex: 0 1 auto
  width: 100%
  text-align: center

  text-align: center
.c-horizontal-score-viz__values
  display: flex
  justify-content: space-between
  flex: 1 1 100%

.c-horizontal-score-viz__min,
.c-horizontal-score-viz__max
  font-weight: bold

.c-horizontal-score-viz__segments
  display: flex
  align-items: center
  justify-content: space-between

.c-horizontal-score-viz__segment
  flex: 1 1 auto
  min-height: rem(25)
  background: #f9f9f9
  border-right: 2px solid $white
  &:last-of-type
    border: 0

@each $variant-name, $colors in $color-ramps
  $reversed-colors: reverse($colors)
  .c-horizontal-score-viz__segments--#{"" + $variant-name}
    @for $i from 1 through length($reversed-colors)
      .c-horizontal-score-viz__segment--#{$i}
        background: nth($reversed-colors, $i)

.c-horizontal-score-viz__labels
  display: flex
  justify-content: space-between

.c-horizontal-score-viz__label
  text-align: center
  &:first-of-type
    text-align: left
    padding-left: space(1)
  &:last-of-type
    text-align: right
    padding-right: space(1)

.c-horizontal-score-viz--lg
  .c-horizontal-score-viz__indicator-wrapper
    min-height: 125px
  .c-horizontal-score-viz__indicator-value
    font-size: rem(45)
  .c-horizontal-score-viz__segment
    min-height: rem(40)
  .c-horizontal-score-viz__indicator-title
    width: 100%
    text-align: center
    text-transform: none
    font-weight: 600
    font-size: rem(16)
  $indicator-extent-adjustment: .40
  .c-horizontal-score-viz__indicator
    &--at-extent-left
      .c-horizontal-score-viz__indicator-title
        transform: translateX(percentage($indicator-extent-adjustment))
      .c-horizontal-score-viz__indicator-value
        transform: translateX(percentage($indicator-extent-adjustment))
    &--at-extent-right
      .c-horizontal-score-viz__indicator-title
        transform: translateX(percentage($indicator-extent-adjustment * -1))
      .c-horizontal-score-viz__indicator-value
        transform: translateX(percentage($indicator-extent-adjustment * -1))
