@import '../helpers'

.c-content-header
  background: $white
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%)

.c-content-header--legacy-topic
  .c-content-header__content
    padding-left: 1.5rem


.c-content-header__content
  @include topic-container

.c-content-header h1
  text-transform: none
  font-weight: 300
  font-family: 'open sans'
  font-size: 36px
