@import '../helpers'

// .c-heading

.c-heading--flex
  display: flex
  align-items: flex-end

.c-heading--full-bleed
  margin-left: (-#{$topic-padding-mobile})
  margin-right: (-#{$topic-padding-mobile})
  padding-left: $topic-padding-mobile
  padding-right: $topic-padding-mobile
  @include media-breakpoint-up(lg)
    margin-left: (-#{$topic-padding-desktop})
    margin-right: (-#{$topic-padding-desktop})
    padding-left: $topic-padding-desktop
    padding-right: $topic-padding-desktop

.c-heading--bordered
  padding-bottom: space(2)
  border-bottom: 2px solid $brand-secondary

.c-heading--sticky
  @include heading-sticky

.c-heading__title
  @include h3
  margin-bottom: 0
