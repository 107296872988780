@import '../helpers'

$landing-mask-width: 25%

.o-landing
  display: flex
  align-items: center
  overflow: hidden
  position: relative
  @include media-breakpoint-down(sm)
    flex-direction: column

.o-landing__hero
  @include hero-image
  position: absolute
  width: 65%
  top: 0
  bottom: 0
  left: 0
  &::before,
  &::after
    content: ''
    position: absolute
    top: 0
    bottom: 0
  &::before
    right: $landing-mask-width
    width: 15%
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.9) 85%, rgba(255,255,255,1) 100%)
  &::after
    position: absolute
    right: 0
    width: $landing-mask-width
    top: 0
    bottom: 0
    background: #FFFFFF
  @include media-breakpoint-down(sm)
    position: static
    width: 100%
    height: 25vh
    &::before,
    &::after
      display: none

.o-landing h1
  @include h1
  margin-bottom: 10px
  @include media-breakpoint-down(sm)
    display: none


.o-landing h2
  @include h2('sm')
  text-transform: none

.o-landing__heading
  margin-bottom: space(4)

.o-landing__content
  z-index: 10
  position: relative
  padding: space(6) space(4)
  @include media-breakpoint-up(md)
    padding-left: 50vw
    padding-right: space(8)

.o-landing__search
  width: 100%

.o-landing__intro
  @include default-body-font(rem(16px))

.o-landing section
  margin-bottom: space(6)

.o-landing__action--primary
  padding-left: space(8)
  padding-right: space(8)
  @media screen and (min-width: 768px) and (max-width: 1040px)
    padding-left: space(4)
    padding-right: space(4)
  @include media-breakpoint-down(xs)
    padding-left: space(3)
    padding-right: space(3)

.o-landing__action--secondary
  font-size: 13px
  line-height: line-height(16, 12)
