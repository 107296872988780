@import '../../helpers'
@import '../../tools/mixins/dashboard'

.c-dashboard-description
  margin-bottom: space(4)

.c-dashboard-description__header
  display: flex
  align-items: center
  justify-content: space-between
  flex-wrap: wrap
  margin-bottom: space(2)
  @each $level in h1 h2 h3
    .#{$level},
    #{$level}
      @include dash-card-heading($level: $level)
      margin-top: space(2)
      margin-bottom: space(2)

.c-dashboard-description__copy
  &,
  p
    font-size: rem(16px)
    line-height: 28px
    color: $black
    max-width: 100ch
  &--featured
    font-size: rem(20px)
    line-height: 32px
    max-width: 120ch
    margin-bottom: space(4)
