@import '../helpers'

.c-date-picker
  display: flex
  padding-bottom: space(4)
  padding-left: space(4)
  padding-right: space(4)
  background: $white
  flex-direction: column
  flex: 1 0 auto

.c-date-picker__label
  font-weight: font-weight(semibold)
  margin-right: space(1)
  margin-top: space(1)
  margin-bottom: space(1)
  &--blue
    color: #045A8D
  > span
    font-weight: normal
    color: $gray-700

.c-date-picker__select
  flex: 1 1 auto

.c-date-picker__selector
  width: 100%
