@import '../helpers'

.l-dashboard
  background: $card-color
  display: flex
  flex-direction: column
  flex: auto

.l-dashboard__overview
  display: flex
  align-items: stretch
  width: 100%

// Maps
.l-dashboard .c-dashboard-map
  min-height: 500px

.l-dashboard__big-map
  display: flex
  flex-direction: column
  flex: auto
