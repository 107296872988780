@import '../../helpers'
@import '../../tools/mixins/dashboard'

$stoplight-colors: dash-color(stoplight)

.c-graduated-indicator
  display: flex
  flex-direction: column

.c-graduated-indicator__point
  margin-bottom: space(2)
  svg
    height: 50px
    path
     fill: nth($stoplight-colors, 1)
    text
     fill: determine-color-for-best-contrast(nth($stoplight-colors, 1))


  &--2
    margin-left: auto
    margin-right: auto
    svg
      path
        fill: nth($stoplight-colors, 2)
      text
        fill: determine-color-for-best-contrast(nth($stoplight-colors, 2))
  &--3
    margin-left: auto
    svg
      path
        fill: nth($stoplight-colors, 3)
      text
        fill: determine-color-for-best-contrast(nth($stoplight-colors, 3))

.c-graduated-indicator__bar
  height: 20px
  width: 100%
  background: linear-gradient(to right, #{nth($stoplight-colors, 1)}, #{nth($stoplight-colors, 2)}, #{nth($stoplight-colors, 3)})
  margin-bottom: space(1)

.c-graduated-indicator__labels
  display: flex
  justify-content: space-around

.c-graduated-indicator__label
  font-weight: font-weight(bold)
