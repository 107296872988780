@import '../helpers'

.ql-editor
  background: $card-color
  min-height: 200px

.ql-editor p
  font-size: 1rem

.ql-container
  font-family: $font-family-sans-serif

.ql-container.ql-snow
  border-bottom-left-radius: 3px
  border-bottom-right-radius: 3px
  border: 1px solid $brand-primary

.ql-toolbar.ql-snow
  background: $card-header-bg-color
  border-top-left-radius: 3px
  border-top-right-radius: 3px
  border-color: $brand-primary
  border-bottom-color: $border-color-secondary

