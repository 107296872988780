// .c-image-tooltip

.c-image-tooltip__permission
  font-weight: 400
  font-size: 11px
  display: block
  text-decoration: none

.c-image-tooltip__permission a
  text-decoration: none
