@use "sass:math"

@import '../helpers'

@mixin nav-padding
  padding-left: 20px
  padding-right: 20px

.c-nav.navbar
  background: none
  margin-bottom: 0
  box-shadow: none
  display: flex
  align-items: stretch
  width: 100%
  padding: 0 space(2)
  flex-wrap: wrap
  align-items: center
  position: sticky
  top: 0
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: 0 space(4)

.c-nav--mhc.navbar
  background: $brand-primary

.c-nav--side
  display: flex
  flex-direction: column
  max-height: calc(100vh - #{$header-height})
  border: 0
  box-shadow: none

.c-nav__section
  margin-bottom: space(4)
  .c-mini-map
      margin-left: 30px
      margin-right: 30px

.c-nav__section--shadow
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)
  padding-bottom: 20px

.c-nav__section-header
  font-weight: 600
  font-size: 12px
  line-height: 18px
  color: rgba($black, 0.87)
  opacity: 0.6
  @include nav-padding
  margin-bottom: 12px // match new frontend margin

.c-nav.navbar a:not(.c-list__link)
  color: $white

.c-nav__additional_ctas
  padding: space(4)
  .c-dropdown__button
    background: rgba($white, 0)
    border-color: rgba($white, .75)
    &,
    &::after
      color: $white
    &:focus,
    &:hover
      background: rgba($white, .9)
      &,
      &::after
        color: $brand-primary

.c-nav__additional_ctas button
  width: 100%

.c-nav__additional_cta-wrapper
  background: rgba(dash-color(primary-d), .75)
  padding: space(2) space(4)
  display: block
  font-size: rem(14px)
  line-height: math.div(20, 14)
  margin-top: space(2)
  margin-bottom: space(2)
  @include flex(1, 1, auto)
  @include media-breakpoint-up($page-nav-breakpoint)
    border-top-left-radius:  4px
    border-bottom-left-radius: 4px
    width: auto
    @include flex(0, 1, auto)
  p
    color: $white
  &:hover,
  &:focus
    text-decoration: none
    background: rgba($white, .95)
    &,
    span,
    p
      color: dash-color(primary-d)
      text-decoration: none !important
  &:active
    background: rgba($white, 1)

.c-nav__heading
  min-height: $heading-min-height
  padding: space(2) 0
  display: flex
  align-items: center
  @include flex(0, 0, auto)
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: space(2) 0

.c-nav__heading--page
  padding: space(2)

.c-nav__heading h1
  @include font-heading(rem(28px), $black, $lh: line-height(32, 28), $weight: font-weight(bold))
  margin-bottom: 0

.c-nav__heading--small
  display: block
  margin-bottom: 0
  padding-bottom: 0

.c-nav__heading--small h1
  margin-bottom: 0

.c-nav__heading--small h1,
.c-nav__heading--small h2
  @include font-heading(rem(16px), $white, $lh: line-height(18, 16), $weight: 600)
  padding: space(2) space(4) space(2) space(4)
  text-transform: uppercase

.c-nav__heading--small h2
  margin-top: space(2)
  background: rgba($white, .5)
  color: black
  @include flex(1, 1, 100%)
  margin: 0

.c-nav__heading-link
  min-width: 250px
  svg
    max-width: 75vw
    @include media-breakpoint-up(md)
      max-width: 300px
      min-width: 250px
    @include media-breakpoint-up(lg)
      max-width: 400px
      min-width: 350px

.c-nav__heading-link--inline
  display: flex
  flex-wrap: wrap
  align-items: baseline
  .c-nav__site-tag-line
    margin-left: space(1)

.c-nav__header
  @include nav-padding
  padding-left: 16px
  padding-right: 16px
  min-height: $utility-header-height
  display: flex
  align-items: center
  margin-bottom: 16px
  @include media-breakpoint-down($nav-switch-breakpoint)
    display: none

.c-nav__mobile_header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 3px space(3)
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.23)
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none

.c-nav__mobile_search
  padding: 11px space(4)
  background-color: #fafafa
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none
  input
    background-color: transparent
    border-width: 2px
    padding-top: 4px !important
    padding-bottom: 4px !important

.c-nav__mobile_location
  padding: space(3) space(4)
  background-color: #F0F3F8
  font-size: 14px
  font-weight: 400
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none
  .location-trigger
    display: inline-flex
    align-items: center
    color: $link-color
    .material-icons
      color: inherit
  a.nav-link
    text-decoration: underline
    display: inline-flex
    align-items: center
    font-weight: 300

.c-nav__home_link
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none

.c-nav__header button
  @include mui-light-button
  align-items: center
  i
    font-size: 17px

.c-nav__heading a
  opacity: 1
  @include transition
  &:hover
    text-decoration: none
    opacity: .85
  &:active
    opacity: .5

.c-nav__site-name,
.c-nav__site-tag-line
  margin-bottom: 0
  opacity: inherit

.c-nav__site-name
  @include font-heading(rem(24px), $white, $lh: line-height(30, 24), $weight: font-weight(bold))
  margin-bottom: 0
  display: flex
  align-items: center
  @include media-breakpoint-down($nav-switch-breakpoint)
    @include font-heading(rem(20px), $white, $lh: line-height(26, 20), $weight: font-weight(bold))

.c-nav__site-tag-line
  @include font-heading(rem(12px), $white, $lh: line-height(14.6667, 12), $weight: font-weight(light))
  display: none
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: block

.c-nav .navbar-toggler
  padding: space(1) space(2)
  color: $white
  font-size: rem(16)

.c-nav__wrapper
  display: flex
  align-items: stretch
  @include flex(1, 1, 100%)
  @include media-breakpoint-up($nav-switch-breakpoint-up)
    margin-left: auto
    flex: none

.c-nav__wrapper--constrained
  margin: 0 auto
  display: flex
  align-items: stretch
  justify-content: space-between
  flex: 1 1 100%

.c-nav__group
  margin-right: space(4)
  &:last-of-type
    margin-right: 0

.c-nav__item
  margin-left: 20px
  margin-right: 20px
  display: flex
  align-items: stretch
  &:last-of-type
    margin-right: 0

.c-nav__link
  opacity: .9
  display: flex
  align-items: center
  padding-left: space(2)
  padding-right: space(2)
  @include media-breakpoint-up($nav-switch-breakpoint)
    @include transition
    &:focus,
    &:hover
      background: rgba($white, .1)
      color: $brand-primary
      opacity: 1
      text-decoration: none
      svg
        fill: $white

.c-nav__link span
  &:first-child
    display: none
    @include media-breakpoint-up($nav-switch-breakpoint)
      display: block
  &:last-child
    font-size: 20px
    display: block
    @include media-breakpoint-up($nav-switch-breakpoint)
      display: none

.c-nav__menu
  display: flex
  align-items: stretch
  @include flex(0, 0, auto)
  &.searching
    width: 100%
  @include media-breakpoint-up($nav-switch-breakpoint)
    padding: space(2) 0
    &,
    &.searching
      width: auto

.c-nav__search-toggle
  font-size: rem(16px)
  display: flex
  align-items: center
  cursor: pointer
  color: $white
  span
    margin-left: space(1)
  &.active
    margin-right: space(2)
  &.active i::before
    content: '\f00d'
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none

.c-nav__dismiss
  z-index: stack-order(top)
  padding: space(2)
  display: flex
  align-items: center
  color: #9E9E9E
  border: 0
  font-size: 25px
  background-color: transparent
  @include media-breakpoint-up($nav-switch-breakpoint)
    display: none
  &:focus
    outline: 2px solid #0060f0

.c-nav__link-cta
  font-weight: 600
.c-nav__link-cta--light
  color: $white
  &:hover,
  &:focus
    color: $white
    text-decoration: underline

.c-nav__switcher-trigger
  margin: space(2)

.c-nav__search
  flex: 1 1 250px
  display: none
  @include media-breakpoint-up(sm)
    max-width: 275px
    > div
      width: 100%
  @include media-breakpoint-up(md)
    display: flex
    align-items: center
  @include media-breakpoint-up(lg)
    max-width: 350px
  input
    font-size: rem(14)
